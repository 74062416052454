import { animate, style, transition, trigger } from '@angular/animations';
import { formatDate } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, Signal, signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { LoggingService } from 'src/app/core/app-services';
import { appRepository } from 'src/app/core/stores/app.repository';
import { detailsRepository } from 'src/app/core/stores/details.repository';
import { environment } from "src/environments/environment";

@Component({
    selector: 'evc-support-overlay',
    template: `
        <button 
            class="support"
            type="button"
            [class.open]="open()"
            (click)="open.set(!open())"
        >
            <span class="material-icon">{{ open() ? 'close' : 'question_mark' }}</span>
        </button>

        @if (open()) {
            <div 
                class="support-overlay"
                [@popupInOut]
            >
                <h3>{{ 'SUPPORT.TITLE' | translate }}</h3>
                <p>{{ 'SUPPORT.TEXT' | translate }}</p>
                <p>{{ 'SUPPORT.TEXT_THX'| translate }}</p>

                <div 
                    class="btn-wrapper flex-row align-items-center justify-content-end"
                    [copy-clipboard]="environment.supportMail"
                >
                    <a
                        class="action-btn mr-8"
                        [href]="environment.userGuideUrl"
                        target="_blank"
                    >
                        <span class="material-icon">download</span>
                        {{ 'SUPPORT.USER_GUIDE' | translate }}
                    </a>
                    <a
                        class="action-btn mr-8"
                        [href]="mailLink()"
                        target="_blank"
                    >
                        <span class="material-icon">email</span>
                        {{ environment.supportMail }}
                    </a>
                </div>
            </div>
        }
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
    styleUrl: './support-overlay.component.scss',
    animations: [
        trigger('popupInOut', [
            transition(':enter', [
                style({ transform: 'translateY(15px)', opacity: 0 }),
                animate('.15s cubic-bezier(.78,.66,.27,1)', style({ transform: 'translateY(0px)', opacity: 1 }))
            ]),
            transition(':leave', [
                style({ transform: 'translateY(0px)', opacity: 1 }),
                animate('.15s cubic-bezier(.78,.66,.27,1)', style({ transform: 'translateY(15px)', opacity: 0 }))
            ])
        ])
    ]
})
export class SupportOverlayComponent {
    readonly environment = environment;
    public open = signal<boolean>(false);
    public mailLink: Signal<string>;

    constructor(
        private _translate: TranslateService,
        private _appRepo: appRepository,
        private _router: Router,
        private _logger: LoggingService,
        private _detailsRepo: detailsRepository
    ) {
        const currentUser = toSignal(this._appRepo.currentUser$);
        const lastRouterEvent = toSignal(this._router.events);
        const newLang = toSignal(this._translate.onLangChange);
        const lastDateRange = toSignal(this._detailsRepo.dateRange$);
        const lastZoomRange = toSignal(this._detailsRepo.filteredChartsZoomRange$);

        const t = (path: string, interpolateParams?: Object) => this._translate.instant(path, interpolateParams);

        this.mailLink = computed(() => {
            const baseLink = 'mailto:Evercharge%20Support%20%3C'+environment.supportMail+'%3E';
            if (!this.open()) return baseLink;
            // just needed for update
            const [routerEvent, lang] = [lastRouterEvent(), newLang()];
            // get current User
            const user = currentUser();
            // create mail subject and link
            const userName = user && user.firstName && user.lastName ? `${user.firstName} ${user.lastName}` : t('SUPPORT.MAIL.NAME');
            const pageLink = window.location.href;

            let additionalInfo = '';

            if (pageLink.includes('/details/')) {
                const [dateRange, zoomRange] = [lastDateRange(), lastZoomRange()];
                if (dateRange) additionalInfo += `dateRange: ${formatDate(dateRange.from, 'dd.MM.y HH:mm:ss', 'en')} - ${formatDate(dateRange.until, 'dd.MM.y HH:mm:ss', 'en')}\n`;
                if (zoomRange) additionalInfo += `zoomRange: ${zoomRange[0]} - ${zoomRange[1]}\n`;
            }
            
            const lastClientErrors = this._logger.clientErrors;
            const lastServerErrors = this._logger.serverErrors;
            if (lastClientErrors && lastClientErrors.length > 0) additionalInfo += `clientErrors: ${JSON.stringify(lastClientErrors)}\n`;
            if (lastServerErrors && lastServerErrors.length > 0) additionalInfo += `serverErrors: ${JSON.stringify(lastServerErrors)}\n`;

            if (additionalInfo.length > 0) {
                additionalInfo = `\n\nAdditional Information:\n${additionalInfo}`;
            }
            
            return `${baseLink}?subject=${encodeURIComponent(t('SUPPORT.MAIL.SUBJECT'))}&body=${encodeURIComponent(t('SUPPORT.MAIL.BODY', {userName, pageLink}))}${encodeURIComponent(additionalInfo)}`;
        })
    }
}
