import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, OnDestroy, Output } from '@angular/core';
import { filter, map, Subject, Subscription, throttleTime } from 'rxjs';

@Component({
    selector: "th[resizableColumn]",
    template: `
    <div class="wrapper">
        <div class="content">
            <ng-content></ng-content>
        </div>
        <div class="resizer" (resizableColumn)="colWidth$.next($event)"></div>
    </div>
  `,
    styleUrls: ['./resizable.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ResizableComponent implements OnDestroy {
    @Output() columnWidth: EventEmitter<number> = new EventEmitter<number>();
    colWidth$: Subject<number | null> = new Subject<number | null>()
    emitWidth$: Subscription = this.colWidth$.pipe(
        filter(width => width !== null),
        throttleTime(20),
        map(width => {
            width = Math.floor(width!)
            this.width = width
            this.columnWidth.emit(width)
            return width
        })
    ).subscribe()

    @HostBinding("style.min-width.px")
    width: number | null = null;

    ngOnDestroy() {
        this.emitWidth$.unsubscribe()
    }
}
