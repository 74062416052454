import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, Signal, computed, inject, input } from '@angular/core';
import { compareArrays, formatSeconds } from 'src/app/core/helpers/utils.helper';
import { NzSliderValue } from 'ng-zorro-antd/slider';
import { FormControl } from '@angular/forms';
import { toSignal } from '@angular/core/rxjs-interop';
import { formatNumber } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';

/**
 * Interface for range options 
 * all values (min, max, firstStart, secondStart) need to be presented 
 * in the original unit
 */
export interface SelectRangeOptions {
    min: number
    max: number
    step: number
    firstStart: number
    secondStart: number
    unit?: string
    // type may define special label formatters, time = d, hh:mm:ss, kW = W to kW
    type?: 'time' | 'kW' | 'kWh' | 'coordinates'
}
@Component({
  selector: 'app-select-range',
  template: `
    <app-dropdown-button
        [title]="this.title"
        [desc]="selectedValDesc()"
        (onClose)="applyChanges()"
        [bodyClass]="this.bodyClasses.join(' ')"
        [size]="size"
        [disabled]="disabled"
        [additionalInfo]="additionalInfo"
        [keepPosition]="keepPosition"
    >
        <ng-container body *ngIf="this.options">
            <div class="wrapper">
                <nz-slider
                    [nzMin]="options().min"
                    [nzMax]="options().max"
                    [nzStep]="options().step"
                    [nzRange]="true"
                    [nzDefaultValue]="[options().firstStart, options().secondStart]"
                    [nzTipFormatter]="getLabel()"
                    [ngModel]="ngModelValues()"
                    (nzOnAfterChange)="prepareChanges($event)"
                />
                <div class="flex-row align-items-center justify-content-between input-wrapper">
                    <div class="form-group">
                        <label for="min">Min</label>
                        <input type="number" name="min" [class.wide]="options().type == 'coordinates'" tabindex="1" [min]="options().min" [max]="secondInput.value" [formControl]="firstInput">
                        @if (suffix(); as suffix) { <div class="input-info">{{ suffix }}</div> }
                    </div>
                    <div class="form-group">
                        <label for="max">Max</label>
                        <input type="number" name="max" [class.wide]="options().type == 'coordinates'" tabindex="1" [min]="firstInput.value" [max]="options().max" [formControl]="secondInput">
                        @if (suffix(); as suffix) { <div class="input-info">{{ suffix }}</div> }
                    </div>
                </div>
            </div>
            @if (this.options().type == 'coordinates') {
                <div class="receive-null">
                    <input type="checkbox" name="receiveNull" id="receiveNull" tabindex="1" [formControl]="thirdInput">
                    <label for="receiveNull">{{ 'FILTERS.INCLUDE_STATIONS_WITHOUT' | translate: {content: title} }}</label>
                </div>
            }
        </ng-container>
    </app-dropdown-button>
    `,
    styleUrls: ['./select-range.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SelectRangeComponent {
    // Title of Dropdown
    @Input('title') title!: string;
    // stores last selection input
    private _prevValues: {range: number[], receiveNull: boolean} | null = null;
    // set active selection
    @Input() set activeSelection(input: (number | boolean)[] | null) {
        let range = null;
        let receiveNull = false;
        if (input && input.length >= 3) {
            range = [input[0], input[1]] as number[];
            receiveNull = input[2] as boolean;
        }
        if (!range || range.length == 0) {
            range = this.presetBaseRangeVals() || []
        }
        this.firstInput.setValue(this._convertForInput(range[0]));
        this.secondInput.setValue(this._convertForInput(range[1]));
        this.thirdInput.setValue(receiveNull);
        this._prevValues = {range, receiveNull};
    };
    @Output() activeSelectionChange = new EventEmitter<(number | boolean | null)[]>();
    public firstInput = new FormControl<number | null>(null, {
        validators: [
            (control) => control.value !== null && control.value !== undefined && control.value >= this.options().min ? null : { invalid: true },
            (control) => control.value !== null && control.value !== undefined && this.secondInput.value !== null && control.value < this.secondInput.value ? null : { invalid: true }
        ]
    });
    public secondInput = new FormControl<number | null>(null, {
        validators: [
            (control): {invalid: boolean} | null => control.value !== null && control.value !== undefined && control.value <= this.options().max ? null : { invalid: true },
            (control): {invalid: boolean} | null => control.value !== null && control.value !== undefined && this.firstInput.value !== null && control.value > this.firstInput.value ? null : { invalid: true }
        ]
    });
    public thirdInput = new FormControl<boolean>(false);
    // available options
    public options = input.required<SelectRangeOptions, SelectRangeOptions | null>({
        transform: (input) => {
            return input ? {
                ...input,
                min: Math.ceil(input.min),
                max: Math.ceil(input.max),
            } : {
                min: 0,
                max: 1,
                step: 1,
                firstStart: 0,
                secondStart: 1
            }
        }
    });
    // model values after correct conversion
    public ngModelValues: Signal<[number, number]>;
    @Input('size') size: 'default' | 'small' = 'default';
    // will keep initial position of dropdown content
    @Input() keepPosition: boolean = false;
    // additional information on info icon in button
    @Input() additionalInfo: string | undefined;
    // whether this button is disabled
    @Input('disabled') disabled: boolean = false;
    // classes for body of dropdown
    public bodyClasses: string[] = ['body-range-select', 'overflow-hidden'];
    // Desc of selected Value for Button
    public selectedValDesc: Signal<string>;
    // preset firstStart and seconStart from options
    public presetBaseRangeVals = computed(() => {
        const options = this.options();
        return [options.min, options.max];
    });

    constructor(
        public translate: TranslateService
    ) {
        const firstInputValueChange = toSignal(this.firstInput.valueChanges);
        const secondInputValueChange = toSignal(this.secondInput.valueChanges);

        this.selectedValDesc = computed(() => {
            const baseRange = this.presetBaseRangeVals();
            const firstInputVal = this._convertFromInput(firstInputValueChange() ?? baseRange[0])!;
            const secondInputVal = this._convertFromInput(secondInputValueChange() ?? baseRange[1])!;
            return `${this._renderLabel(firstInputVal)} - ${this._renderLabel(secondInputVal)}`
        })

        this.ngModelValues = computed(() => {
            const firstInputVal = this._convertFromInput(firstInputValueChange());
            const secondInputVal = this._convertFromInput(secondInputValueChange());
            return [firstInputVal, secondInputVal] as [number, number]
        })
    }

    prepareChanges(event: NzSliderValue) {
        const values = event as number[];
        this.firstInput.setValue(this._convertForInput(values[0]));
        this.secondInput.setValue(this._convertForInput(values[1]));
    }

    applyChanges() {
        // compare changes
        const values = {
            range: [
                this._convertFromInput(this.firstInput.value),
                this._convertFromInput(this.secondInput.value),
            ] as number[],
            receiveNull: this.thirdInput.value ?? false,
        };
        const options = this.options();
        // limit to min / max from options and between the inputs
        values.range[0] = values.range[0] !== null && values.range[0] !== undefined ? Math.max(options.min, Math.min(values.range[0], values.range[1])) : options.min;
        values.range[1] = values.range[1] !== null && values.range[1] !== undefined ? Math.min(options.max, Math.max(values.range[0], values.range[1])) : options.max;

        // true if selection equals min/max of range slider
        const isBaseRange = compareArrays(values.range, (this.presetBaseRangeVals() || []));

        if (values && (!this._prevValues || !compareArrays(this._prevValues.range, values.range) || this._prevValues.receiveNull != values.receiveNull)) {
            // If values are selected and are not the same as previous selection, update
            this.activeSelectionChange.emit(isBaseRange ? values.receiveNull ? [...this.presetBaseRangeVals(), values.receiveNull] : [] : [...values.range, values.receiveNull])
        }
        this._prevValues = {...values};
    }

    // returns correct suffix based on range type and provided unit
    public suffix = computed(() => {
        const options = this.options();
        if (!options) return ''
        if (!options.type) return options.unit
        if (options.type == 'time') return 'h'
        if (options.type == 'coordinates') return '°'
        return options.type
    })

    // conversion of real value to converted for type (e.g. W -> kW)
    private _convertForInput(value: number): number {
        const options = this.options();
        switch (options.type) {
            case 'kW': case 'kWh':
                const diff = (options.max || 0) - (options.min || 0);
                return +(value / 1000).toFixed(diff > 100 ? 0 : 2)
            case 'time':
                return +(value / 3600).toFixed(2)
            default:
                return value
        }
    }

    // conversion of converted value to real value (e.g. kW -> W)
    private _convertFromInput(value: number | undefined | null): number | undefined | null {
        if (value == undefined || value == null) return value
        const options = this.options();
        switch (options.type) {
            case 'kW': case 'kWh':
                return value * 1000
            case 'time':
                return value * 3600
            default:
                return value
        }
    }

    // base formatting for display values, potentially with unit
    private _formatForUnitDisplay(value: number): string {
        const options = this.options();
        return value + (options && options.unit ? options.unit : '')
    }
    // formats for kW or kWh display
    private _formatForKiloDisplay(value: number): string {
        const options = this.options();
        const diff = (options.max || 0) - (options.min || 0);
        // only show decimals if difference is less than 100
        return formatNumber(+(value / 1000).toFixed(diff > 100 ? 0 : 2), this.translate.currentLang) + ' ' + this.suffix();
    }

    // returns formatted string
    private _renderLabel(value: number): string {
        const options = this.options();
        if (options && options.type) {
            switch (options.type) {
                case 'time':
                    return formatSeconds(value) + ' h';
                case 'kW': case 'kWh':
                    return this._formatForKiloDisplay(value);
            }
        }
        return this._formatForUnitDisplay(value)
    }

    getLabel(): (value: number) => string {
        return (value) => this._renderLabel(value)
    }
}

