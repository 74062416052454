import { AfterViewInit, Component, ElementRef, Inject, Input, Renderer2, ViewChild } from '@angular/core';

@Component({
    selector: "td[copy-clipboard], div[copy-clipboard]",
    template: `
        <div class="copy-attribute-cont">
            <ng-content></ng-content>
            <button 
                *ngIf="payload"
                class="copy-attribute"
                [copy-clipboard]="payload"
            >
                <div 
                    #imgEl
                    class="copy-img"
                ></div>
            </button>
        </div>
    `,
    styleUrls: ['./copy-attribute.component.scss']
})
export class CopyAttributeComponent implements AfterViewInit {

    @Input("copy-clipboard")
    public payload: string | null = '';

    @ViewChild("imgEl") 
    public imgEl: ElementRef<HTMLDivElement> | undefined;

    constructor(
        private readonly _elementRef: ElementRef<HTMLElement>,
        private readonly _renderer: Renderer2
    ) {}

    ngAfterViewInit() {
        if (this.imgEl) {
            const className = this._elementRef.nativeElement.tagName == "TD" ? "copy-details-view" : "copy-navbar";
            this._renderer.addClass(this.imgEl.nativeElement, className);
        }
    }

}
