import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { overviewRepository } from 'src/app/core/stores/overview.repository';
import { AUTO_STYLE, animate, style, transition, trigger, useAnimation } from '@angular/animations';
import { fadeIn } from 'src/app/core/helpers/animations';
import { BehaviorSubject, Observable, ReplaySubject, combineLatest, delay, map, of, share, startWith, switchMap, withLatestFrom } from 'rxjs';
import { AdditionalListInformation } from '../select-filters/select-filters.component';
import { Filter } from "src/app/core/stores/station-filters.repository";
import { TranslateService } from "@ngx-translate/core";

// extend ActiveFilter by optional information to be displayed on filter
interface FilterWithInfo extends Filter {
    info?: string
}

@Component({
    selector: 'list-filters',
    template: `
        <div class="filters">
            @for (activeFilter of filters$ | async; track activeFilter.id) {
                <filter-select
                    [activeFilter]="activeFilter"
                    [showDeleteButton]="!activeFilter.required"
                    [pseudoDisable]="true"
                    [loading]="loading"
                    [disabled]="disabled"
                    (onSelectionChange)="newFilterValue(activeFilter.id, $event)"
                    (onResetFilter)="resetFilter($event)"
                    (onDeleteFilter)="deleteFilter($event)"
                    [@fadeInOutAnim]="filterKeysChanged$ | async"
                >
                </filter-select>
            }
        </div>
    `,
    styleUrls: ['./list-filters.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [
        trigger('fadeInOutAnim', [
            transition('void => true', [
                useAnimation(fadeIn)
            ]),
            transition('true => void', [
                style({ opacity: 1, width: AUTO_STYLE }),
                animate('.25s ease-out', 
                style({ opacity: 0, width: '0px' }))
            ])
        ])
    ]
})

export class ListFiltersComponent {
    // controls if animation should be fired
    public filterKeysChanged$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
    // all filters with mapped type of selection
    private _filters$: BehaviorSubject<Filter[]> = new BehaviorSubject<Filter[]>([]);
    public filters$: Observable<FilterWithInfo[]> = combineLatest([
        this._filters$,
        this._translate.onLangChange.pipe(startWith(null)),
    ]).pipe(
        withLatestFrom(this.filterKeysChanged$),
        switchMap(([[filters, lang], filterKeysChanged]) => {
            // slightly delay filters emission if keys are the same to account for updates in animation control
            // we dont want to animate any filters in this case, as in this case only select options in filters are updated
            return filterKeysChanged ? of(filters) : of(filters).pipe(delay(2))
        }),
        map((filters) => {
            // map additional information to active filter object
            const additionalInformation = this.additionalInformation?.filter(x => x.group === 'variables');
            if (additionalInformation) {
                additionalInformation.forEach((info) => {
                    const match = filters.find((filterOption) => filterOption.id === info.value);
                    if (match) (match as FilterWithInfo).info = info.text
                })
            }

            return filters as FilterWithInfo[]
        }),
        share({connector: () => new ReplaySubject(1)})
    )
    @Input() set filters(filters: Filter[] | null) {
        // test if filter keys changed
        const prevFilterKeys = this._filters$.getValue().map((filter) => filter.id);
        const newFilterKeys = filters?.map((filter) => filter.id) ?? [];
        this.filterKeysChanged$.next(!this._compareArrays(prevFilterKeys, newFilterKeys));
        this._filters$.next(filters ?? []);
    };

    // additional info thats displayed based on filter key
    @Input() additionalInformation: AdditionalListInformation[] | undefined
    // sets animation on filters
    @Input() loading: boolean | null = false;
    // whether filter list should be disabled
    @Input() disabled: boolean = false;

    // outputs on changes in listed filters
    @Output() onResetFilter = new EventEmitter<Filter>();
    @Output() onDeleteFilter = new EventEmitter<Filter['id']>();
    @Output() onNewFilterValue = new EventEmitter<[Filter['id'], any]>();

    constructor(
        private _translate: TranslateService,
        public overviewRepo: overviewRepository
    ) { }

    // returns true if the two arrays are alike (ignores order)
    private _compareArrays(a: any[], b: any[]) {
        if (a.length !== b.length) return false;
        const uniqueValues = new Set([...a, ...b]);
        for (const v of uniqueValues) {
            const aCount = a.filter(e => e === v).length;
            const bCount = b.filter(e => e === v).length;
            if (aCount !== bCount) return false;
        }
        return true;
    }

    newFilterValue(filterId: Filter['id'], value: any) {
        this.onNewFilterValue.emit([filterId, value])
    }

    resetFilter(filter: Filter) {
        this.onResetFilter.emit(filter);
    }

    deleteFilter(filter: Filter) {
        this.onDeleteFilter.emit(filter.id);
    }
}
