/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { ResetResponse } from '../../models/reset-response';

export interface RestartChargingStationsBySchedule$Params {
      body: {

/**
 * Unique identifiers of the Charging Stations
 */
'stationIds'?: Array<string>;

/**
 * Days of the week for weekly or daily restart between 00:00 am and 2:00 am (UTC). Each day of the week is represented by an integer (0=Monday, 6=Sunday).
 */
'weekdays'?: Array<number>;
}
}

export function restartChargingStationsBySchedule(http: HttpClient, rootUrl: string, params: RestartChargingStationsBySchedule$Params, context?: HttpContext): Observable<StrictHttpResponse<ResetResponse>> {
  const rb = new RequestBuilder(rootUrl, restartChargingStationsBySchedule.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/json');
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<ResetResponse>;
    })
  );
}

restartChargingStationsBySchedule.PATH = '/restarts/scheduledRestart/';
