import { animate, query, stagger, style, transition, trigger, useAnimation } from '@angular/animations';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, Renderer2 } from '@angular/core';
import { fadeIn, fadeOut, smoothHeight } from 'src/app/core/helpers/animations';

@Component({
    selector: 'app-modal',
    template: `
        <div
            *ngIf="isOpen"
            [@modalQueryAnim]
        >
            <div 
                class="app-modal backdrop justify-modal-{{ positionX }} align-modal-{{ positionY }} modal-height-{{ height }}"
                (mousedown)="backdropClick($event)"
            >
                <div class="modal-content width-{{ width }}">
                    <div class="modal-header">
                        <ng-content select="[header]"></ng-content>
                        <button 
                            class="close-modal" 
                            aria-label="close modal" 
                            title="close modal"
                            (click)="toggleModal()"
                        >
                            <div class="material-icon default-icon">close</div>
                        </button>
                    </div>
                    <div 
                        class="modal-body"
                        [class.p-0]="noBodyPadding"
                    >
                        <ng-content select="[body]"></ng-content>
                    </div>
                    <div class="modal-footer">
                        <ng-content select="[footer]"></ng-content>
                    </div>
                </div>
            </div>
        </div>
    `,
    styleUrls: ['./modal.component.scss'],
    animations: [
        smoothHeight,
        trigger('fadeInOutAnim', [
            transition(':enter', [
                useAnimation(fadeIn)
            ]),
            transition(':leave', [
                useAnimation(fadeOut)
            ])
        ]),
        trigger('modalQueryAnim', [
            transition(':enter', [
                query('.modal-content', [
                    style({transform: 'translateY(20px)', opacity: 0 }),
                    animate('.2s ease-out', 
                    style({transform: 'translateY(0px)', opacity: 1 }))
                ], { optional: true }),
                query('.backdrop', [
                    stagger(100, [
                        animate('.2s ease-out', 
                        style({ opacity: 1 }))
                    ])
                ], { optional: true })
            ]),
            transition(':leave', [
                query('.modal-content', [
                    style({ opacity: 1, transform: 'translateY(0px)' }),
                    animate('.15s ease-out', 
                        style({ opacity: 0, transform: 'translateY(20px)' })
                    )
                ], { optional: true }),
                query('.backdrop', [
                    stagger(10, [
                        style({ opacity: 1 }),
                        animate('.1s ease-in', 
                        style({ opacity: 0 }))
                    ])
                ], { optional: true })
            ])
        ])
    ],
    host: {
        '[@modalQueryAnim]': 'this.isOpen'
    },
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ModalComponent {
    @Input() set open(isOpen: boolean | null) {
        if (isOpen == null) return
        this.toggleModal(isOpen)
    }
    @Output() openChange = new EventEmitter<boolean>(false)
    @Input() positionX: 'left' | 'center' | 'right' = 'center';
    @Input() positionY: 'top' | 'center' | 'bottom' = 'center';
    @Input() height: 'auto' | 'full' = 'auto';
    @Input() width: 'small' | 'default' | 'large' = 'default';
    @Input() noBodyPadding: boolean = false;

    private _unlistenEvents: (() => void)[] = [];
    public isOpen: boolean = false;

    constructor(
        private _renderer: Renderer2
    ) {}

    private _setListeners() {
        this._unlistenEvents.push(
            this._renderer.listen('window', 'keydown.esc', () => {
                if (this.isOpen) this.toggleModal()
            })
        )
    }

    private _removeListeners() {
        this._unlistenEvents.forEach((fn) => fn())
    }

    toggleModal(forcedState?: boolean) {
        this.isOpen = forcedState ?? !this.isOpen
        this.isOpen ? this._setListeners() : this._removeListeners()
        this.openChange.emit(this.isOpen)
    }

    // if click on backdrop, toggle modal
    backdropClick(event: Event) {
        if (!(event.target instanceof Element)) return
        if (event.target.classList.contains('backdrop')) this.toggleModal()
    }
}
