import { Injectable } from "@angular/core";
import { createStore, getRegistry, select, setProp, withProps } from "@ngneat/elf";
import { localStorageStrategy, persistState } from "@ngneat/elf-persist-state";

const mapStateStore = createStore(
    {name: 'mapState'},
    withProps<{
        showGraphs: boolean // keep track if map graphs are shown
    }>({
        showGraphs: false
    })
)

persistState(mapStateStore, {
    key: 'mapState',
    storage: localStorageStrategy
})
@Injectable({ providedIn: 'root' })
export class mapRepository {

    constructor() {}

    // show map graphs
    showGraphs$ = mapStateStore.pipe(
        select((state) => state.showGraphs)
    )

    setGraphsShown(show: boolean) {
        mapStateStore.update(setProp('showGraphs', show))
    }

    public toggleGraphsShown() {
        mapStateStore.update(setProp('showGraphs', !mapStateStore.getValue().showGraphs))
    }
}
