/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { DataBaseService } from '../data-base-service';
import { DataApiConfiguration } from '../data-api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ApiResponse } from '../models/api-response';
import { Invitee } from '../models/invitee';
import { User } from '../models/user';


/**
 * User Administration
 */
@Injectable({
  providedIn: 'root',
})
export class AdminService extends DataBaseService {
  constructor(
    config: DataApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getUsers
   */
  static readonly GetUsersPath = '/admin/users/';

  /**
   * Return list of users.
   *
   * Return a list of all users with their roles and tenants
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getUsers()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUsers$Response(params?: {
  }): Observable<StrictHttpResponse<Array<User>>> {

    const rb = new RequestBuilder(this.rootUrl, AdminService.GetUsersPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<User>>;
      })
    );
  }

  /**
   * Return list of users.
   *
   * Return a list of all users with their roles and tenants
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getUsers$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUsers(params?: {
  }): Observable<Array<User>> {

    return this.getUsers$Response(params).pipe(
      map((r: StrictHttpResponse<Array<User>>) => r.body as Array<User>)
    );
  }

  /**
   * Path part for operation getUser
   */
  static readonly GetUserPath = '/admin/users/{uuid}/';

  /**
   * Return a single users.
   *
   * Return a list of all users with their roles and tenants
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getUser()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUser$Response(params: {

    /**
     * Internal uuid of the user
     */
    uuid: string;
  }): Observable<StrictHttpResponse<User>> {

    const rb = new RequestBuilder(this.rootUrl, AdminService.GetUserPath, 'get');
    if (params) {
      rb.path('uuid', params.uuid, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<User>;
      })
    );
  }

  /**
   * Return a single users.
   *
   * Return a list of all users with their roles and tenants
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getUser$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUser(params: {

    /**
     * Internal uuid of the user
     */
    uuid: string;
  }): Observable<User> {

    return this.getUser$Response(params).pipe(
      map((r: StrictHttpResponse<User>) => r.body as User)
    );
  }

  /**
   * Path part for operation deleteUser
   */
  static readonly DeleteUserPath = '/admin/users/{uuid}/';

  /**
   * Delete a single users.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteUser()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteUser$Response(params: {

    /**
     * Internal uuid of the user
     */
    uuid: string;
  }): Observable<StrictHttpResponse<ApiResponse>> {

    const rb = new RequestBuilder(this.rootUrl, AdminService.DeleteUserPath, 'delete');
    if (params) {
      rb.path('uuid', params.uuid, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ApiResponse>;
      })
    );
  }

  /**
   * Delete a single users.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteUser$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteUser(params: {

    /**
     * Internal uuid of the user
     */
    uuid: string;
  }): Observable<ApiResponse> {

    return this.deleteUser$Response(params).pipe(
      map((r: StrictHttpResponse<ApiResponse>) => r.body as ApiResponse)
    );
  }

  /**
   * Path part for operation updateUser
   */
  static readonly UpdateUserPath = '/admin/users/{uuid}/';

  /**
   * Update a single users.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateUser()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateUser$Response(params: {

    /**
     * Internal uuid of the user
     */
    uuid: string;
    body?: {
'user': User;
}
  }): Observable<StrictHttpResponse<ApiResponse>> {

    const rb = new RequestBuilder(this.rootUrl, AdminService.UpdateUserPath, 'patch');
    if (params) {
      rb.path('uuid', params.uuid, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ApiResponse>;
      })
    );
  }

  /**
   * Update a single users.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateUser$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateUser(params: {

    /**
     * Internal uuid of the user
     */
    uuid: string;
    body?: {
'user': User;
}
  }): Observable<ApiResponse> {

    return this.updateUser$Response(params).pipe(
      map((r: StrictHttpResponse<ApiResponse>) => r.body as ApiResponse)
    );
  }

  /**
   * Path part for operation deleteUsers
   */
  static readonly DeleteUsersPath = '/admin/users/delete/';

  /**
   * Delete a list of users by uuids.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteUsers()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  deleteUsers$Response(params?: {
    body?: Array<string>
  }): Observable<StrictHttpResponse<ApiResponse>> {

    const rb = new RequestBuilder(this.rootUrl, AdminService.DeleteUsersPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ApiResponse>;
      })
    );
  }

  /**
   * Delete a list of users by uuids.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteUsers$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  deleteUsers(params?: {
    body?: Array<string>
  }): Observable<ApiResponse> {

    return this.deleteUsers$Response(params).pipe(
      map((r: StrictHttpResponse<ApiResponse>) => r.body as ApiResponse)
    );
  }

  /**
   * Path part for operation inviteUsers
   */
  static readonly InviteUsersPath = '/admin/users/invite/';

  /**
   * Invite a list of users by mail address.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inviteUsers()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  inviteUsers$Response(params?: {
    body?: {

/**
 * List of users to invite
 */
'invitees': Array<Invitee>;
}
  }): Observable<StrictHttpResponse<Array<string>>> {

    const rb = new RequestBuilder(this.rootUrl, AdminService.InviteUsersPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<string>>;
      })
    );
  }

  /**
   * Invite a list of users by mail address.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `inviteUsers$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  inviteUsers(params?: {
    body?: {

/**
 * List of users to invite
 */
'invitees': Array<Invitee>;
}
  }): Observable<Array<string>> {

    return this.inviteUsers$Response(params).pipe(
      map((r: StrictHttpResponse<Array<string>>) => r.body as Array<string>)
    );
  }

  /**
   * Path part for operation addTenant
   */
  static readonly AddTenantPath = '/admin/users/addTenant/';

  /**
   * Add a tenant for a list of users.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addTenant()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addTenant$Response(params?: {
    body?: {

/**
 * Name of the tenant to add.
 */
'tenant': string;

/**
 * List of user uuids to add the tenant to.
 */
'uuids': Array<string>;
}
  }): Observable<StrictHttpResponse<ApiResponse>> {

    const rb = new RequestBuilder(this.rootUrl, AdminService.AddTenantPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ApiResponse>;
      })
    );
  }

  /**
   * Add a tenant for a list of users.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `addTenant$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addTenant(params?: {
    body?: {

/**
 * Name of the tenant to add.
 */
'tenant': string;

/**
 * List of user uuids to add the tenant to.
 */
'uuids': Array<string>;
}
  }): Observable<ApiResponse> {

    return this.addTenant$Response(params).pipe(
      map((r: StrictHttpResponse<ApiResponse>) => r.body as ApiResponse)
    );
  }

  /**
   * Path part for operation removeTenant
   */
  static readonly RemoveTenantPath = '/admin/users/removeTenant/';

  /**
   * Remove a tenant for a list of users.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `removeTenant()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  removeTenant$Response(params?: {
    body?: {

/**
 * Name of the tenant to remove.
 */
'tenant': string;

/**
 * List of user uuids to remove the tenant from.
 */
'uuids': Array<string>;
}
  }): Observable<StrictHttpResponse<ApiResponse>> {

    const rb = new RequestBuilder(this.rootUrl, AdminService.RemoveTenantPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ApiResponse>;
      })
    );
  }

  /**
   * Remove a tenant for a list of users.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `removeTenant$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  removeTenant(params?: {
    body?: {

/**
 * Name of the tenant to remove.
 */
'tenant': string;

/**
 * List of user uuids to remove the tenant from.
 */
'uuids': Array<string>;
}
  }): Observable<ApiResponse> {

    return this.removeTenant$Response(params).pipe(
      map((r: StrictHttpResponse<ApiResponse>) => r.body as ApiResponse)
    );
  }

  /**
   * Path part for operation addRole
   */
  static readonly AddRolePath = '/admin/users/addRole/';

  /**
   * Add a role for a list of users.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addRole()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addRole$Response(params?: {
    body?: {

/**
 * role to add.
 */
'role'?: string;

/**
 * List of user uuids to add the role to.
 */
'uuids': Array<string>;
}
  }): Observable<StrictHttpResponse<ApiResponse>> {

    const rb = new RequestBuilder(this.rootUrl, AdminService.AddRolePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ApiResponse>;
      })
    );
  }

  /**
   * Add a role for a list of users.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `addRole$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addRole(params?: {
    body?: {

/**
 * role to add.
 */
'role'?: string;

/**
 * List of user uuids to add the role to.
 */
'uuids': Array<string>;
}
  }): Observable<ApiResponse> {

    return this.addRole$Response(params).pipe(
      map((r: StrictHttpResponse<ApiResponse>) => r.body as ApiResponse)
    );
  }

  /**
   * Path part for operation removeRole
   */
  static readonly RemoveRolePath = '/admin/users/removeRole/';

  /**
   * Remove a role for a list of users.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `removeRole()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  removeRole$Response(params?: {
    body?: {

/**
 * Name of the role to remove.
 */
'role'?: string;

/**
 * List of user uuids to remove the role from.
 */
'uuids': Array<string>;
}
  }): Observable<StrictHttpResponse<ApiResponse>> {

    const rb = new RequestBuilder(this.rootUrl, AdminService.RemoveRolePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ApiResponse>;
      })
    );
  }

  /**
   * Remove a role for a list of users.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `removeRole$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  removeRole(params?: {
    body?: {

/**
 * Name of the role to remove.
 */
'role'?: string;

/**
 * List of user uuids to remove the role from.
 */
'uuids': Array<string>;
}
  }): Observable<ApiResponse> {

    return this.removeRole$Response(params).pipe(
      map((r: StrictHttpResponse<ApiResponse>) => r.body as ApiResponse)
    );
  }

/**
 * Path part for operation addTenantsToUsers
 */
  static readonly AddTenantsToUsersPath = '/admin/users/addTenantsToUsers/';

  /**
   * Add a list of tenants to a list of users.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addTenantsToUsers()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addTenantsToUsers$Response(params?: {
    body?: {
  
/**
 * List of tenants to add.
 */
'tenants': Array<string>;
  
/**
 * List of user uuids to add the tenant to.
 */
'uuids': Array<string>;
}
  }): Observable<StrictHttpResponse<ApiResponse>> {
  
    const rb = new RequestBuilder(this.rootUrl, AdminService.AddTenantsToUsersPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }
  
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ApiResponse>;
      })
    );
  }
  
  /**
   * Add a list of tenants to a list of users.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `addTenantsToUsers$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addTenantsToUsers(params?: {
    body?: {
  
/**
 * List of tenants to add.
 */
'tenants': Array<string>;
  
/**
 * List of user uuids to add the tenant to.
 */
'uuids': Array<string>;
}
  }): Observable<ApiResponse> {

    return this.addTenantsToUsers$Response(params).pipe(
      map((r: StrictHttpResponse<ApiResponse>) => r.body as ApiResponse)
    );
  }
  
  /**
   * Path part for operation removeTenantsFromUsers
   */
  static readonly RemoveTenantsFromUsersPath = '/admin/users/removeTenantsFromUsers/';
  
  /**
   * Remove a list if tenants from a list of users.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `removeTenantsFromUsers()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  removeTenantsFromUsers$Response(params?: {
    body?: {
  
/**
 * List of tenants to remove.
 */
'tenants': Array<string>;
  
/**
 * List of user uuids to remove the tenants from.
 */
'uuids': Array<string>;
}
  }): Observable<StrictHttpResponse<ApiResponse>> {

    const rb = new RequestBuilder(this.rootUrl, AdminService.RemoveTenantsFromUsersPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }
  
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ApiResponse>;
      })
    );
  }
  
  /**
   * Remove a list if tenants from a list of users.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `removeTenantsFromUsers$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  removeTenantsFromUsers(params?: {
    body?: {
  
/**
 * List of tenants to remove.
 */
'tenants': Array<string>;
  
/**
 * List of user uuids to remove the tenants from.
 */
'uuids': Array<string>;
}
  }): Observable<ApiResponse> {

    return this.removeTenantsFromUsers$Response(params).pipe(
      map((r: StrictHttpResponse<ApiResponse>) => r.body as ApiResponse)
    );
  }  
}
