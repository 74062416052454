/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { DataBaseService } from '../data-base-service';
import { DataApiConfiguration } from '../data-api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { deleteRestarts } from '../fn/restarts/delete-restarts';
import { DeleteRestarts$Params } from '../fn/restarts/delete-restarts';
import { DeleteRestartsResponse } from '../models/delete-restarts-response';
import { deleteSchedules } from '../fn/restarts/delete-schedules';
import { DeleteSchedules$Params } from '../fn/restarts/delete-schedules';
import { DeleteSchedulesResponse } from '../models/delete-schedules-response';
import { getOnIdleRestarts } from '../fn/restarts/get-on-idle-restarts';
import { GetOnIdleRestarts$Params } from '../fn/restarts/get-on-idle-restarts';
import { getRestartEventsOfChargingStation } from '../fn/restarts/get-restart-events-of-charging-station';
import { GetRestartEventsOfChargingStation$Params } from '../fn/restarts/get-restart-events-of-charging-station';
import { getSchedules } from '../fn/restarts/get-schedules';
import { GetSchedules$Params } from '../fn/restarts/get-schedules';
import { ResetResponse } from '../models/reset-response';
import { Restart } from '../models/restart';
import { restartChargingStationsBySchedule } from '../fn/restarts/restart-charging-stations-by-schedule';
import { RestartChargingStationsBySchedule$Params } from '../fn/restarts/restart-charging-stations-by-schedule';
import { restartChargingStationsManually } from '../fn/restarts/restart-charging-stations-manually';
import { RestartChargingStationsManually$Params } from '../fn/restarts/restart-charging-stations-manually';
import { RestartEvent } from '../models/restart-event';
import { RestartSchedule } from '../models/restart-schedule';

@Injectable({ providedIn: 'root' })
export class RestartsService extends DataBaseService {
  constructor(config: DataApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getRestartEventsOfChargingStation()` */
  static readonly GetRestartEventsOfChargingStationPath = '/restarts/{stationId}/restartEvents/';

  /**
   * Gets restart events of charging station.
   *
   * Fetch the history of last restarts of a specific charging station, optionally for a specific date interval
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getRestartEventsOfChargingStation()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRestartEventsOfChargingStation$Response(params: GetRestartEventsOfChargingStation$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<RestartEvent>>> {
    return getRestartEventsOfChargingStation(this.http, this.rootUrl, params, context);
  }

  /**
   * Gets restart events of charging station.
   *
   * Fetch the history of last restarts of a specific charging station, optionally for a specific date interval
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getRestartEventsOfChargingStation$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRestartEventsOfChargingStation(params: GetRestartEventsOfChargingStation$Params, context?: HttpContext): Observable<Array<RestartEvent>> {
    return this.getRestartEventsOfChargingStation$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<RestartEvent>>): Array<RestartEvent> => r.body)
    );
  }

  /** Path part for operation `getOnIdleRestarts()` */
  static readonly GetOnIdleRestartsPath = '/restarts/getOnIdleRestarts/';

  /**
   * Retrieves pending onIdle restarts in the restarts index for a list of charging stations.
   *
   * Retrieves Restarts of RestartStatus "pending" of mode manual and onIdle in the restarts index for a list of charging stations.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getOnIdleRestarts()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOnIdleRestarts$Response(params?: GetOnIdleRestarts$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<Restart>>> {
    return getOnIdleRestarts(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves pending onIdle restarts in the restarts index for a list of charging stations.
   *
   * Retrieves Restarts of RestartStatus "pending" of mode manual and onIdle in the restarts index for a list of charging stations.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getOnIdleRestarts$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOnIdleRestarts(params?: GetOnIdleRestarts$Params, context?: HttpContext): Observable<Array<Restart>> {
    return this.getOnIdleRestarts$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<Restart>>): Array<Restart> => r.body)
    );
  }

  /** Path part for operation `getSchedules()` */
  static readonly GetSchedulesPath = '/restarts/getSchedules/';

  /**
   * Retrieves restart schedules in the restart schedules index for a list of charging stations.
   *
   * Retrieves restart schedules in the restart schedules index for a list of charging stations. Each station can only have one schedule associated with it.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getSchedules()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSchedules$Response(params?: GetSchedules$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<RestartSchedule>>> {
    return getSchedules(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves restart schedules in the restart schedules index for a list of charging stations.
   *
   * Retrieves restart schedules in the restart schedules index for a list of charging stations. Each station can only have one schedule associated with it.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getSchedules$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSchedules(params?: GetSchedules$Params, context?: HttpContext): Observable<Array<RestartSchedule>> {
    return this.getSchedules$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<RestartSchedule>>): Array<RestartSchedule> => r.body)
    );
  }

  /** Path part for operation `restartChargingStationsManually()` */
  static readonly RestartChargingStationsManuallyPath = '/restarts/manualRestart/';

  /**
   * Creates entries in the restarts index for a list of charging stations to be restarted.
   *
   * Creates entries of RestartStatus "pending" of mode manual and onIdle restart in the restarts index according to the type of restart selected for a list of charging stations. The restarts worker lambda later processes and triggers the respective restart either immediately or when the station is not charging (onIdle).
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `restartChargingStationsManually()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  restartChargingStationsManually$Response(params: RestartChargingStationsManually$Params, context?: HttpContext): Observable<StrictHttpResponse<ResetResponse>> {
    return restartChargingStationsManually(this.http, this.rootUrl, params, context);
  }

  /**
   * Creates entries in the restarts index for a list of charging stations to be restarted.
   *
   * Creates entries of RestartStatus "pending" of mode manual and onIdle restart in the restarts index according to the type of restart selected for a list of charging stations. The restarts worker lambda later processes and triggers the respective restart either immediately or when the station is not charging (onIdle).
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `restartChargingStationsManually$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  restartChargingStationsManually(params: RestartChargingStationsManually$Params, context?: HttpContext): Observable<ResetResponse> {
    return this.restartChargingStationsManually$Response(params, context).pipe(
      map((r: StrictHttpResponse<ResetResponse>): ResetResponse => r.body)
    );
  }

  /** Path part for operation `restartChargingStationsBySchedule()` */
  static readonly RestartChargingStationsBySchedulePath = '/restarts/scheduledRestart/';

  /**
   * Creates entries in the restarts schedule index for a list of charging stations to be restarted.
   *
   * Creates entries for restart schedules (weekly or daily) according to the weekdays selected for a list of charging stations. The restarts worker lambda later processes and triggers the respective restart according to schedule.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `restartChargingStationsBySchedule()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  restartChargingStationsBySchedule$Response(params: RestartChargingStationsBySchedule$Params, context?: HttpContext): Observable<StrictHttpResponse<ResetResponse>> {
    return restartChargingStationsBySchedule(this.http, this.rootUrl, params, context);
  }

  /**
   * Creates entries in the restarts schedule index for a list of charging stations to be restarted.
   *
   * Creates entries for restart schedules (weekly or daily) according to the weekdays selected for a list of charging stations. The restarts worker lambda later processes and triggers the respective restart according to schedule.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `restartChargingStationsBySchedule$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  restartChargingStationsBySchedule(params: RestartChargingStationsBySchedule$Params, context?: HttpContext): Observable<ResetResponse> {
    return this.restartChargingStationsBySchedule$Response(params, context).pipe(
      map((r: StrictHttpResponse<ResetResponse>): ResetResponse => r.body)
    );
  }

  /** Path part for operation `deleteSchedules()` */
  static readonly DeleteSchedulesPath = '/restarts/deleteSchedules/';

  /**
   * Deletes entries in the restart schedules index for a list of charging stations.
   *
   * Deletes entries for restart schedules (weekly or daily) for a list of charging stations. The restarts worker lambda will not process any schedule for these stations anymore.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteSchedules()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteSchedules$Response(params?: DeleteSchedules$Params, context?: HttpContext): Observable<StrictHttpResponse<DeleteSchedulesResponse>> {
    return deleteSchedules(this.http, this.rootUrl, params, context);
  }

  /**
   * Deletes entries in the restart schedules index for a list of charging stations.
   *
   * Deletes entries for restart schedules (weekly or daily) for a list of charging stations. The restarts worker lambda will not process any schedule for these stations anymore.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteSchedules$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteSchedules(params?: DeleteSchedules$Params, context?: HttpContext): Observable<DeleteSchedulesResponse> {
    return this.deleteSchedules$Response(params, context).pipe(
      map((r: StrictHttpResponse<DeleteSchedulesResponse>): DeleteSchedulesResponse => r.body)
    );
  }

  /** Path part for operation `deleteRestarts()` */
  static readonly DeleteRestartsPath = '/restarts/deleteRestarts/';

  /**
   * Deletes entries in the restarts index for a list of charging stations.
   *
   * Deletes entries for onIdle restarts for a list of charging stations. The restarts worker lambda will not process this onIdle restart anymore.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteRestarts()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteRestarts$Response(params?: DeleteRestarts$Params, context?: HttpContext): Observable<StrictHttpResponse<DeleteRestartsResponse>> {
    return deleteRestarts(this.http, this.rootUrl, params, context);
  }

  /**
   * Deletes entries in the restarts index for a list of charging stations.
   *
   * Deletes entries for onIdle restarts for a list of charging stations. The restarts worker lambda will not process this onIdle restart anymore.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteRestarts$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteRestarts(params?: DeleteRestarts$Params, context?: HttpContext): Observable<DeleteRestartsResponse> {
    return this.deleteRestarts$Response(params, context).pipe(
      map((r: StrictHttpResponse<DeleteRestartsResponse>): DeleteRestartsResponse => r.body)
    );
  }

}
