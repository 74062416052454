import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { Filter } from 'src/app/core/stores/station-filters.repository';

@Component({
    selector: 'filter-select',
    template: `
        @if (activeFilter) {
            <div 
                class="filter"
                [class.inactive]="activeFilter.value == undefined || activeFilter.value.length === 0"
                [class.zero-options]="!showDeleteButton && !showResetButton"
                [class.one-option]="showDeleteButton != showResetButton"
                [class.two-options]="showDeleteButton && showResetButton"
                [class.loading]="loading"
            >
                <div class="inner">
                    @switch (activeFilter.type) {
                        @case ('select-multiple') {
                            <app-select-multiple
                                [title]="activeFilter.label"
                                [options]="activeFilter.options"
                                [disabledValues]="activeFilter.inactiveValues"
                                [pseudoDisable]="pseudoDisable"
                                size="small"
                                [required]="activeFilter.required"
                                [disabled]="disabled"
                                [keepPosition]="keepPosition"
                                [selectedValues]="activeFilter.value"
                                (selectedValuesChange)="onSelectionChange.emit($event.current)"
                            ></app-select-multiple>
                        }
                        @case ('select-single-radio') {
                            <app-select-single-radio
                                [title]="activeFilter.label"
                                [options]="activeFilter.options"
                                size="small"
                                [disabled]="disabled"
                                [keepPosition]="keepPosition"
                                [activeSelection]="activeFilter.value"
                                (activeSelectionChange)="onSelectionChange.emit($event)"
                            ></app-select-single-radio>
                        }
                        @case ('select-single') {
                            <app-select-single
                                [title]="activeFilter.label"
                                [options]="activeFilter.options ?? null"
                                size="small"
                                [required]="activeFilter.required"
                                [disabled]="disabled"
                                [disabledValues]="activeFilter.inactiveValues"
                                [keepPosition]="keepPosition"
                                [activeSelection]="activeFilter.value"
                                (activeSelectionChange)="onSelectionChange.emit($event)"
                            />
                        }
                        @case ('range') {
                            <app-select-range
                                [title]="activeFilter.label"
                                [key]="activeFilter.id"
                                [options]="activeFilter.rangePickerOptions!"
                                size="small"
                                [disabled]="disabled"
                                [keepPosition]="keepPosition"
                                [activeSelection]="activeFilter.value"
                                (activeSelectionChange)="onSelectionChange.emit($event)"
                            ></app-select-range>
                        }
                        @case ('date-time-range') {
                            <app-select-date-range
                                [title]="activeFilter.label"
                                [key]="activeFilter.id"
                                [minMaxDates]="activeFilter.options"
                                [showTime]="true"
                                size="small"
                                [disabled]="disabled"
                                [keepPosition]="keepPosition"
                                [activeSelection]="activeFilter.value"
                                (activeSelectionChange)="onSelectionChange.emit($event)"
                            ></app-select-date-range>
                        }
                        @case ('date-range') {
                            <app-select-date-range
                                [title]="activeFilter.label"
                                [key]="activeFilter.id"
                                [minMaxDates]="activeFilter.options"
                                [showTime]="false"
                                size="small"
                                [disabled]="disabled"
                                [keepPosition]="keepPosition"
                                [activeSelection]="activeFilter.value"
                                (activeSelectionChange)="onSelectionChange.emit($event)"
                            ></app-select-date-range>
                        }
                    }
                </div>
                @if (showResetButton) {
                    <button 
                        class="reset"
                        type="button"
                        [tabindex]="(activeFilter.value == undefined || activeFilter.value.length === 0) ? -1 : 1"
                        [disabled]="disabled"
                        [class.required]="activeFilter.required"
                        [tooltip]="'FILTERS.RESET_FILTER' | translate"
                size="small"
                        (click)="onResetFilter.emit(activeFilter)"
                    >
                        <span class="material-icon">rotate_right</span>
                    </button>
                }
                @if (showDeleteButton) {
                    <button 
                        class="delete"
                        type="button"
                        tabindex="1"
                        [disabled]="disabled"
                        [class.required]="activeFilter.required"
                        [tooltip]="'FILTERS.DELETE_FILTER' | translate"
                size="small"
                        (click)="onDeleteFilter.emit(activeFilter)"
                    >
                        <span class="material-icon">delete_outline</span>
                    </button>
                }
            </div>
        }
    `,
    styleUrls: ['./filter-select.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class FilterSelectComponent {
    @Input() activeFilter: Filter | null = null;
    @Input() showResetButton: boolean = true;
    @Input() showDeleteButton: boolean = true;
    @Input() pseudoDisable: boolean = false;
    @Input() loading: boolean | null = false;
    @Input() disabled: boolean = false;
    // will keep initial position of dropdown content
    @Input() keepPosition: boolean = false;
    @Output() onSelectionChange = new EventEmitter<any>();
    @Output() onResetFilter = new EventEmitter<Filter>();
    @Output() onDeleteFilter = new EventEmitter<Filter>();

    constructor() { }
}
