/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { ChargedAmount } from '../../models/charged-amount';

export interface GetChartChargedAmount$Params {

/**
 * End of time interval
 */
  date?: string;

/**
 * The period in days before 'date'
 */
  interval?: number;

/**
 * The length of all segments in minutes
 */
  segmentLength?: number;

/**
 * Unique identifier of the Charging Station
 */
  stationIds?: Array<string>;

/**
 * Term to search for in all fields of "#/components/schemas/ChargingStation"
 */
  search?: string;

/**
 * String representation of a complex search expression in url-encoded format. The query is a list of key-value pairs, the key being a field of "#/components/schemas/ChargingStation"
 */
  searchFields?: string;

/**
 * String representation of a complex filter expression
 */
  filters?: string;

/**
 * Include only hidden stations
 */
  showOnlyHidden?: boolean;
}

export function getChartChargedAmount(http: HttpClient, rootUrl: string, params?: GetChartChargedAmount$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ChargedAmount>>> {
  const rb = new RequestBuilder(rootUrl, getChartChargedAmount.PATH, 'get');
  if (params) {
    rb.query('date', params.date, {});
    rb.query('interval', params.interval, {});
    rb.query('segmentLength', params.segmentLength, {});
    rb.query('stationIds', params.stationIds, {});
    rb.query('search', params.search, {});
    rb.query('searchFields', params.searchFields, {});
    rb.query('filters', params.filters, {});
    rb.query('showOnlyHidden', params.showOnlyHidden, {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<Array<ChargedAmount>>;
    })
  );
}

getChartChargedAmount.PATH = '/chargedAmount/';
