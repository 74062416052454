/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { DataBaseService } from '../data-base-service';
import { DataApiConfiguration } from '../data-api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { getRestartEventsOfChargingStation } from '../fn/restarts/get-restart-events-of-charging-station';
import { GetRestartEventsOfChargingStation$Params } from '../fn/restarts/get-restart-events-of-charging-station';
import { RestartEvent } from '../models/restart-event';


/**
 * Analysis Tab for the restart History of a Charging Station
 */
@Injectable({ providedIn: 'root' })
export class DetailsRestartService extends DataBaseService {
  constructor(config: DataApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getRestartEventsOfChargingStation()` */
  static readonly GetRestartEventsOfChargingStationPath = '/restarts/{stationId}/restartEvents/';

  /**
   * Gets restart events of charging station.
   *
   * Fetch the history of last restarts of a specific charging station, optionally for a specific date interval
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getRestartEventsOfChargingStation()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRestartEventsOfChargingStation$Response(params: GetRestartEventsOfChargingStation$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<RestartEvent>>> {
    return getRestartEventsOfChargingStation(this.http, this.rootUrl, params, context);
  }

  /**
   * Gets restart events of charging station.
   *
   * Fetch the history of last restarts of a specific charging station, optionally for a specific date interval
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getRestartEventsOfChargingStation$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRestartEventsOfChargingStation(params: GetRestartEventsOfChargingStation$Params, context?: HttpContext): Observable<Array<RestartEvent>> {
    return this.getRestartEventsOfChargingStation$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<RestartEvent>>): Array<RestartEvent> => r.body)
    );
  }

}
