/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { DataBaseService } from '../data-base-service';
import { DataApiConfiguration } from '../data-api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ChargingStation } from '../models/charging-station';
import { OverviewMetaInfo } from '../models/overview-meta-info';


/**
 * Map View Tab for a Charging Station
 */
@Injectable({
  providedIn: 'root',
})
export class DetailsMapService extends DataBaseService {
  constructor(
    config: DataApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getChargingStations
   */
  static readonly GetChargingStationsPath = '/chargingStations/';

  /**
   * Gets list of charging stations.
   *
   * Fetch a paginated list of charging stations, optionally for a specific date
   * The returned list of charging stations is basically paginated. Only if latMin, latMax, lonMin and lonMax are set, the complete result list is returned. In that case the pagination and sorting parameters are ignored. In addition, the charging stations contain only the attributes needed for the display in the map.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getChargingStations()` instead.
   *
   * This method doesn't expect any request body.
   */
  getChargingStations$Response(params?: {

    /**
     * Time at which the list of chargers should be displayed
     */
    date?: string;

    /**
     * Number of the page
     */
    pageNumber?: number;

    /**
     * Number of items per page
     */
    perPage?: number;

    /**
     * Attribute to sort by
     */
    sortBy?: string;

    /**
     * Order of sorting
     */
    sortOrder?: 'asc' | 'desc';

    /**
     * Term to search for in all fields of &quot;#/components/schemas/ChargingStation&quot;
     */
    search?: string;

    /**
     * String representation of a complex search expression in url-encoded format. The query is a list of key-value pairs, the key being a field of &quot;#/components/schemas/ChargingStation&quot;
     */
    searchFields?: string;

    /**
     * String representation of a complex filter expression
     */
    filter?: string;

    /**
     * Minimum latitude of map section
     */
    latMin?: number;

    /**
     * Minimum latitude of map section
     */
    latMax?: number;

    /**
     * Maximum longitude of map section
     */
    lonMin?: number;

    /**
     * Maximum longitude of map section
     */
    lonMax?: number;

    /**
     * Include only hidden stations
     */
    showOnlyHidden?: boolean;
  }): Observable<StrictHttpResponse<{
'stations'?: Array<ChargingStation>;
'meta'?: OverviewMetaInfo;
}>> {

    const rb = new RequestBuilder(this.rootUrl, DetailsMapService.GetChargingStationsPath, 'get');
    if (params) {
      rb.query('date', params.date, {});
      rb.query('pageNumber', params.pageNumber, {});
      rb.query('perPage', params.perPage, {});
      rb.query('sortBy', params.sortBy, {});
      rb.query('sortOrder', params.sortOrder, {});
      rb.query('search', params.search, {});
      rb.query('searchFields', params.searchFields, {});
      rb.query('filter', params.filter, {});
      rb.query('latMin', params.latMin, {});
      rb.query('latMax', params.latMax, {});
      rb.query('lonMin', params.lonMin, {});
      rb.query('lonMax', params.lonMax, {});
      rb.query('showOnlyHidden', params.showOnlyHidden, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'stations'?: Array<ChargingStation>;
        'meta'?: OverviewMetaInfo;
        }>;
      })
    );
  }

  /**
   * Gets list of charging stations.
   *
   * Fetch a paginated list of charging stations, optionally for a specific date
   * The returned list of charging stations is basically paginated. Only if latMin, latMax, lonMin and lonMax are set, the complete result list is returned. In that case the pagination and sorting parameters are ignored. In addition, the charging stations contain only the attributes needed for the display in the map.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getChargingStations$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getChargingStations(params?: {

    /**
     * Time at which the list of chargers should be displayed
     */
    date?: string;

    /**
     * Number of the page
     */
    pageNumber?: number;

    /**
     * Number of items per page
     */
    perPage?: number;

    /**
     * Attribute to sort by
     */
    sortBy?: string;

    /**
     * Order of sorting
     */
    sortOrder?: 'asc' | 'desc';

    /**
     * Term to search for in all fields of &quot;#/components/schemas/ChargingStation&quot;
     */
    search?: string;

    /**
     * String representation of a complex search expression in url-encoded format. The query is a list of key-value pairs, the key being a field of &quot;#/components/schemas/ChargingStation&quot;
     */
    searchFields?: string;

    /**
     * String representation of a complex filter expression
     */
    filter?: string;

    /**
     * Minimum latitude of map section
     */
    latMin?: number;

    /**
     * Minimum latitude of map section
     */
    latMax?: number;

    /**
     * Maximum longitude of map section
     */
    lonMin?: number;

    /**
     * Maximum longitude of map section
     */
    lonMax?: number;

    /**
     * Include only hidden stations
     */
    showOnlyHidden?: boolean;
  }): Observable<{
'stations'?: Array<ChargingStation>;
'meta'?: OverviewMetaInfo;
}> {

    return this.getChargingStations$Response(params).pipe(
      map((r: StrictHttpResponse<{
'stations'?: Array<ChargingStation>;
'meta'?: OverviewMetaInfo;
}>) => r.body as {
'stations'?: Array<ChargingStation>;
'meta'?: OverviewMetaInfo;
})
    );
  }

  /**
   * Path part for operation getChargingStation
   */
  static readonly GetChargingStationPath = '/chargingStations/{stationId}/';

  /**
   * Gets a single charging station.
   *
   * Fetch a single charging stations, optionally for a specific date
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getChargingStation()` instead.
   *
   * This method doesn't expect any request body.
   */
  getChargingStation$Response(params: {

    /**
     * Unique identifier of the Charging Station
     */
    stationId: string;

    /**
     * Time at which state the charging station should be returned
     */
    date?: string;
  }): Observable<StrictHttpResponse<ChargingStation>> {

    const rb = new RequestBuilder(this.rootUrl, DetailsMapService.GetChargingStationPath, 'get');
    if (params) {
      rb.path('stationId', params.stationId, {});
      rb.query('date', params.date, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ChargingStation>;
      })
    );
  }

  /**
   * Gets a single charging station.
   *
   * Fetch a single charging stations, optionally for a specific date
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getChargingStation$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getChargingStation(params: {

    /**
     * Unique identifier of the Charging Station
     */
    stationId: string;

    /**
     * Time at which state the charging station should be returned
     */
    date?: string;
  }): Observable<ChargingStation> {

    return this.getChargingStation$Response(params).pipe(
      map((r: StrictHttpResponse<ChargingStation>) => r.body as ChargingStation)
    );
  }

}
