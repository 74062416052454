import { Injectable } from '@angular/core';
import { StateColors, StateHelperService } from './state-helper.service';

// Defining types for mapping
// previously used "typeof this.xx", which causes some TS problems: https://github.com/microsoft/TypeScript/issues/1554

type EventIconsMapping = [
    string[],
    string[]
]

type EventCategories = [
    // category
    ['electrical', 'device-communication', 'vendor', 'statusNotifications'],
    // group mapped events in categories
    [ string[], string[], string[], string[] ],
    // general icons of group, used when no mapped event can be found
    [string, string, string, string]
]

export type EventCodeEvaluation = [
    // available states
    ['Ok', 'To Be Monitored', 'Potential Failure', 'Failure', 'No Data'],
    // colors
    [string, string, string, string, string],
    [ any[], any[], any[], any[], any[] ]
]

@Injectable({
    providedIn: 'root'
})
export class EventIconsService {
    private _stateHelper: StateHelperService = new StateHelperService();
    private _stateColors: StateColors = this._stateHelper.getStateColors();

    // mapping of specific events
    public readonly eventIconsMapping: EventIconsMapping = [
        // events
        ['ConnectorLockFailure', 'EVCommunicationError', 'HighTemperature', 'InternalError', 'LocalListConflict', 'PowerMeterFailure', 'PowerSwitchFailure', 'ReaderFailure', 'ResetFailure', 'WeakSignal', 'StartTransaction', 'StopTransaction', 'StatusNotification', 'OtherError', 'NoError'],
        // mapped material icons (https://fonts.google.com/icons?icon.style=Outlined&icon.set=Material+Symbols&icon.platform=web)
        ['no_encryption', 'sync_alt', 'device_thermostat', 'memory', 'playlist_remove', 'electric_meter', 'settings', 'sensors_off', 'sync_disabled', 'wifi_off', 'power', 'power_off', 'info', 'priority_high', 'info']
    ];
    // mapping of categories
    public readonly eventCategories: EventCategories = [
        // category
        ['electrical', 'device-communication', 'vendor', 'statusNotifications'],
        // group mapped events in categories
        [   
            // "electrical"
            ['PowerMeterFailure', 'PowerSwitchFailure', 'GroundFailure', 'OverCurrentFailure', 'UnderVoltage', 'OverVoltage'],
            // "device and communication"
            ['ConnectorLockFailure', 'HighTemperature', 'InternalError', 'ReaderFailure', 'ResetFailure', 'EVCommunicationError', 'LocalListConflict', 'WeakSignal'],
            // "vendor"
            [ 'OtherError'],
            // "statusNotifications"
            []
        ],
        // general icons of group, used when no mapped event can be found
        ['bolt', 'sync_alt', 'priority_high', 'mail']
    ];

    public readonly eventCodeEvaluation: EventCodeEvaluation = [
        // available states
        ['Ok', 'To Be Monitored', 'Potential Failure', 'Failure', 'No Data'],
        // colors
        [this._stateColors.ok, this._stateColors.toBeMonitored, this._stateColors.potentialFailure, this._stateColors.failure, this._stateColors.noData],
        // mapped event errorCodes
        [
            // Ok
            [],
            // To Be Monitored
            [],
            // Potential Failure
            [],
            // Failure
            [],
            // No Data
            []
        ]
    ];

    constructor() { }

    // returns complete category of mapped event
    public getEventCategory(event: string): [
        EventCategories[0][number],
        EventCategories[1][number],
        EventCategories[2][number]
    ] {
        // find event in groups, get index of group
        let categoryIndex = this.eventCategories[1].indexOf(this.eventCategories[1].find((x: any) => x.indexOf(event) > -1) as any);
        // if no match was found, return "vendor" category as default
        categoryIndex = categoryIndex > -1 ? categoryIndex : this.eventCategories[0].indexOf('vendor');
        return [
            this.eventCategories[0][categoryIndex],
            this.eventCategories[1][categoryIndex],
            this.eventCategories[2][categoryIndex]
        ]
    }

    // get event icon from mapping
    public getEventIcon(event: EventIconsMapping[0][number] | string): EventIconsMapping[1][number] | EventCategories[2][number] {
        const index = this.eventIconsMapping[0].indexOf(event as EventIconsMapping[0][number]);
        // return matching icon or get fallback icon of group
        return index > -1 ? this.eventIconsMapping[1][index] : this.getEventCategory(event)[2];
    }

    // returns array of evaluation and string of color
    public getEventEvaluationColor(event: string): [evaluation: EventCodeEvaluation[0][number], color: string] {
        let evalIndex = this.eventCodeEvaluation[2].indexOf(this.eventCodeEvaluation[2].find((x: any) => x.indexOf(event) > -1) as any);
        // return "No Data" set with no match
        evalIndex = evalIndex > -1 ? evalIndex : this.eventCodeEvaluation[0].indexOf('No Data');
        return [
            this.eventCodeEvaluation[0][evalIndex],
            this.eventCodeEvaluation[1][evalIndex]
        ]
    }
}
