/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { DataBaseService } from '../data-base-service';
import { DataApiConfiguration } from '../data-api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { ErrorDistribution } from '../models/error-distribution';
import { getErrorDistributionByPlugType } from '../fn/advanced-analytics/get-error-distribution-by-plug-type';
import { GetErrorDistributionByPlugType$Params } from '../fn/advanced-analytics/get-error-distribution-by-plug-type';
import { getErrorDistributionByStation } from '../fn/advanced-analytics/get-error-distribution-by-station';
import { GetErrorDistributionByStation$Params } from '../fn/advanced-analytics/get-error-distribution-by-station';
import { getTopErrors } from '../fn/advanced-analytics/get-top-errors';
import { GetTopErrors$Params } from '../fn/advanced-analytics/get-top-errors';
import { TopError } from '../models/top-error';

@Injectable({ providedIn: 'root' })
export class AdvancedAnalyticsService extends DataBaseService {
  constructor(config: DataApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getTopErrors()` */
  static readonly GetTopErrorsPath = '/analytics/errorInsights/topErrors/';

  /**
   * Gets errors for error analytics over a group of stations.
   *
   * Gets top-10 errors in time interval for filtered stations.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getTopErrors()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTopErrors$Response(params?: GetTopErrors$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<TopError>>> {
    return getTopErrors(this.http, this.rootUrl, params, context);
  }

  /**
   * Gets errors for error analytics over a group of stations.
   *
   * Gets top-10 errors in time interval for filtered stations.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getTopErrors$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTopErrors(params?: GetTopErrors$Params, context?: HttpContext): Observable<Array<TopError>> {
    return this.getTopErrors$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<TopError>>): Array<TopError> => r.body)
    );
  }

  /** Path part for operation `getErrorDistributionByStation()` */
  static readonly GetErrorDistributionByStationPath = '/analytics/errorInsights/distributionByStationId/';

  /**
   * Gets distribution over stations given filters and an array of error-codes.
   *
   * Gets distribution over stations given filters and an array of error-codes
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getErrorDistributionByStation()` instead.
   *
   * This method doesn't expect any request body.
   */
  getErrorDistributionByStation$Response(params?: GetErrorDistributionByStation$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ErrorDistribution>>> {
    return getErrorDistributionByStation(this.http, this.rootUrl, params, context);
  }

  /**
   * Gets distribution over stations given filters and an array of error-codes.
   *
   * Gets distribution over stations given filters and an array of error-codes
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getErrorDistributionByStation$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getErrorDistributionByStation(params?: GetErrorDistributionByStation$Params, context?: HttpContext): Observable<Array<ErrorDistribution>> {
    return this.getErrorDistributionByStation$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ErrorDistribution>>): Array<ErrorDistribution> => r.body)
    );
  }

  /** Path part for operation `getErrorDistributionByPlugType()` */
  static readonly GetErrorDistributionByPlugTypePath = '/analytics/errorInsights/distributionByPlugType/';

  /**
   * Gets distribution over plug types given filters and an array of error-codes.
   *
   * Gets distribution over plug types given filters and an array of error-codes
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getErrorDistributionByPlugType()` instead.
   *
   * This method doesn't expect any request body.
   */
  getErrorDistributionByPlugType$Response(params?: GetErrorDistributionByPlugType$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ErrorDistribution>>> {
    return getErrorDistributionByPlugType(this.http, this.rootUrl, params, context);
  }

  /**
   * Gets distribution over plug types given filters and an array of error-codes.
   *
   * Gets distribution over plug types given filters and an array of error-codes
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getErrorDistributionByPlugType$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getErrorDistributionByPlugType(params?: GetErrorDistributionByPlugType$Params, context?: HttpContext): Observable<Array<ErrorDistribution>> {
    return this.getErrorDistributionByPlugType$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ErrorDistribution>>): Array<ErrorDistribution> => r.body)
    );
  }

}
