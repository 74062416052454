import { Recommendation } from "src/app/overview/overview.component";
import { ChargingStation, Connector } from "../data-backend/models";
import { EnumeratedState } from "./state-helper.service";
import { PermissionsService } from "../app-services/permissions.service";
import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";

export interface ExtendedChargingStation extends ChargingStation {
    recommendation?: Recommendation | null; 
    recommendationComment?: string | null;
    featuredConnector: Connector;
}

/**
 * This helper is used to provide different functions to transform the given ChargingStation object.
 * Feel free to add more functions to this helper, which can be used repeatedly in the application.
 */
@Injectable({
    providedIn: 'root'
})
export class ChargingStationTransformer {
    private _stateRanking: EnumeratedState[] = ['No Data', 'Ok', 'To Be Monitored', 'Potential Failure', 'Failure'];

    constructor(
        private _permService: PermissionsService,
        private _translate: TranslateService
    ) { }

    /**
     * returns a value corresponding rank based on a predefined ranking array.
     * @param {EnumeratedState} state - The `state` parameter in the `_rank` function
     * is of type `EnumeratedState`. 
     * @returns The `_rank` function returns a number representing the rank of the input state in the
     * `_stateRanking` array.
     */
    private _rank(state: EnumeratedState): number {
        let res = this._stateRanking.indexOf(state);
        // lowest rank is 0, undefined counts as "No Data"
        return res > -1 ? res : 0;
    }

    /**
     * takes a 2D array, flattens it, and returns a new array with unique, non-null and non-undefined elements.
     * @param {any[][]} array - The `array` parameter in the `_joinUnique` function is a
     * two-dimensional array of any type. Each element in the outer array represents a group of
     * elements, and each inner array contains the elements within that group.
     * @returns An array with unique values from the input array, after flattening it and filtering out
     * any null or undefined values.
     */
    private _joinUnique(array: any[][]): any[] {
        return array
                .flat()
                .filter((item, index, self) => item !== null && item !== undefined && self.indexOf(item) === index)
    }

    public extendedChargingStationTransformer(station: ChargingStation): ExtendedChargingStation {
        // set first connector as default
        let featuredConnector = { ...station.connectors[0] };
        
        if (station.connectors.length > 1) {
            // get all available and featured states from user permissions and merge when multiple permission sets are given
            //                                                                                   v  sadly this is needed 🤢🤮
            const featuredStates    = this._joinUnique((this._permService.getPermissions('global.featuredStates') as any[][])) as ('lastOverallState' | 'lastChargingModelState' | 'lastErrorState')[];
            const availableStates   = this._joinUnique((this._permService.getPermissions('global.availableStates') as any[][])) as ('lastOverallState' | 'lastChargingModelState' | 'lastErrorState')[];

            // map connectors and ranks
            const conStateRanks = station.connectors.map((connector) => {
                const featuredStatesRank    = featuredStates.reduce((acc, state) => acc + this._rank(connector[state]), 0);
                const availableStatesRank   = availableStates.reduce((acc, state) => acc + this._rank(connector[state]), 0);
                const rank = featuredStatesRank + availableStatesRank;

                return {con: connector, rank}
            });

            const hasHealthIndexPerms = this._permService.hasStateModel('lastHealthIndexValue');
            if (hasHealthIndexPerms) {
                // sort connectors by highest rank and lowest health index (if on same rank)
                conStateRanks.sort((a, b) => b.rank - a.rank || a.con.lastHealthIndexValue - b.con.lastHealthIndexValue);
            } else {
                // sort connectors by highest rank
                conStateRanks.sort((a, b) => b.rank - a.rank);
            }
            
            // additional sort by connectorId to ensure consistent order in app
            station.connectors = station.connectors.sort((a, b) => a.connectorId - b.connectorId)
            // set first con of stateRanks as featuredConnector
            featuredConnector = { ...conStateRanks[0].con } as Connector
        }

        // check if all provided data is null
        const allAverageUsageEnergyNull = station.connectors.every((con) => con.averageUsageEnergy === null || con.averageUsageEnergy === undefined);
        const allAverageUsageRegularSessionsNull = station.connectors.every((con) => con.averageUsageRegularSessions === null || con.averageUsageRegularSessions === undefined);

        // add average usage data for display in OV table if available
        // if not available, write value of -1 (the models provided through the openAPI specs expect these values to be defined). We'll filter out -1 values in the renderFn
        featuredConnector.averageUsageEnergy = allAverageUsageEnergyNull ? -1 : station.connectors.reduce((sum, conn) => sum + (conn.averageUsageEnergy || 0), 0);
        featuredConnector.averageUsageRegularSessions = allAverageUsageRegularSessionsNull ? -1 : station.connectors.reduce((sum, conn) => sum + (conn.averageUsageRegularSessions || 0), 0);

        // patch empty location IDs
        if (station['locationId'] == null) station['locationId'] = this._translate.instant('COMMON.NOT_AVAILABLE')

        return {
            ...station,
            featuredConnector
        };
    }
}
