import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, Output, ViewChild } from '@angular/core';
import { NzDatePickerComponent } from 'ng-zorro-antd/date-picker';
import { BehaviorSubject, Subject, delay, filter, take, takeUntil, tap } from 'rxjs';
import { SingleValue } from 'ng-zorro-antd/core/time';


@Component({
    selector: 'evc-date-input',
    template: `
        <div class="form-group date-picker-cont">
            <ng-template #icon>
                <span class="material-icon icon">date_range</span>
            </ng-template>
            <nz-date-picker
                #datePicker
                [class.small]="size == 'small'"
                [class.has-reset]="reset"
                [nzShowTime]="showTime ? { nzFormat: 'HH:mm' } : null"
                [nzFormat]="showTime ? 'dd.MM.yyyy HH:mm' : 'dd.MM.yyyy'"
                [nzSuffixIcon]="icon"
                [nzAllowClear]="false"
                [nzDisabled]="disabled"
                [nzDisabledDate]="disabledDates"
                [(ngModel)]="value"
                (nzOnOpenChange)="onOpenClose($event)"
            ></nz-date-picker>
            @if(reset) {
                <button 
                    class="reset"
                    type="button"
                    [class.small]="size == 'small'"
                    [tooltip]="'COMMON.RESET_TO_DEFAULT' | translate"
                    [disabled]="disabled"
                    (click)="onChange(value = reset == 'empty' ? null : reset)"
                >
                    <span class="material-icon">rotate_right</span>
                </button>
            }
            @if(title) {<label>{{ title }}</label>}
        </div>
    `,
    styleUrl: './date-input.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DateInputComponent implements OnDestroy {
    private readonly _destroying$ = new Subject<void>();
    private _datePickerRef$ = new BehaviorSubject<NzDatePickerComponent | null>(null);
    @ViewChild('datePicker') set datePicker(ref: NzDatePickerComponent) {
        this._datePickerRef$.next(ref)
    };
    @Input() title: string | null = null;
    // value / emission of update
    @Input() value: Date | null = null;
    // size of date picker
    @Input() size: 'large' | 'small' = 'large';
    // reset value - whether reset button will be displayed
    @Input() reset: Date | 'empty' | null = null;
    // enables time inputs
    @Input() showTime: boolean = true;
    @Output() valueChange = new EventEmitter<Date | null>();
    // limits the selectable range
    public disabledDates: ((current: Date) => boolean) = (current) => {
        let dateToCheck = new Date(current);
        if (this.min) {
            // check if min is in range
            dateToCheck.setHours(this.min.getHours(), this.min.getMinutes(), this.min.getSeconds());
            dateToCheck.setSeconds(dateToCheck.getSeconds() + 1);
            if (dateToCheck < this.min) return true;
        }
        if (this.max) {
            // check if max is in range
            dateToCheck.setHours(this.max.getHours(), this.max.getMinutes(), this.max.getSeconds());
            dateToCheck.setSeconds(dateToCheck.getSeconds() - 1);
            if (dateToCheck > this.max) return true;
        }
        return false;
    };
    @Input() min: Date | null = null;
    @Input() max: Date | null = null;
    @Input() disabled: boolean = false;
    // open / close state
    @Input() set open(data: boolean | null) {
        if (data === null) return;
        this._datePickerRef$.pipe(
            takeUntil(this._destroying$),
            filter((ref) => ref !== null),
            take(1),
            delay(20),
            tap((ref) => ref!.open())
        ).subscribe()
    };
    @Output() openChange = new EventEmitter<boolean>();

    constructor() {}

    onChange(result: Date | null | undefined): void {
        if (result) {
            // if selected date is larger than max - set result to max minus one sec
            if (this.max && result > this.max) {
                result = new Date(this.max.getTime() - 1000);
            // if selected date is smaller than min - set result to min plus one sec
            } else if (this.min && result < this.min) {
                result = new Date(this.min.getTime() + 1000);
            }
        }
        this.valueChange.emit(result);
    }

    onOpenClose(open: boolean): void {
        if (!open) {
            this._datePickerRef$.pipe(
                takeUntil(this._destroying$),
                take(1),
                tap((ref) => this.onChange((ref?.datePickerService.value as SingleValue)?.nativeDate)) 
            ).subscribe()
        }
    }

    ngOnDestroy(): void {
        this._destroying$.next(undefined);
        this._destroying$.complete();
    }
}
