import { ChangeDetectionStrategy, Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { combineLatest, map, Observable, startWith } from 'rxjs';
import { StationLocations } from 'src/app/core/data-backend/models';
import { overviewRepository } from 'src/app/core/stores/overview.repository';
import { stationFiltersRepository } from 'src/app/core/stores/station-filters.repository';
import { lastOverallState } from 'src/app/overview/overview-plots/overview-plots.component';


interface StateItem {
    name: lastOverallState | string,
    amount: number,
    active: boolean,
    type?: 'state' | 'hidden',
    onClick: () => void
}

@Component({
    selector: 'evc-overall-state-filter',
    template: `
        <div 
            class="overall-state-filter flex-shrink-0"
            [class.loading]="loading$ | async"
        >
            @for (item of stateItems$ | async; track item.name) {
                <button
                    class="filter-option"
                    [class.active]="item.active"
                    (click)="item.onClick()"
                >
                    @if (item.type == 'state') {
                        <div class="circle" [class]="item.name | stateClass: 'bg'"></div>
                        <span>{{ item.amount | localizedNumber }}</span>
                    } @else if (item.type == 'hidden') {
                        <span class="material-icon">
                            {{ item.active ? 'visibility' : 'visibility_off' }}
                        </span>
                        <span>{{ item.amount | localizedNumber }}</span>
                    } @else {
                        <span>{{ item.name }} {{ item.amount | localizedNumber }}</span>
                    }
                </button>
            }
        </div>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
    styleUrl: './overall-state-filter.component.scss'
})
export class OverallStateFilterComponent {
    // available selections in filter bar
    public stateItems$: Observable<StateItem[]>;
    public loading$ = this._repo.stationLocations$.pipe(
        map((res) => res.isLoading)
    )

    constructor(
        private _repo: overviewRepository,
        private _filtersRepo: stationFiltersRepository,
        private _translate: TranslateService
    ) {
        // update overall state filter
        this.stateItems$ = combineLatest({
            stationLocations: this._repo.stationLocations$,
            filterValues: this._filtersRepo.activeFilterValues$,
            showOnlyHidden: this._repo.showOnlyHidden$,
            hiddenStationLocations: this._repo.hiddenStationLocations$,
            newLang: this._translate.onLangChange.pipe(startWith(null))
        }).pipe(
            map(({stationLocations, filterValues, showOnlyHidden, hiddenStationLocations}) => {
                // create new empty state data list
                let newStateData: StateItem[] = [
                    { name: 'Ok', amount: 0, active: false, type: 'state', onClick: () => this.setOverallStateFilter('Ok') },
                    { name: 'To Be Monitored', amount: 0, active: false, type: 'state', onClick: () => this.setOverallStateFilter('To Be Monitored') },
                    { name: 'Potential Failure', amount: 0, active: false, type: 'state', onClick: () => this.setOverallStateFilter('Potential Failure') },
                    { name: 'Failure', amount: 0, active: false, type: 'state', onClick: () => this.setOverallStateFilter('Failure') },
                    { name: 'No Data', amount: 0, active: false, type: 'state', onClick: () => this.setOverallStateFilter('No Data') }
                ];

                // get the worst state of each station and add it to its state amount
                stationLocations.data.stationLocations.forEach((entry: StationLocations) => {
                    const worstState = this.getWorstState(entry.lastOverallState);
                    newStateData = newStateData.map(item => ({
                        ...item,
                        amount: item.name === worstState ? item.amount + 1 : item.amount
                    }));
                });

                // set the states which are currently being filtered for to active
                let overallStateFilter = filterValues.find(item => item.id === 'lastOverallState');
                if (overallStateFilter) {
                    const statesToActivate = new Set(overallStateFilter.value);
                    newStateData = newStateData.map(item => ({
                        ...item,
                        active: statesToActivate.has(item.name)
                    }));
                }

                // Add "All" field in first position
                newStateData.unshift({ 
                    name: showOnlyHidden ? this._t('COMMON.HIDDEN') : this._t('COMMON.ALL'), 
                    amount: stationLocations.data.stationLocations.length, 
                    active: !overallStateFilter || !overallStateFilter.value || overallStateFilter.value.length == 0, 
                    onClick: () => this.setOverallStateFilter(null)
                })

                // Add "Hidden" field in last position, only if hidden stations are available
                // and user is not currently on the view for hidden stations
                if (hiddenStationLocations.length > 0 || showOnlyHidden) {
                    newStateData.push({ 
                        name: 'Hidden', 
                        amount: hiddenStationLocations.length, 
                        active: showOnlyHidden, 
                        type: 'hidden', 
                        onClick: () => this.toggleVisibility() 
                    });
                }

                return newStateData
            })
        );
    };

    private _t(path: string, interpolateParams?: Object) {
        return this._translate.instant(path, interpolateParams)
    }

    public setOverallStateFilter(state: lastOverallState | null): void {
        let filterAttr: string = 'lastOverallState';

        if (state == null) {
            // if 'all' was clicked - remove overall state filter
            this._filtersRepo.deleteFilters(filterAttr);

        } else if (this._filtersRepo.hasFilter(filterAttr)) {
            // overall state filter already exists, use empty array incase the filter is added but no value is set yet
            const currentValues = this._filtersRepo.getFilterValue(filterAttr)?.value ?? [];

            if (currentValues.includes(state)) {
                // clicked state is active
                let newValues = currentValues.filter((value: any) => value !== state);
                // remove filter if clicked state is the only active one
                if (newValues.length == 0) this._filtersRepo.deleteFilters(filterAttr);
                // update filter if there is at least one other active state
                else this._filtersRepo.updateFilterValue(filterAttr, newValues);
            } else {
                const newValues = [...currentValues, state];
                if (newValues.length === 5) {
                    // remove filter if all options are selected
                    this._filtersRepo.deleteFilters(filterAttr)
                } else {
                    this._filtersRepo.updateFilterValue(filterAttr, newValues);
                }
            }

        } else {
            // overall state filter was not yet selected - add filter with clicked value
            this._filtersRepo.addFilter(filterAttr, [state])
        }
    }

    public toggleVisibility() {
        this._repo.toggleShowOnlyHidden();
    }

    public getWorstState(states: string[]): string {
        let stateOrder: { [state: string]: number } = { 'No Data': 0, 'Ok': 1, 'To Be Monitored': 2, 'Potential Failure': 3, 'Failure': 4 };
        return states.length ? states.reduce((a, b) => stateOrder[a] > stateOrder[b] ? a : b) : 'No Data';
    }
}
