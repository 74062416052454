import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { differenceInCalendarDays, eachDayOfInterval, eachHourOfInterval, eachMonthOfInterval, isWithinInterval, subDays, subHours, subMonths } from 'date-fns';
import { DateRange } from 'src/app/core/plots/plot.models';

export type DateRangePreset = {
    label: string
    interval: number
    range: 'h' | 'd' | 'M'
}

@Component({
    selector: 'app-date-range',
    template: `
        <div 
            class="date-range-wrapper flex-row align-items-center justify-content-center"
            [class.vertical]="format == 'vertical'"
            [class.evc-style]="style == 'evc'"
            [class.small]="size == 'small'"
            [class.show-time]="showTime"
            [class.has-reset]="showReset"
        >
            <ng-template #icon>
                <span class="material-icon icon">date_range</span>
            </ng-template>
            <nz-range-picker
                class="flex-shrink-0 {{ size }} {{ type }}"
                [nzShowTime]="showTime ? { nzFormat: 'HH:mm' } : null"
                [nzFormat]="showTime ? 'dd.MM.yyyy HH:mm' : 'dd.MM.yyyy'"
                [nzSize]="size"
                [nzDisabledDate]="disabledDates"
                [nzSuffixIcon]="style == 'evc' ? icon : 'calendar'"
                [nzAllowClear]="style == 'default'"
                [(ngModel)]="dateArray"
                (ngModelChange)="onChange($event)"
                (nzOnCalendarChange)="onChange($event)"
                (nzOnOk)="onOk()"
            ></nz-range-picker>
            <div 
                class="inputs-wrapper d-flex flex-row align-items-center justify-content-center flex-shrink-0"
                [class.small]="size == 'small'"
            >
                @if (presetDateRanges.length > 0) {
                    <div 
                        class="daterange-preselects"
                        [class.grid-layout]="format == 'vertical'"
                    >
                        <button
                            type="button"
                            *ngFor="let preset of presetDateRanges"
                            (click)="applyDateRangePreset(preset)"
                            [class.active]="inputIsActive(preset)"
                        >
                            {{ preset.label }}
                        </button>
                    </div>
                }
                @if (showReset) {
                    <button 
                        title="reset to default"
                        class="reset-date-range"
                        [tooltip]="'COMMON.RESET_TO_DEFAULT' | translate"
                        size="small"
                        type="button"
                        (click)="resetToDefault()"
                    >
                        <div class="reset-img"></div>
                    </button>
                }
            </div>
        </div>
    `,
    styleUrls: ['./date-range.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DateRangeComponent implements OnInit {
    // enables time inputs
    @Input() showTime: boolean = true;
    // enables reset functionality
    @Input() showReset: boolean = true;
    // whether until date will be disabled or not
    @Input() type: 'range' | 'date' = 'range';
    // format in which the inputs should be stacked
    @Input() format: 'horizontal' | 'vertical' = 'horizontal';
    // input size
    @Input() size: 'small' | 'default' | 'large' = 'default';
    // change input styling to comply with different designs...
    @Input() style: 'default' | 'evc' = 'default';
    // preset dateRange
    @Input() set dateRange(range: DateRange | undefined) {
        if (!range || !range.from || !range.until) {
            this.dateArray = this._getDefaultRange();
        } else {
            this.dateArray = [range.from, range.until]
        }
    };
    @Input() minDate: Date | undefined;
    @Input() maxDate: Date | undefined;
    // set of selectable buttons, interval in ms
    public presetDateRanges: DateRangePreset[] = [
        {
            label: '3h',
            interval: 3,
            range: 'h'
        },
        {
            label: '24h',
            interval: 24,
            range: 'h'
        },
        {
            label: '7d',
            interval: 7,
            range: 'd'
        },
        {
            label: '30d',
            interval: 30,
            range: 'd'
        }
    ];
    // only overrides default value if array is passed, will keep default if nulled
    @Input() set dateRangePreset(value: DateRangePreset[] | null) {
        if (value === null) return
        this.presetDateRanges = value
    }
    // date to set when reset button clicked
    public resetDates: Date[] | null = null;
    @Input() set resetDateRange(range: DateRange | null) {
        if (!range || !range.from || !range.until) return;
        this.resetDates = [range.from, range.until];
    }

    @Output() dateRangeChange = new EventEmitter<DateRange>();

    // range formatted as array [from, until]
    dateArray: Date[] = [subDays(new Date(), 14), new Date()];
    // last dateRange inputted by user before selecting preset
    private _lastCustomDateRange: Date[] = [];
    // last value to compare changes against
    private _lastDateRange: Date[] = [];

    constructor() {
        setTimeout(() => {
            // if type 'date', inputs unselectable by tab navigation
            if (this.type == 'date') {
                const elements = document.querySelectorAll('.ant-picker-input input');
                elements.forEach((element: any) => {
                  element.setAttribute('tabindex', '-1')
                });
            }
        }, 100)
    }

    // returns true to disable date
    public disabledDates = (current: Date): boolean => {
        if (!this.minDate && !this.maxDate) return false
        if (this.minDate && !this.maxDate) {
            return differenceInCalendarDays(current, this.minDate) < 1;
        }
        if (!this.minDate && this.maxDate) {
            return differenceInCalendarDays(current, this.maxDate) > 0
        }
        
        return !isWithinInterval(current, {
            start: subDays(this.minDate!, 1),
            end: this.maxDate!
        })
    }

    ngOnInit() {
        const clone = structuredClone(this.dateArray);
        // save initial dates as first "manual" dateRange
        this._lastCustomDateRange = clone
        this._lastDateRange = clone
    }

    onChange(result: (Date | null)[] | Date | null): void {
        if (result instanceof Array) {
            // range select will emit empty array when removing selection
            if (result.length === 0 || result[0] === null || result[1] === null) {
                this.resetToDefault()
                return;
            }
            // func called with uncomplete interval, return until data is complete
            if (result.length === 1) return

            // only emit if new range is selected
            if (this._lastDateRange && this._lastDateRange.length === 2 &&
                result[0].getTime() === this._lastDateRange[0].getTime() &&
                result[1].getTime() === this._lastDateRange[1].getTime()
            ) {
                return;
            }

            this._lastDateRange = result as Date[]
            this.dateRangeChange.emit({
                from: result[0],
                until: result[1]
            })
        } 
    }

    onOk(): void {
        if (this.type == 'date') {
            const elements = document.querySelectorAll('.ant-picker-input input');
            elements.forEach((element: any) => element.blur());
        }
    }

    // sets selection to dedicated reset range if available, else to min/max or last 14d - now
    resetToDefault() {
        this._updateDateRange(this.resetDates ? this.resetDates : this._getDefaultRange())
        this._lastCustomDateRange = structuredClone(this.dateArray)
    }

    applyDateRangePreset(preset: DateRangePreset) {
        const isActive = this.inputIsActive(preset)
        if (isActive) {
            // reset to last manual date
            this._updateDateRange(structuredClone(this._lastCustomDateRange))
            return;
        }

        const activeRangePreset = this.presetDateRanges.find((x) => this.inputIsActive(x));
        if (!activeRangePreset) {
            // no preset range active, meaning last input was manual
            this._lastCustomDateRange = structuredClone(this.dateArray)
        }

        const dateUntil = new Date();
        let dateFrom;
        // calculating new from date with date-fns
        // https://date-fns.org/
        switch (preset.range) {
            case 'd':
                dateFrom = subDays(dateUntil, preset.interval)
                break;
            case 'h':
                dateFrom = subHours(dateUntil, preset.interval)
                break;
            case 'M':
                dateFrom = subMonths(dateUntil, preset.interval)
                break;
        }
        
        this._updateDateRange([dateFrom, dateUntil])
    }

    inputIsActive(preset: DateRangePreset): boolean {
        let currentInterval;
        const interval = {
            start: this.dateArray[0],
            end: this.dateArray[1]
        }
        switch (preset.range) {
            case 'h':
                currentInterval = eachHourOfInterval(interval).length - 1
                break;
            case 'd':
                currentInterval = eachDayOfInterval(interval).length - 1
                break;
            case 'M':
                currentInterval = eachMonthOfInterval(interval).length - 1
                break;
        }

        return currentInterval === preset.interval
    }

    private _getDefaultRange(): Date[] {
        if (this.minDate && this.maxDate) {
            return [this.minDate, this.maxDate]
        }
        return [subDays(new Date(), 14), new Date()];
    }

    private _updateDateRange(range: Date[]) {
        this.dateArray = range;
        this.dateRangeChange.emit({
            from: range[0],
            until: range[1]
        })
    }
}
