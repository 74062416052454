/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';


export interface GetUsersByUuid$Params {

/**
 * Unique identifiers of the Users
 */
  uuid: Array<string>;
}

export function getUsersByUuid(http: HttpClient, rootUrl: string, params: GetUsersByUuid$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<{

/**
 * Unique identification of user
 */
'uuid'?: string;

/**
 * First name of user
 */
'firstName'?: string;

/**
 * Last name of user
 */
'lastName'?: string;
}>>> {
  const rb = new RequestBuilder(rootUrl, getUsersByUuid.PATH, 'get');
  if (params) {
    rb.query('uuid', params.uuid, {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<Array<{
      
      /**
       * Unique identification of user
       */
      'uuid'?: string;
      
      /**
       * First name of user
       */
      'firstName'?: string;
      
      /**
       * Last name of user
       */
      'lastName'?: string;
      }>>;
    })
  );
}

getUsersByUuid.PATH = '/users/';
