import { EnumeratedState } from "./state-helper.service";

export class Sort {
    private sortOrder = 1;
    private collator = new Intl.Collator(undefined, {
        numeric: true,
        sensitivity: 'base'
    })
    private enumeratedStates: EnumeratedState[] = ['No Data', 'Ok', 'To Be Monitored', 'Potential Failure', 'Failure'];

    constructor() { }

    private sortData(a: any, b: any) {
        if (a < b) {
            return -1 * this.sortOrder
        } else if (a > b) {
            return 1 * this.sortOrder
        } else {
            return 0 * this.sortOrder
        }
    }

    private sortArray(array1: any[], array2: any[]) {
        let lengthDiff = array1.length - array2.length;
        // firstly compare length of lists
        if (lengthDiff !== 0) {
          return lengthDiff;
        } else {
          // if the lists have the same length, compare based on content
          for (let i = 0; i < array1.length; i++) {
            let contentDiff = this.collator.compare(array1[i], array2[i]);
            if (contentDiff !== 0) return contentDiff;
          }
          // lists are equal in both length and content
          return 0;
        }
    }

    private sortEnum(a: EnumeratedState, b: EnumeratedState) {
        const aIndex = this.enumeratedStates.indexOf(a);
        const bIndex = this.enumeratedStates.indexOf(b);

        return aIndex > bIndex ? 1 : aIndex < bIndex ? - 1 : 0;
    }

    private sortChildren(array: Array<any>, key: any, nestedKey: string, sortBy: 'highest' | 'lowest' | 'firstAvailable') {
        if (!sortBy) return;
        let nestedItems: Array<any> = array[key];
        switch (sortBy) {
            case 'highest':
                let highestValues: Array<number> = nestedItems.map(item => {
                    return item[nestedKey]
                })
                let highestValue = Math.max(...highestValues)
                // return index of highest value in array
                return highestValues.indexOf(highestValue)
            case 'lowest':
                let lowestValues: Array<number> = nestedItems.map(item => {
                    return item[nestedKey] as number
                })
                let lowestValue = Math.min(...lowestValues)
                // return index of lowest value in array
                return lowestValues.indexOf(lowestValue)
            default:
                // return index of first available value in array
                for (let i = 0; i < nestedItems.length; i++) {
                    if (array[key][i][nestedKey]) {
                        return i;
                    }
                }
                return 0;
        }
    }

    public startSort(property: string, order: string, type = '', nestedKey: string, sortChildrenBy: 'highest' | 'lowest' | 'firstAvailable') {
        if (order === 'desc') {
            this.sortOrder = -1;
            // invert children sort order for desc/asc
            if (sortChildrenBy && sortChildrenBy == 'highest') {
                sortChildrenBy = 'lowest'
            } else if (sortChildrenBy && sortChildrenBy == 'lowest') {
                sortChildrenBy = 'highest'
            }
        }
        return (a: any, b: any) => {
            if (nestedKey) {
                let nestedPosition = 0;
                if (sortChildrenBy) {
                    nestedPosition = this.sortChildren(a, nestedKey, property, sortChildrenBy)! | 0
                }
                switch (type) {
                    case 'date':
                        return this.sortData(new Date(a[nestedKey][nestedPosition][property]), new Date(a[nestedKey][nestedPosition][property]));
                    case 'array':
                        let array1 = a[nestedKey][nestedPosition][property];
                        let array2 = b[nestedKey][nestedPosition][property];
                        return this.sortArray(array1, array2) * this.sortOrder;
                    case 'enum':
                        const enumA = a[nestedKey][nestedPosition][property];
                        const enumB = b[nestedKey][nestedPosition][property];
                        return this.sortEnum(enumA, enumB) * this.sortOrder;
                    default:
                        return this.collator.compare(a[nestedKey][nestedPosition][property], b[nestedKey][nestedPosition][property]) * this.sortOrder;
                }
            } else {
                switch (type) {
                    case 'date':
                        return this.sortData(new Date(a[property]), new Date(b[property]));
                    case 'array':
                        let array1 = a[property];
                        let array2 = b[property];
                        return this.sortArray(array1, array2) * this.sortOrder;
                    case 'enum':
                        const enumA = a[property];
                        const enumB = b[property];
                        return this.sortEnum(enumA, enumB) * this.sortOrder;
                    default:
                        return this.collator.compare(a[property], b[property]) * this.sortOrder;
                }
            }
        }
    }
}