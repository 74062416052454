import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { PermissionsService } from '../app-services/permissions.service';
import { Paths, RolePermissions } from 'src/assets/files/roles_access/roles-permissions.types';

export const userRoutingGuard: CanActivateFn = (
    route: ActivatedRouteSnapshot, 
    state: RouterStateSnapshot
): Promise<boolean | UrlTree> => {
    const router = inject(Router);
    const permService = inject(PermissionsService);
    let stateObj = state.url.split('?')[0].split('/').splice(1);

    const routeMap: Record<string, (Record<string, Paths<RolePermissions>> | Paths<RolePermissions>)> = {
        'overview': 'routes.dashboard',
        'details': {
            '_base': 'routes.stationDetails',
            'timeline': 'routes.stationDetails.timeline',
            'health-index': 'routes.stationDetails.healthIndex',
            'errors': 'routes.stationDetails.errors',
            'sessions': 'routes.stationDetails.sessions',
            'restarts': 'routes.stationDetails.restarts',
            'connection': 'routes.stationDetails.connection',
            'configuration': 'routes.stationDetails.configuration',
            'tickets': 'routes.stationDetails.tickets'
        },
        'map': 'routes.operationMap',
        'kpi': 'routes.kpiDashboard',
        'filtersets': 'routes.filterSets',
        'notifications': 'routes.notifications',
        'admin': {
            '_base': 'routes.admin',
            'manage-users': 'routes.admin.manageUsers',
            'manage-tenants': 'routes.admin.manageTenants'
        },
        'insights': {
            '_base': 'routes.insights',
            'error-insights': 'routes.insights.errorInsights',
            'lifecycle-analysis': 'routes.insights.lifecycleAnalysis',
            'station-ranking': 'routes.insights.stationRanking'
        }
    }

    // recursive function to get the permission for a given route
    function getPermissionForRoute(routeParts: string[], currentMap: any = routeMap): Paths<RolePermissions> | undefined {
        if (routeParts.length === 0) {
            return typeof currentMap === 'string' ? currentMap : currentMap['_base'];
        }
    
        const [currentPart, ...remainingParts] = routeParts;
    
        if (currentMap[currentPart]) {
            return getPermissionForRoute(remainingParts, currentMap[currentPart]);
        } else {
            return undefined;
        }
    }

    // return promise to make sure permissions are loaded before checking access rights
    return new Promise((resolve) => {
        permService.awaitPermissions().then((permissions) => {
            // special case for station details, where the url would be /details/<stationId>/view
            if (stateObj[0] == 'details') {
                stateObj.splice(1, 1)
            }

            const requestedPermission = getPermissionForRoute(stateObj);
            
            // if user has proper permissions - forward to selected page - otherwise redirect to 404
            resolve(requestedPermission && permService.hasPermission(requestedPermission) ? true : router.createUrlTree(['404']));
        })
    })
};
