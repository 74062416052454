import { Injectable } from "@angular/core";
import { createStore } from "@ngneat/elf";
import { addEntities, entitiesPropsFactory, getEntity, hasEntity, selectEntity, updateEntities, withEntities } from "@ngneat/elf-entities";
import { persistState, sessionStorageStrategy } from "@ngneat/elf-persist-state";
import { Observable, map } from "rxjs";

type ColumWidths = Record<number, number | null>;

const { tableEntitiesRef, withTableEntities } = entitiesPropsFactory('table')

const tableStatesStore = createStore(
    {name: 'tableStates'},
    withTableEntities<{
        id: string,
        columnWidths: ColumWidths
    }, 'id'>({idKey: 'id'})
);

persistState(tableStatesStore, {
    key: 'tablesStore',
    storage: sessionStorageStrategy
})

@Injectable({ providedIn: 'root' })
export class tablesRepository {
    
    public updateColumnWidths(id: string, columnWidths: ColumWidths) {
        const hasTable = tableStatesStore.query(hasEntity(id, {ref: tableEntitiesRef}));

        if (hasTable) {
            tableStatesStore.update(
                updateEntities(id, {columnWidths}, {ref: tableEntitiesRef})
            )
        } else {
            tableStatesStore.update(
                addEntities({id, columnWidths}, {ref: tableEntitiesRef})
            )
        }
    }

    public updateSingleWidth(id: string, columnWidth: Record<number, number | null>) {
        const stored = tableStatesStore.query(getEntity(id, {ref: tableEntitiesRef}));
        const current = Object.assign({}, stored?.columnWidths, columnWidth as ColumWidths);
        this.updateColumnWidths(id, current)
    }

    public getTableWidthUpdates(id: string): Observable<ColumWidths> {
        return tableStatesStore.pipe(
            selectEntity(id, {ref: tableEntitiesRef}),
            map((res) => res?.columnWidths ?? {})
        )
    }
}