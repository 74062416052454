/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { DataBaseService } from '../data-base-service';
import { DataApiConfiguration } from '../data-api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { ApiResponse } from '../models/api-response';
import { changeVisibilityOfChargingStations } from '../fn/overview/change-visibility-of-charging-stations';
import { ChangeVisibilityOfChargingStations$Params } from '../fn/overview/change-visibility-of-charging-stations';
import { ChargedAmount } from '../models/charged-amount';
import { ChargersInFailure } from '../models/chargers-in-failure';
import { ChargingStation } from '../models/charging-station';
import { deleteRestarts } from '../fn/restarts/delete-restarts';
import { DeleteRestarts$Params } from '../fn/restarts/delete-restarts';
import { DeleteRestartsResponse } from '../models/delete-restarts-response';
import { deleteSchedules } from '../fn/restarts/delete-schedules';
import { DeleteSchedules$Params } from '../fn/restarts/delete-schedules';
import { DeleteSchedulesResponse } from '../models/delete-schedules-response';
import { EnergyMeterValue } from '../models/energy-meter-value';
import { getChargingStations } from '../fn/overview/get-charging-stations';
import { GetChargingStations$Params } from '../fn/overview/get-charging-stations';
import { getChartChargedAmount } from '../fn/overview/get-chart-charged-amount';
import { GetChartChargedAmount$Params } from '../fn/overview/get-chart-charged-amount';
import { getChartChargersInFailure } from '../fn/overview/get-chart-chargers-in-failure';
import { GetChartChargersInFailure$Params } from '../fn/overview/get-chart-chargers-in-failure';
import { getLocationsOfChargingStations } from '../fn/overview/get-locations-of-charging-stations';
import { GetLocationsOfChargingStations$Params } from '../fn/overview/get-locations-of-charging-stations';
import { getMeterValue } from '../fn/overview/get-meter-value';
import { GetMeterValue$Params } from '../fn/overview/get-meter-value';
import { getNumSessions } from '../fn/overview/get-num-sessions';
import { GetNumSessions$Params } from '../fn/overview/get-num-sessions';
import { getOnIdleRestarts } from '../fn/restarts/get-on-idle-restarts';
import { GetOnIdleRestarts$Params } from '../fn/restarts/get-on-idle-restarts';
import { getSchedules } from '../fn/restarts/get-schedules';
import { GetSchedules$Params } from '../fn/restarts/get-schedules';
import { NumSessions } from '../models/num-sessions';
import { OverviewMetaInfo } from '../models/overview-meta-info';
import { ResetResponse } from '../models/reset-response';
import { Restart } from '../models/restart';
import { restartChargingStationsBySchedule } from '../fn/restarts/restart-charging-stations-by-schedule';
import { RestartChargingStationsBySchedule$Params } from '../fn/restarts/restart-charging-stations-by-schedule';
import { restartChargingStationsManually } from '../fn/restarts/restart-charging-stations-manually';
import { RestartChargingStationsManually$Params } from '../fn/restarts/restart-charging-stations-manually';
import { RestartSchedule } from '../models/restart-schedule';
import { StationLocations } from '../models/station-locations';


/**
 * Charging Stations Overview Table
 */
@Injectable({ providedIn: 'root' })
export class OverviewService extends DataBaseService {
  constructor(config: DataApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getChargingStations()` */
  static readonly GetChargingStationsPath = '/chargingStations/';

  /**
   * Gets list of charging stations.
   *
   * Fetch a paginated list of charging stations, optionally for a specific date
   * The returned list of charging stations is basically paginated. Only if latMin, latMax, lonMin and lonMax are set, the complete result list is returned. In that case the pagination and sorting parameters are ignored. In addition, the charging stations contain only the attributes needed for the display in the map.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getChargingStations()` instead.
   *
   * This method doesn't expect any request body.
   */
  getChargingStations$Response(params?: GetChargingStations$Params, context?: HttpContext): Observable<StrictHttpResponse<{
'stations'?: Array<ChargingStation>;
'meta'?: OverviewMetaInfo;
}>> {
    return getChargingStations(this.http, this.rootUrl, params, context);
  }

  /**
   * Gets list of charging stations.
   *
   * Fetch a paginated list of charging stations, optionally for a specific date
   * The returned list of charging stations is basically paginated. Only if latMin, latMax, lonMin and lonMax are set, the complete result list is returned. In that case the pagination and sorting parameters are ignored. In addition, the charging stations contain only the attributes needed for the display in the map.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getChargingStations$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getChargingStations(params?: GetChargingStations$Params, context?: HttpContext): Observable<{
'stations'?: Array<ChargingStation>;
'meta'?: OverviewMetaInfo;
}> {
    return this.getChargingStations$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
'stations'?: Array<ChargingStation>;
'meta'?: OverviewMetaInfo;
}>): {
'stations'?: Array<ChargingStation>;
'meta'?: OverviewMetaInfo;
} => r.body)
    );
  }

  /** Path part for operation `changeVisibilityOfChargingStations()` */
  static readonly ChangeVisibilityOfChargingStationsPath = '/chargingStations/changeVisibility/';

  /**
   * Changes the visibility of a list of charging stations.
   *
   * The list of charging stations will be shown or hidden for the current user
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `changeVisibilityOfChargingStations()` instead.
   *
   * This method doesn't expect any request body.
   */
  changeVisibilityOfChargingStations$Response(params: ChangeVisibilityOfChargingStations$Params, context?: HttpContext): Observable<StrictHttpResponse<ApiResponse>> {
    return changeVisibilityOfChargingStations(this.http, this.rootUrl, params, context);
  }

  /**
   * Changes the visibility of a list of charging stations.
   *
   * The list of charging stations will be shown or hidden for the current user
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `changeVisibilityOfChargingStations$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  changeVisibilityOfChargingStations(params: ChangeVisibilityOfChargingStations$Params, context?: HttpContext): Observable<ApiResponse> {
    return this.changeVisibilityOfChargingStations$Response(params, context).pipe(
      map((r: StrictHttpResponse<ApiResponse>): ApiResponse => r.body)
    );
  }

  /** Path part for operation `getChartChargedAmount()` */
  static readonly GetChartChargedAmountPath = '/chargedAmount/';

  /**
   * Gets data for chart 'Charged Amount'.
   *
   * Fetch all necessary data for a chart showing the summed charge amount of all displayed chargers for a given time interval divided into segments of the same length.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getChartChargedAmount()` instead.
   *
   * This method doesn't expect any request body.
   */
  getChartChargedAmount$Response(params?: GetChartChargedAmount$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ChargedAmount>>> {
    return getChartChargedAmount(this.http, this.rootUrl, params, context);
  }

  /**
   * Gets data for chart 'Charged Amount'.
   *
   * Fetch all necessary data for a chart showing the summed charge amount of all displayed chargers for a given time interval divided into segments of the same length.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getChartChargedAmount$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getChartChargedAmount(params?: GetChartChargedAmount$Params, context?: HttpContext): Observable<Array<ChargedAmount>> {
    return this.getChartChargedAmount$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ChargedAmount>>): Array<ChargedAmount> => r.body)
    );
  }

  /** Path part for operation `getChartChargersInFailure()` */
  static readonly GetChartChargersInFailurePath = '/chargersInFailure/';

  /**
   * Gets data for chart 'Chargers in Failure'.
   *
   * Fetch all necessary data for a chart showing the amount of chargers with a status "Failure" for a given time interval divided into days.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getChartChargersInFailure()` instead.
   *
   * This method doesn't expect any request body.
   */
  getChartChargersInFailure$Response(params?: GetChartChargersInFailure$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ChargersInFailure>>> {
    return getChartChargersInFailure(this.http, this.rootUrl, params, context);
  }

  /**
   * Gets data for chart 'Chargers in Failure'.
   *
   * Fetch all necessary data for a chart showing the amount of chargers with a status "Failure" for a given time interval divided into days.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getChartChargersInFailure$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getChartChargersInFailure(params?: GetChartChargersInFailure$Params, context?: HttpContext): Observable<Array<ChargersInFailure>> {
    return this.getChartChargersInFailure$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ChargersInFailure>>): Array<ChargersInFailure> => r.body)
    );
  }

  /** Path part for operation `getOnIdleRestarts()` */
  static readonly GetOnIdleRestartsPath = '/restarts/getOnIdleRestarts/';

  /**
   * Retrieves pending onIdle restarts in the restarts index for a list of charging stations.
   *
   * Retrieves Restarts of RestartStatus "pending" of mode manual and onIdle in the restarts index for a list of charging stations.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getOnIdleRestarts()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOnIdleRestarts$Response(params?: GetOnIdleRestarts$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<Restart>>> {
    return getOnIdleRestarts(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves pending onIdle restarts in the restarts index for a list of charging stations.
   *
   * Retrieves Restarts of RestartStatus "pending" of mode manual and onIdle in the restarts index for a list of charging stations.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getOnIdleRestarts$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOnIdleRestarts(params?: GetOnIdleRestarts$Params, context?: HttpContext): Observable<Array<Restart>> {
    return this.getOnIdleRestarts$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<Restart>>): Array<Restart> => r.body)
    );
  }

  /** Path part for operation `getSchedules()` */
  static readonly GetSchedulesPath = '/restarts/getSchedules/';

  /**
   * Retrieves restart schedules in the restart schedules index for a list of charging stations.
   *
   * Retrieves restart schedules in the restart schedules index for a list of charging stations. Each station can only have one schedule associated with it.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getSchedules()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSchedules$Response(params?: GetSchedules$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<RestartSchedule>>> {
    return getSchedules(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves restart schedules in the restart schedules index for a list of charging stations.
   *
   * Retrieves restart schedules in the restart schedules index for a list of charging stations. Each station can only have one schedule associated with it.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getSchedules$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSchedules(params?: GetSchedules$Params, context?: HttpContext): Observable<Array<RestartSchedule>> {
    return this.getSchedules$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<RestartSchedule>>): Array<RestartSchedule> => r.body)
    );
  }

  /** Path part for operation `restartChargingStationsManually()` */
  static readonly RestartChargingStationsManuallyPath = '/restarts/manualRestart/';

  /**
   * Creates entries in the restarts index for a list of charging stations to be restarted.
   *
   * Creates entries of RestartStatus "pending" of mode manual and onIdle restart in the restarts index according to the type of restart selected for a list of charging stations. The restarts worker lambda later processes and triggers the respective restart either immediately or when the station is not charging (onIdle).
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `restartChargingStationsManually()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  restartChargingStationsManually$Response(params: RestartChargingStationsManually$Params, context?: HttpContext): Observable<StrictHttpResponse<ResetResponse>> {
    return restartChargingStationsManually(this.http, this.rootUrl, params, context);
  }

  /**
   * Creates entries in the restarts index for a list of charging stations to be restarted.
   *
   * Creates entries of RestartStatus "pending" of mode manual and onIdle restart in the restarts index according to the type of restart selected for a list of charging stations. The restarts worker lambda later processes and triggers the respective restart either immediately or when the station is not charging (onIdle).
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `restartChargingStationsManually$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  restartChargingStationsManually(params: RestartChargingStationsManually$Params, context?: HttpContext): Observable<ResetResponse> {
    return this.restartChargingStationsManually$Response(params, context).pipe(
      map((r: StrictHttpResponse<ResetResponse>): ResetResponse => r.body)
    );
  }

  /** Path part for operation `restartChargingStationsBySchedule()` */
  static readonly RestartChargingStationsBySchedulePath = '/restarts/scheduledRestart/';

  /**
   * Creates entries in the restarts schedule index for a list of charging stations to be restarted.
   *
   * Creates entries for restart schedules (weekly or daily) according to the weekdays selected for a list of charging stations. The restarts worker lambda later processes and triggers the respective restart according to schedule.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `restartChargingStationsBySchedule()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  restartChargingStationsBySchedule$Response(params: RestartChargingStationsBySchedule$Params, context?: HttpContext): Observable<StrictHttpResponse<ResetResponse>> {
    return restartChargingStationsBySchedule(this.http, this.rootUrl, params, context);
  }

  /**
   * Creates entries in the restarts schedule index for a list of charging stations to be restarted.
   *
   * Creates entries for restart schedules (weekly or daily) according to the weekdays selected for a list of charging stations. The restarts worker lambda later processes and triggers the respective restart according to schedule.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `restartChargingStationsBySchedule$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  restartChargingStationsBySchedule(params: RestartChargingStationsBySchedule$Params, context?: HttpContext): Observable<ResetResponse> {
    return this.restartChargingStationsBySchedule$Response(params, context).pipe(
      map((r: StrictHttpResponse<ResetResponse>): ResetResponse => r.body)
    );
  }

  /** Path part for operation `deleteSchedules()` */
  static readonly DeleteSchedulesPath = '/restarts/deleteSchedules/';

  /**
   * Deletes entries in the restart schedules index for a list of charging stations.
   *
   * Deletes entries for restart schedules (weekly or daily) for a list of charging stations. The restarts worker lambda will not process any schedule for these stations anymore.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteSchedules()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteSchedules$Response(params?: DeleteSchedules$Params, context?: HttpContext): Observable<StrictHttpResponse<DeleteSchedulesResponse>> {
    return deleteSchedules(this.http, this.rootUrl, params, context);
  }

  /**
   * Deletes entries in the restart schedules index for a list of charging stations.
   *
   * Deletes entries for restart schedules (weekly or daily) for a list of charging stations. The restarts worker lambda will not process any schedule for these stations anymore.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteSchedules$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteSchedules(params?: DeleteSchedules$Params, context?: HttpContext): Observable<DeleteSchedulesResponse> {
    return this.deleteSchedules$Response(params, context).pipe(
      map((r: StrictHttpResponse<DeleteSchedulesResponse>): DeleteSchedulesResponse => r.body)
    );
  }

  /** Path part for operation `deleteRestarts()` */
  static readonly DeleteRestartsPath = '/restarts/deleteRestarts/';

  /**
   * Deletes entries in the restarts index for a list of charging stations.
   *
   * Deletes entries for onIdle restarts for a list of charging stations. The restarts worker lambda will not process this onIdle restart anymore.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteRestarts()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteRestarts$Response(params?: DeleteRestarts$Params, context?: HttpContext): Observable<StrictHttpResponse<DeleteRestartsResponse>> {
    return deleteRestarts(this.http, this.rootUrl, params, context);
  }

  /**
   * Deletes entries in the restarts index for a list of charging stations.
   *
   * Deletes entries for onIdle restarts for a list of charging stations. The restarts worker lambda will not process this onIdle restart anymore.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteRestarts$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteRestarts(params?: DeleteRestarts$Params, context?: HttpContext): Observable<DeleteRestartsResponse> {
    return this.deleteRestarts$Response(params, context).pipe(
      map((r: StrictHttpResponse<DeleteRestartsResponse>): DeleteRestartsResponse => r.body)
    );
  }

  /** Path part for operation `getMeterValue()` */
  static readonly GetMeterValuePath = '/chargingStations/{stationId}/meterValuesEstimation/';

  /**
   * Gets energy meter values for a single charging station for the meter value estimator.
   *
   * Fetch the last energy meter values before the selected date for each connector of a single charging station. If no date is selected, it fetches the last value received. The values are used in the meter value estimator feature.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMeterValue()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMeterValue$Response(params: GetMeterValue$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<EnergyMeterValue>>> {
    return getMeterValue(this.http, this.rootUrl, params, context);
  }

  /**
   * Gets energy meter values for a single charging station for the meter value estimator.
   *
   * Fetch the last energy meter values before the selected date for each connector of a single charging station. If no date is selected, it fetches the last value received. The values are used in the meter value estimator feature.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getMeterValue$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMeterValue(params: GetMeterValue$Params, context?: HttpContext): Observable<Array<EnergyMeterValue>> {
    return this.getMeterValue$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<EnergyMeterValue>>): Array<EnergyMeterValue> => r.body)
    );
  }

  /** Path part for operation `getLocationsOfChargingStations()` */
  static readonly GetLocationsOfChargingStationsPath = '/stationLocations/';

  /**
   * Gets map information of all charging stations.
   *
   * Gets location and ID information from all filtered charging stations, optionally for a specific time interval
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getLocationsOfChargingStations()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLocationsOfChargingStations$Response(params?: GetLocationsOfChargingStations$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<StationLocations>>> {
    return getLocationsOfChargingStations(this.http, this.rootUrl, params, context);
  }

  /**
   * Gets map information of all charging stations.
   *
   * Gets location and ID information from all filtered charging stations, optionally for a specific time interval
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getLocationsOfChargingStations$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLocationsOfChargingStations(params?: GetLocationsOfChargingStations$Params, context?: HttpContext): Observable<Array<StationLocations>> {
    return this.getLocationsOfChargingStations$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<StationLocations>>): Array<StationLocations> => r.body)
    );
  }

  /** Path part for operation `getNumSessions()` */
  static readonly GetNumSessionsPath = '/numSessions/';

  /**
   * Gets number of charging sessions in timeframe aggregated in timesteps.
   *
   * Gets number of charging sessions for a selected timeframe, aggregated in timesteps and zero, short, regular charging sessions
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getNumSessions()` instead.
   *
   * This method doesn't expect any request body.
   */
  getNumSessions$Response(params?: GetNumSessions$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<NumSessions>>> {
    return getNumSessions(this.http, this.rootUrl, params, context);
  }

  /**
   * Gets number of charging sessions in timeframe aggregated in timesteps.
   *
   * Gets number of charging sessions for a selected timeframe, aggregated in timesteps and zero, short, regular charging sessions
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getNumSessions$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getNumSessions(params?: GetNumSessions$Params, context?: HttpContext): Observable<Array<NumSessions>> {
    return this.getNumSessions$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<NumSessions>>): Array<NumSessions> => r.body)
    );
  }

}
