/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { DataBaseService } from '../data-base-service';
import { DataApiConfiguration } from '../data-api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ChargersInFailure } from '../models/chargers-in-failure';
import { NumSessions } from '../models/num-sessions';

@Injectable({
  providedIn: 'root',
})
export class KpiService extends DataBaseService {
  constructor(
    config: DataApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getChartChargersInFailure
   */
  static readonly GetChartChargersInFailurePath = '/chargersInFailure/';

  /**
   * Gets data for chart 'Chargers in Failure'.
   *
   * Fetch all necessary data for a chart showing the amount of chargers with a status "Failure" for a given time interval divided into days.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getChartChargersInFailure()` instead.
   *
   * This method doesn't expect any request body.
   */
  getChartChargersInFailure$Response(params?: {

    /**
     * End of time interval
     */
    date?: string;

    /**
     * The period in days before &#x27;date&#x27;
     */
    interval?: number;

    /**
     * Unique identifier of the Charging Stations
     */
    stationIds?: Array<string>;

    /**
     * Term to search for in all fields of &quot;#/components/schemas/ChargingStation&quot;
     */
    search?: string;

    /**
     * String representation of a complex search expression in url-encoded format. The query is a list of key-value pairs, the key being a field of &quot;#/components/schemas/ChargingStation&quot;
     */
    searchFields?: string;

    /**
     * String representation of a complex filter expression
     */
    filters?: string;

    /**
     * Include only hidden stations
     */
    showOnlyHidden?: boolean;
  }): Observable<StrictHttpResponse<Array<ChargersInFailure>>> {

    const rb = new RequestBuilder(this.rootUrl, KpiService.GetChartChargersInFailurePath, 'get');
    if (params) {
      rb.query('date', params.date, {});
      rb.query('interval', params.interval, {});
      rb.query('stationIds', params.stationIds, {});
      rb.query('search', params.search, {});
      rb.query('searchFields', params.searchFields, {});
      rb.query('filters', params.filters, {});
      rb.query('showOnlyHidden', params.showOnlyHidden, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ChargersInFailure>>;
      })
    );
  }

  /**
   * Gets data for chart 'Chargers in Failure'.
   *
   * Fetch all necessary data for a chart showing the amount of chargers with a status "Failure" for a given time interval divided into days.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getChartChargersInFailure$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getChartChargersInFailure(params?: {

    /**
     * End of time interval
     */
    date?: string;

    /**
     * The period in days before &#x27;date&#x27;
     */
    interval?: number;

    /**
     * Unique identifier of the Charging Stations
     */
    stationIds?: Array<string>;

    /**
     * Term to search for in all fields of &quot;#/components/schemas/ChargingStation&quot;
     */
    search?: string;

    /**
     * String representation of a complex search expression in url-encoded format. The query is a list of key-value pairs, the key being a field of &quot;#/components/schemas/ChargingStation&quot;
     */
    searchFields?: string;

    /**
     * String representation of a complex filter expression
     */
    filters?: string;

    /**
     * Include only hidden stations
     */
    showOnlyHidden?: boolean;
  }): Observable<Array<ChargersInFailure>> {

    return this.getChartChargersInFailure$Response(params).pipe(
      map((r: StrictHttpResponse<Array<ChargersInFailure>>) => r.body as Array<ChargersInFailure>)
    );
  }

  /**
   * Path part for operation getNumSessions
   */
  static readonly GetNumSessionsPath = '/numSessions/';

  /**
   * Gets number of charging sessions in timeframe aggregated in timesteps.
   *
   * Gets number of charging sessions for a selected timeframe, aggregated in timesteps and zero, short, regular charging sessions
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getNumSessions()` instead.
   *
   * This method doesn't expect any request body.
   */
  getNumSessions$Response(params?: {

    /**
     * Date until which the sessions should be considered, else current date
     */
    date?: string;

    /**
     * The period in days before &#x27;date&#x27; from which all overall states should be returned
     */
    interval?: number;

    /**
     * Term to search for in all fields of &quot;#/components/schemas/ChargingStation&quot;
     */
    search?: string;

    /**
     * String representation of a complex search expression in url-encoded format. The query is a list of key-value pairs, the key being a field of &quot;#/components/schemas/ChargingStation&quot;
     */
    searchFields?: string;

    /**
     * String representation of a complex filter expression
     */
    filters?: string;

    /**
     * Include only hidden stations
     */
    showOnlyHidden?: boolean;
  }): Observable<StrictHttpResponse<Array<NumSessions>>> {

    const rb = new RequestBuilder(this.rootUrl, KpiService.GetNumSessionsPath, 'get');
    if (params) {
      rb.query('date', params.date, {});
      rb.query('interval', params.interval, {});
      rb.query('search', params.search, {});
      rb.query('searchFields', params.searchFields, {});
      rb.query('filters', params.filters, {});
      rb.query('showOnlyHidden', params.showOnlyHidden, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<NumSessions>>;
      })
    );
  }

  /**
   * Gets number of charging sessions in timeframe aggregated in timesteps.
   *
   * Gets number of charging sessions for a selected timeframe, aggregated in timesteps and zero, short, regular charging sessions
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getNumSessions$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getNumSessions(params?: {

    /**
     * Date until which the sessions should be considered, else current date
     */
    date?: string;

    /**
     * The period in days before &#x27;date&#x27; from which all overall states should be returned
     */
    interval?: number;

    /**
     * Term to search for in all fields of &quot;#/components/schemas/ChargingStation&quot;
     */
    search?: string;

    /**
     * String representation of a complex search expression in url-encoded format. The query is a list of key-value pairs, the key being a field of &quot;#/components/schemas/ChargingStation&quot;
     */
    searchFields?: string;

    /**
     * String representation of a complex filter expression
     */
    filters?: string;

    /**
     * Include only hidden stations
     */
    showOnlyHidden?: boolean;
  }): Observable<Array<NumSessions>> {

    return this.getNumSessions$Response(params).pipe(
      map((r: StrictHttpResponse<Array<NumSessions>>) => r.body as Array<NumSessions>)
    );
  }

}
