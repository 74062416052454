/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { DataBaseService } from '../data-base-service';
import { DataApiConfiguration } from '../data-api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { getCurrentUser } from '../fn/user/get-current-user';
import { GetCurrentUser$Params } from '../fn/user/get-current-user';
import { getUsersByUuid } from '../fn/user/get-users-by-uuid';
import { GetUsersByUuid$Params } from '../fn/user/get-users-by-uuid';
import { setCurrentUsersLanguage } from '../fn/user/set-current-users-language';
import { SetCurrentUsersLanguage$Params } from '../fn/user/set-current-users-language';
import { User } from '../models/user';

@Injectable({ providedIn: 'root' })
export class UserService extends DataBaseService {
  constructor(config: DataApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getUsersByUuid()` */
  static readonly GetUsersByUuidPath = '/users/';

  /**
   * Gets users for a list of uuids.
   *
   * Fetch a list of users for a given list of uuids.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getUsersByUuid()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUsersByUuid$Response(params: GetUsersByUuid$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<{

/**
 * Unique identification of user
 */
'uuid'?: string;

/**
 * First name of user
 */
'firstName'?: string;

/**
 * Last name of user
 */
'lastName'?: string;
}>>> {
    return getUsersByUuid(this.http, this.rootUrl, params, context);
  }

  /**
   * Gets users for a list of uuids.
   *
   * Fetch a list of users for a given list of uuids.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getUsersByUuid$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUsersByUuid(params: GetUsersByUuid$Params, context?: HttpContext): Observable<Array<{

/**
 * Unique identification of user
 */
'uuid'?: string;

/**
 * First name of user
 */
'firstName'?: string;

/**
 * Last name of user
 */
'lastName'?: string;
}>> {
    return this.getUsersByUuid$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<{

/**
 * Unique identification of user
 */
'uuid'?: string;

/**
 * First name of user
 */
'firstName'?: string;

/**
 * Last name of user
 */
'lastName'?: string;
}>>): Array<{

/**
 * Unique identification of user
 */
'uuid'?: string;

/**
 * First name of user
 */
'firstName'?: string;

/**
 * Last name of user
 */
'lastName'?: string;
}> => r.body)
    );
  }

  /** Path part for operation `getCurrentUser()` */
  static readonly GetCurrentUserPath = '/users/current/';

  /**
   * get the current user.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCurrentUser()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCurrentUser$Response(params?: GetCurrentUser$Params, context?: HttpContext): Observable<StrictHttpResponse<User>> {
    return getCurrentUser(this.http, this.rootUrl, params, context);
  }

  /**
   * get the current user.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCurrentUser$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCurrentUser(params?: GetCurrentUser$Params, context?: HttpContext): Observable<User> {
    return this.getCurrentUser$Response(params, context).pipe(
      map((r: StrictHttpResponse<User>): User => r.body)
    );
  }

  /** Path part for operation `setCurrentUsersLanguage()` */
  static readonly SetCurrentUsersLanguagePath = '/users/setLanguage/';

  /**
   * Set language for user.
   *
   * Sets the preferred language for the current user.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `setCurrentUsersLanguage()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  setCurrentUsersLanguage$Response(params: SetCurrentUsersLanguage$Params, context?: HttpContext): Observable<StrictHttpResponse<User>> {
    return setCurrentUsersLanguage(this.http, this.rootUrl, params, context);
  }

  /**
   * Set language for user.
   *
   * Sets the preferred language for the current user.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `setCurrentUsersLanguage$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  setCurrentUsersLanguage(params: SetCurrentUsersLanguage$Params, context?: HttpContext): Observable<User> {
    return this.setCurrentUsersLanguage$Response(params, context).pipe(
      map((r: StrictHttpResponse<User>): User => r.body)
    );
  }

}
