import { animation, style, animate, transition, trigger, state } from '@angular/animations';

export const inAnimation = animation([
    style({ opacity: 0, transform: 'translateY(10px)' }),
    animate('.275s ease-in-out', 
    style({ opacity: 1, transform: 'translateY(0px)' }))
]);

export const outAnimation = animation([
    style({ opacity: 1, transform: 'translateY(0px)' }),
    animate('.25s ease-out', 
    style({ opacity: 0, transform: 'translateY(10px)' }))
]);

export const fadeIn = animation([
    style({ opacity: 0 }),
    animate('.25s ease-out', 
    style({ opacity: 1 }))
]);

export const fadeOut = animation([
    style({ opacity: 1 }),
    animate('.25s ease-out', 
    style({ opacity: 0 }))
]);

export const smoothHeight = trigger('grow', [
    transition('void <=> *', []),
    transition('* <=> *', [
        style({ height: '{{startHeight}}px' }), animate('.35s ease')
    ], { params: { startHeight: 0 }})
]);

export const changeHeight = trigger('changeHeight', [
    state('void', style({
        height: '0'
    })),
    state('*', style({
        height: '*'
    })),
    transition('void <=> *', [
        animate('{{duration}} ease-in-out')
    ], { params: { duration: '300ms' } })
]);
