import { transition, trigger, useAnimation } from '@angular/animations';
import { ChangeDetectionStrategy, Component, Input, input } from '@angular/core';
import { fadeIn, fadeOut } from 'src/app/core/helpers/animations';

export type ErrorDisplay = {
    title: string,
    text: string,
    icon: string
}

@Component({
    selector: 'evc-error-overlay',
    template: `
    @if (errorDisplay(); as vm) {
        <div 
            class="error-overlay flex-column align-items-center justify-content-center"
            [@fadeInOutAnimation]
        >
            <div class="flex-row align-items-center justify-content-center">
                <span class="material-icon">{{ vm.icon }}</span>
                <div class="text-wrapper flex-column align-items-start">
                    <p class="headline">{{ vm.title }}</p>                                
                    <p>{{ vm.text }}</p>
                </div>
            </div>
        </div>
    }
   `,
    styleUrl: './error-overlay.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [
        trigger('fadeInOutAnimation', [
            transition(':enter', [
                useAnimation(fadeIn)
            ]),
            transition(':leave', [
                useAnimation(fadeOut)
            ])
        ])
    ]
})
export class ErrorOverlayComponent {
    public errorDisplay = input<ErrorDisplay | null>(null);
}
