import { computed, inject, Injectable, Signal } from '@angular/core';
import { EnumeratedState } from '../helpers/state-helper.service';
import { TranslateService } from '@ngx-translate/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LocalizedStateNameService {
    private readonly _translate = inject(TranslateService);
    private readonly _newLang = toSignal(this._translate.onLangChange);
    private readonly _enumStates = ['Ok', 'To Be Monitored', 'Potential Failure', 'Failure', 'No Data', 'Still Collecting Data'];

    constructor() { }
    
    
    public isEnumerated(value: EnumeratedState | string): value is EnumeratedState {
        return this._enumStates.includes(value);
    }

    // transforms enumerated state string to mapped string of localization
    private _transform(state: EnumeratedState | string): string {
        if (this.isEnumerated(state)) {
            return `COMMON.STATES.INTERPRETATION.${state.toUpperCase().replace(/ /g, '_')}`;
        } else {
            return state;
        }
    }

    // returns localized string of state
    public instant(state: EnumeratedState | string): string {
        const mappedState = this._transform(state);
        return this._translate.instant(mappedState);
    }

    // returns signal which emissions automatically update with new language
    public stateSignal(state: EnumeratedState | string): Signal<string> {
        return computed(() => {
            this._newLang();
            return this.instant(state);
        })
    }

    // returns observable which emits updated language
    public stateObservable(state: EnumeratedState | string): Observable<string> {
        const mappedState = this._transform(state);
        return this._translate.stream(mappedState)
    }
}
