import { Directive, ElementRef, EventEmitter, NgZone, Output } from '@angular/core';

@Directive({
    selector: '[resized]',
    standalone: false
})
export class ResizedDirective {
    private _elObserver: ResizeObserver;
    private _oldRect?: DOMRectReadOnly;

    @Output() public resized = new EventEmitter<ResizedEvent>();
  
    constructor(
        private _elRef: ElementRef,
        private _ngZone: NgZone
    ) {
        this._elObserver = new ResizeObserver(entries => this._ngZone.run(() => this._observeFn(entries)))
    }

    public ngOnInit(): void {
        this._elObserver.observe(this._elRef.nativeElement)
    }

    private _observeFn(entries: ResizeObserverEntry[]): void {
        const domSize = entries[0];
        const resizedEvent = new ResizedEvent(domSize.contentRect, this._oldRect);
        this._oldRect = domSize.contentRect;
        this.resized.emit(resizedEvent);
    }

    public ngOnDestroy(): void {
        this._elObserver.disconnect();
    }
}

export class ResizedEvent {
    public newRect: DOMRectReadOnly;
    public oldRect?: DOMRectReadOnly;
    public isFirst: boolean;

    public constructor(newRect: DOMRectReadOnly, oldRect: DOMRectReadOnly | undefined) {
        this.newRect = newRect;
        this.oldRect = oldRect;
        this.isFirst = oldRect == null;
    }
}
