import { animate, AUTO_STYLE, state, style, transition, trigger } from '@angular/animations';
import { ChangeDetectionStrategy, Component, computed, effect, ElementRef, input, model, ViewChild } from '@angular/core';

@Component({
    selector: 'evc-collapsible',
    template: `
    <div [class.container]="!fullWidth()">
        <div class="collapsible {{ parentClasses() }}" [attr.collapsed]="collapsed()">
            <div [class.full-width]="fullWidth()">
                <button 
                    class="collapsible-header" 
                    [attr.disabled]="active() ? null : 'disabled'"
                    [class.loading]="isLoading()"
                    (click)="toggleCollapsed()"
                    tabindex="1"
                    #buttonRef
                >
                    <ng-content select="[header]"></ng-content>
                    @if (showChevron()) {
                        <span class="chevron">
                            <span class="material-icon">
                                expand_more
                            </span>
                        </span>
                    }
                </button>
                <div 
                    class="collapsible-body"
                    [@collapse]="collapsed()"
                >
                    <div class="collapsible-body-wrapper {{ bodyClasses() }}">
                        <ng-content select="[body]"></ng-content>
                    </div>
                </div>
            </div>
        </div>
    </div>
  `,
    styleUrls: ['./collapsible.component.scss'],
    animations: [
        trigger('collapse', [
            state('false', style({ height: AUTO_STYLE, visibility: AUTO_STYLE })),
            state('true', style({ height: '0', visibility: 'hidden' })),
            transition('false => true', animate('250ms ease-in')),
            transition('true => false', animate('250ms ease-out'))
        ])
    ],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CollapsibleComponent {
    @ViewChild('buttonRef') buttonRef: ElementRef | undefined;
    public collapsed = model<boolean>(true);
    // takes array of css classes to be applied, will return string
    public bodyClasses = input(undefined, {
        transform: (value: string[]) => value.join(' ')
    });
    public parentClasses = input(undefined, {
        transform: (value: string[]) => value.join(' ')
    });
    public fullWidth = input<boolean>(false);
    // optionally set active to false to disable expanding the collapsible (e.g. content still polling)
    public active = input<boolean>(true);
    // optionally hide the chevron
    public showChevron = input<boolean>(true);
    // set loading to true for skeleton-like animation
    public loading = input<boolean>(false);
    public isLoading = computed(() => {
        return !this.active() && this.loading()
    })

    constructor() {
        // collapse component if active is being set to false
        effect(() => {
            const collapsed = this.collapsed();
            if (this.active() == false) {
                this.collapsed.set(true);
            }
        }, { allowSignalWrites: true })
    }

    toggleCollapsed() {
        this.collapsed.set(this.active() ? !this.collapsed() : true);
    }
}
