import { Injectable } from '@angular/core';
import { WindowReferenceService } from './window-reference.service';
import { ChargingStation, Connector } from '../data-backend/models';


@Injectable({
    providedIn: 'root'
})
export class DataLayerService {
    readonly ngAppStageName: string = process.env.NG_APP_STAGE_NAME
    private window;

    constructor(
        private _windowRef: WindowReferenceService
    ) {
        this.window = _windowRef.nativeWindow;
    }

    private _pushToDataLayer(obj: Object) {
        if (!this.window.dataLayer || this.ngAppStageName !== 'int') return;
        this.window.dataLayer.push(obj);
    }

    public logPageView(url: string) {
        const hit = {
            event: 'content-view',
            pageName: url
        };
        this._pushToDataLayer(hit);
    }

    public logEvent(event: string, category: string, action: string, label: string) {
        const hit = {
            event: event,
            category: category,
            action: action,
            label: label
        };
        this._pushToDataLayer(hit);
    }

    public logFilterSelection(action: 'filter-select' | 'filter-remove', fromView: 'dashboard' | 'map' | 'kpi' | 'error-insights', filterKey: keyof ChargingStation | keyof Connector | string) {
        const hit = {
            event: action,
            view: fromView,
            filterID: filterKey
        };
        this._pushToDataLayer(hit);
    }

    public logClick(label: string, action: string) {
        const hit = {
            event: 'click',
            action,
            label
        };
        this._pushToDataLayer(hit);
    }
}
