/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { StationLocations } from '../../models/station-locations';

export interface GetLocationsOfChargingStations$Params {

/**
 * Time at which the list of chargers should be displayed
 */
  date?: string;

/**
 * Term to search for in all fields of "#/components/schemas/ChargingStation"
 */
  search?: string;

/**
 * String representation of a complex search expression in url-encoded format. The query is a list of key-value pairs, the key being a field of "#/components/schemas/ChargingStation"
 */
  searchFields?: string;

/**
 * String representation of a complex filter expression
 */
  filters?: string;

/**
 * Include only hidden stations
 */
  showOnlyHidden?: boolean;
}

export function getLocationsOfChargingStations(http: HttpClient, rootUrl: string, params?: GetLocationsOfChargingStations$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<StationLocations>>> {
  const rb = new RequestBuilder(rootUrl, getLocationsOfChargingStations.PATH, 'get');
  if (params) {
    rb.query('date', params.date, {});
    rb.query('search', params.search, {});
    rb.query('searchFields', params.searchFields, {});
    rb.query('filters', params.filters, {});
    rb.query('showOnlyHidden', params.showOnlyHidden, {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<Array<StationLocations>>;
    })
  );
}

getLocationsOfChargingStations.PATH = '/stationLocations/';
