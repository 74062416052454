import { formatDate, formatNumber } from '@angular/common';
import { Injectable, inject } from '@angular/core';
import { Recommendation } from 'src/app/overview/overview.component';
import { RoleTypes } from '../data-backend/models';
import { appRepository } from '../stores/app.repository';
import { StateHelperService } from './state-helper.service';
import { formatSeconds } from './utils.helper';
import { EventIconsService } from './event-icons.service';
import { TranslateService } from '@ngx-translate/core';
import { LocalizedStateNameService } from '../app-services/localized-state-name.service';
import { StateClassPipe } from '../pipes';

@Injectable({
    providedIn: 'root'
})
export class ColumnService {

    constructor(
        private _stateClassPipe: StateClassPipe,
        private _appRepo: appRepository,
        private _stateHelper: StateHelperService,
        private _translate: TranslateService,
        private _localizedStateName: LocalizedStateNameService
    ) {}

    private _eventIconsService = inject(EventIconsService);

    // shortened translate method for better readability in the code
    private _t = (path: string): string => {
        return this._translate.instant(path)
    }

    public getRenderCellFunction(renderName: string, value: any[]) {
        switch (renderName) {
            case 'activated': return this.getActivatedStyling(value);
            case 'ai-prediction': return this.getAiPredictionStyling(value);
            case 'category': return this.getCategoryStyling(value);
            case 'connector': return this.getConnectorStyling(value);
            case 'current-ticket': return this.getCurrentTicketStyling(value);
            case 'duration-last-charge-event': return this.getDurationLastChargeEventStyling(value);
            case 'email': return this.getEmailStyling(value);
            case 'evse': return this.getEVSEStyling(value);
            case 'error-model-influence': return this.getErrorModelInfluenceStyling(value)
            case 'plug': return this.getPlugStyling(value);
            case 'device': return this.getDeviceStyling(value);
            case 'fixable-by': return this.getFixableByStyling(value);
            case 'from': return this.getFromStyling(value);
            case 'indicators': return this.getIndicatorsStyling(value);
            case 'usage': return this.getUsageStyling(value);
            case 'last-ok-session': return this.getLastOkSessionStyling(value);
            case 'last-regular-session': return this.getLastRegularSessionStyling(value);
            case 'last-msg': return this.getLastMsgStyling(value);
            case 'joined': return this.getJoinedStyling(value);
            case 'invited': return this.getInvitedStyling(value);
            case 'last-change': return this.getLastChangeStyling(value);
            case 'last-error': return this.getLastErrorStyling(value);
            case 'last-restart': return this.getLastRestartStyling(value);
            case 'last-session': return this.getLastSessionStyling(value);
            case 'location': return this.getLocationStyling(value);
            case 'open-time': return this.getOpenTimeStyling(value);
            case 'overall-state': return this.getOverallStateStyling(value);
            case 'persistent': return this.getPersistentStyling(value);
            case 'recommendations': return this.getRecommendationStyling(value);
            case 'roles': return this.getRolesStyling(value);
            case 'service-provider': return this.getServiceProviderStyling(value);
            case 'sessions-24-h': return this.getSessions24hStyling(value);
            case 'sessions-2-w': return this.getSessions2WStyling(value);
            case 'sim-card-online': return this.getSimCardOnlineStyling(value);
            case 'single-status': return this.getSingleStateStyling(value);
            case 'state-tickets': return this.getStateStylingTickets(value);
            case 'state-users': return this.getStateStylingUsers(value);
            case 'station-home': return this.getStationHomeStyling(value);
            case 'station-overview': return this.getStationOverviewStyling(value);
            case 'tenant-groups': return this.getTenantGroupsStyling(value);
            case 'tickets': return this.getTicketsStyling(value);
            case 'time': return this.getTimeStyling(value);
            case 'to': return this.getToStyling(value);
            case 'users': return this.getUsersStyling(value);
            case 'tenant-access': return this.getTenantAccessStyling(value);
            case 'versions': return this.getVersionsStyling(value);
            case 'w-to-kw': return this._getWtoKWStyling(value);
            case 'kwh': return this._getKWHStyling(value);
            case 'last-error-state-cause': return this._getLastErrorStateCauseStyling(value);
            case 'ocpp-status': return this.getOcppStatusStyling(value);
            case 'default-date': return this._getDateUpperLowerStyling(value);
            case 'scheduled-restarts': return this._getScheduledRestartsStyling(value);
            default: return this.getDefaultStyling(value);
        }
    }

    // - - - - - Column Styling Functions - - - - - //


    // prepare data & styling for 'Activated' column
    public getActivatedStyling(value: any[]) {
        if (value[0] == null) return '-';

        return `<div class="column-activated">
                    <span class="material-icon ${value[0]}">
                        ${value[0] == true ? 'notifications' : 'notifications_off'}
                    </span>
                </div>`;
    }


    // prepare data & styling for 'AI Prediction' column
    public getAiPredictionStyling(value: any[]) {
        if (typeof value[0] == 'string') value[0] = parseInt(value[0]);

        return `<div class="column-aiprediction">
                    <span class="state ${this._stateClassPipe.transform(value[0])}">
                        <div title="Health Index" class="material-icon">${value[0] != null ? 'health_and_safety' : 'gpp_bad'}</div>
                        ${value[0] != null ? value[0] : '-'} %
                    </span>
                </div>`;
    }


    // prepare data & styling for 'Autorestart' column
    public getAutorestartStyling(value: any[]) {
        if (value[0] == null) return '-';
        return `<div class="column-autorestart">
                    ${value[0] == 'Yes' ?
                        '<span class="material-icon state-ok-txt">done</span>' :
                        '<span class="material-icon state-failure-txt">close</span>'
                    }
                </div>`;
    }


    // prepare data & styling for 'Connector' column
    public getCategoryStyling(value: any[]) {
        if (value[0] == null) return '-';

        let iconMap: any = {
            'update': 'new_releases',
            'information': 'campaign',
            'warning': 'warning',
            'error': 'error'
        };

        let icon = iconMap[value[0]] || '';

        return `<div class="column-category">
                    <span class="material-icon ${value[0]}">
                        ${icon}
                    </span>
                </div>`;
    }


    // prepare data & styling for 'Connector' column
    public getConnectorStyling(value: any[]) {
        if (value[0] == null) return '-';
        if (value[0] == 0) 
            return `<div class="column-connector"><span class="station"></span></div>`;
        else 
            return `<div class="column-connector"><span class="conn"></span>
                    <span class="num">${value[0]}</span></div>`;
    }


    // prepare data & styling for 'Current Ticket' column
    public getCurrentTicketStyling(value: any[]) {
        let [id, isOpen, modifiedOn, createdOn] = value;
        if (id == null || isOpen == null || (modifiedOn == null && createdOn == null)) return '-';

        let date = isOpen ? createdOn : modifiedOn;
        date = formatDate(date, 'MMM d, y', this._translate.currentLang);

        return `<div class="column-currentticket">
                    <span class="${isOpen? 'open-title' : 'closed-title'}">
                        ${id}
                    </span><br>
                    <span class="${isOpen? 'open-date' : 'closed-date'}">
                        ${(isOpen ? this._t('DASHBOARD.COLUMNS.CURRENT_TICKET.OPENED') : this._t('DASHBOARD.COLUMNS.CURRENT_TICKET.CLOSED'))}: ${date}
                    </span>
                </div>`
    }


    // prepare data & styling for 'Duration last charge event' column
    public getDurationLastChargeEventStyling(value: any[]) {
        if (value[0] == null) return '-';
        return `${formatSeconds(value[0])} h`
    }


    // prepare data & styling for 'E-Mail' column
    public getEmailStyling(value: any[]) {
        if (value[0] == null) return '-';
        const isUser = value[1] && value[1] == this._appRepo.getCurrentUser()?.uuid;

        return `<div class="column-email">
                    <span ${isUser ? 'class="is-user"' : ''}>${value[0]}</span>
                </div>`;
    }


    // prepare data & styling for 'Error Model Influence' column, "detailed" = true: pills aligned, "Ok" state is shown
    public getErrorModelInfluenceStyling(value: any[], detailed: boolean = false) {
        let [single, multiple] = value;
        if (single == null || multiple == null) return '-';

        if (detailed) {
            return `<div class="column-errormodelinfluence errormodelinfluence-detailed">
                        <div><span class="info">${this._t('COMMON.STATES.OCCURENCE.SINGLE')}:</span></div>
                        <div><span class="${this._stateClassPipe.transform(single, 'bg')} state-pill">${single}<br></div>
                        <div><span class="info">${this._t('COMMON.STATES.OCCURENCE.MULTIPLE')}:</span></div>
                        <div><span class="${this._stateClassPipe.transform(multiple, 'bg')} state-pill">${multiple}</span></div>
                    </div>`;
        }

        return `<div class="column-errormodelinfluence">
                    <span class="info">
                        ${this._t('COMMON.STATES.OCCURENCE.SINGLE')}: <span class="${single != "Ok" ? this._stateClassPipe.transform(single, 'bg') : 'empty'}">
                            ${single != "Ok" ? this._localizedStateName.instant(single) : '-'}
                        </span><br>
                        ${this._t('COMMON.STATES.OCCURENCE.MULTIPLE')}: <span class="${multiple != "Ok" ? this._stateClassPipe.transform(multiple, 'bg') : 'empty'}">
                            ${multiple != "Ok" ? this._localizedStateName.instant(multiple) : '-'}
                        </span>
                    </span>
                </div>`;
    }


    public getErrorInsightsStyling(value: any[]) {
        const [errorCode, errorCluster] = value;
        const icon = this._eventIconsService.getEventIcon(errorCode)
        return `<div class="column-errorinsights flex-row align-items-center justify-content-start no-wrap">
                    <div class="material-icon icon-box">${icon}</div>
                    <div class="pl-8">
                        <span class="no-word-break">${errorCode}</span><br>
                        ${errorCluster ? `<span class="subline">${errorCluster}</span>` : ``}
                    </div>
                </div>`
    }


    // prepare data & styling for 'EVSE', 'Plug' and 'Device' column
    private _getTwoValuesStyling(value: any[]) {
        return `<div class="column-twovalues">
                    <span>${value[0] ?? '-'}</span><br><span>${value[1] ?? '-'}</span>
                </div>`
    }
    public getEVSEStyling = this._getTwoValuesStyling;
    public getPlugStyling = this._getTwoValuesStyling;
    public getDeviceStyling = this._getTwoValuesStyling;


    // prepare data & styling for 'Fixable by' column
    public getFixableByStyling(value: any[]) {
        if (value[0] == null) return '-';

        return `<div class="column-fixableby">
                    ${value[0] == 'Yes' ? '<span class="icon-box"><span class="material-icon">electrical_services</span></span>' : '-'}
                </div>`;
    }


    // prepare data & styling for 'Indicators' column
    public getIndicatorsStyling(value: any[]) {
        let [heartbeat, charging, error] = value;
        if (heartbeat == null && charging == null && error == null) return '-';

        let results = [];

        if (heartbeat !== null && heartbeat !== undefined)
            results.push(`<span title="Heartbeat" class="material-icon ${this._stateClassPipe.transform(heartbeat)}">${heartbeat == 'Failure' ? 'heart_broken' : 'favorite'}</span>`);

        if (charging !== null && charging !== undefined)
            results.push(`<span title="Charging Model" class="material-icon ${this._stateClassPipe.transform(charging)}">ev_station</span>`);

        if (error !== null && error !== undefined)
            results.push(`<span title="Error Model" class="material-icon ${this._stateClassPipe.transform(error)}">warning</span>`);

            return `<div class="column-indicators">${results.join('')}</div>`;
    }


    // prepare data & styling for 'Last Ocpp Message', 'Last Regular Session' and 'Last Msg' column
    private _getDateUpperLowerStyling(value: any[]) {
        if (value[0] == null) return '-'

        let dateUpper = formatDate(value[0], 'MMM d', this._translate.currentLang);
        let dateLower = formatDate(value[0], 'y, HH:mm', this._translate.currentLang);

        return `<div class="column-twovalues">
                    <span>${dateUpper}</span><br><span>${dateLower}</span>
                </div>`;
    }
    public getLastRegularSessionStyling = this._getDateUpperLowerStyling;
    public getLastMsgStyling = this._getDateUpperLowerStyling;
    public getTimeStyling = this._getDateUpperLowerStyling;
    public getLastOkSessionStyling = this._getDateUpperLowerStyling;

    private _getScheduledRestartsStyling(value: any[]) {
        const [schedule] = value;
        if (!schedule) return ' - '
        return schedule == 'Daily restart' ? this._t('DETAILS_VIEW.RESTARTS.DAILY_RESTART') : this._t('DETAILS_VIEW.RESTARTS.WEEKLY_RESTART');
    }

    // prepare data & styling for 'From' and 'To' column
    private _getDateUpperLowerNoStatusStyling(value: any[]) {
        if (value[0] == null) return '-'

        let dateUpper = formatDate(value[0], 'MMM d', this._translate.currentLang);
        let dateLower = formatDate(value[0], 'y, HH:mm', this._translate.currentLang);

        return `<div class="column-twovalues">
                    <span>${dateUpper}</span><br><span>${dateLower}</span>
                </div>`;
    }
    public getFromStyling = this._getDateUpperLowerNoStatusStyling;
    public getToStyling = this._getDateUpperLowerNoStatusStyling;


    // prepare data & styling for 'Joined' and 'Invited' column
    public _getInvitationStyling(value: any[]) {
        if (value[0] == null) return '-';
        value[0] = formatDate(value[0], 'MMM d, y, HH:mm', this._translate.currentLang);

        return `<div class="column-invitestate">
                    <span>${value[0]}</span>
                </div>`;
    }
    public getJoinedStyling = this._getInvitationStyling;
    public getInvitedStyling = this._getInvitationStyling;


    // prepare data & styling for 'Last Change' column
    public getLastChangeStyling(value: any[]) {
        if (value[0] == null) return '-';
        value[0] = formatDate(value[0], 'MMM d, y', this._translate.currentLang);

        return `<div class="column-lastchange">
                    <span>${value[0]}</span>
                </div>`;
    }


    // prepare data & styling for 'Last Error' column
    public getLastErrorStyling(value: any[]) {
        let [error, date, errorDesc, errorInfo] = value;
        if (date != null) date = formatDate(date, 'MMM d, y, HH:mm', this._translate.currentLang);

        const descDefined = errorDesc !== null && errorDesc !== undefined && errorDesc.length > 0;
        return `<div class="column-lasterror">
                    <span title="Error Code">${error ?? '-'}</span><br><span class="subline" title="Date of occurrence">${date ?? '-'}</span><br>
                    ${errorInfo ? '<span class="subline" title="Error Info">' + errorInfo + ',</span> ' : ''}
                    <span class="subline" title="Error Description">${descDefined ? errorDesc : ' - '}</span>
                </div>`;
    }


    // prepare data & styling for 'Last Restart' column
    public getLastRestartStyling(value: any[]) {
        let [attempt, successful] = value;
        if (attempt == null || successful == null) return '-';

        let attemptDate = new Date(attempt);
        let successfulDate = new Date(successful);

        if (successfulDate >= attemptDate) {
            successful = formatDate(successful, 'MMM d, y, HH:mm', this._translate.currentLang);
            return `<div class="column-lastrestart">
                        <span>${this._t('DASHBOARD.COLUMNS.RESTART.SUCCESSFUL')}</span><br>
                        <span>${successful}</span>
                    </div>`;
        } else {
            attempt = formatDate(attempt, 'MMM d, y, HH:mm', this._translate.currentLang);
            return `<div class="column-lastrestart">
                        <span class="failed ${this._stateClassPipe.transform("Failure")}">${this._t('DASHBOARD.COLUMNS.RESTART.FAILED')}</span><br>
                        <span>${attempt}</span>
                    </div>`;
        }
    }


    // prepare data & styling for 'Last Session' column
    public getLastSessionStyling(value: any[]) {
        // value[0] = lastChargingDuration in seconds, value[1] = lastChargedEnergy in kWh
        if (value[0] == null || value[1] == null) return '-'
        // session is short when duration under 1 minute
        // session is "zeroKWh" when chargedEnergy under 1000 Wh
        const isShortOrZeroKWh = value[0] < 60 || value[1] < 1;

        return `<div class="column-lastsession">
                    <span class="${this._stateClassPipe.transform(isShortOrZeroKWh ? 'To Be Monitored' : 'Ok')}">
                        ${formatSeconds(value[0])} h - ${value[1]} kWh
                    </span>
                </div>`
    }


    // prepare data & styling for 'Location' column
    public getLocationStyling(value: any[]) {
        if (value[0] == null || value[1] == null || value[2] == null || value[3] == null) return '-';

        return `<div class="column-location">
                    <span>${value[0]}</span><br>
                    <span>${value[1]} ${value[2]} ${value[3]}</span>
                </div>`;
    }


    // prepare data & styling for 'Open Time' column
    public getOpenTimeStyling(value: any[]) {
        let created = value[0];
        let isOpen = value[1];
        let modified = value[2];

        if (created == null || isOpen == null || modified == null) return '-';

        let days = this._daysDiff(created, isOpen ? new Date().toISOString() : modified);
        created = formatDate(created, 'MMM d, y', this._translate.currentLang);

        return `<div class="column-opentime">
                    <span class="${isOpen == true ? "" : "acc-dark"}">${days} ${this._t('COMMON.TIMES.DAY.' + (days == 1 ? 'ONE' : 'OTHER'))}</span><br>
                    <span class="${isOpen == true ? "acc-dark" : "acc-light"}">${created}</span>
                </div>`;
    }


    // prepare data & styling for 'Overall State' column
    public getOverallStateStyling(value: any[]) {
        return `<div class="column-overallstate">
                    <span class="state ${this._stateClassPipe.transform(value[0], 'bg')}">
                        ${this._localizedStateName.instant(value[0])}
                    </span>
                </div>`;
    }


    // prepare data & styling for 'Persistent' column
    public getPersistentStyling(value: any[]) {
        if (value[0] == null) return '-';

        return `<div class="column-persistent">
                    <span class="material-icon ${value[0]}">
                        ${value[0] == true ? 'done' : 'clear'}
                    </span>
                </div>`;
    }


    // prepare data & styling for 'Recommendation' column
    public getRecommendationStyling(value: any[]) {
        let [recommendation, comment] = value;
        if (recommendation == null) return '-';

        if (!comment) comment = '';

        const recommendationIconMap: {[key: string]: string} = {
            'Check SIM Portal': 'sim_card',
            'Check Ticket State': 'description',
            'Create Ticket': 'note_add',
            'No issues detected': 'done',
            'Out of Order': 'clear',
            'Restart Station': 'replay_circle_filled',
        };

        const recommendationTranslationsMap: Record<string, string> = {
            'Check SIM Portal': 'CHECK_SIM',
            'Check Ticket State': 'CHECK_TICKET',
            'Create Ticket': 'CREATE_TICKET',
            'No issues detected': 'NO_ISSUES',
            'Out of Order': 'OUT_OF_ORDER',
            'Restart Station': 'RESTART_STATION',
        }

        let icon = recommendationIconMap[recommendation as Recommendation] || '';

        return `<div class="column-recommendation">
                    <span class="icon-${icon} material-icon">${icon}</span>
                    <div class="icon-${icon} recommendation-info">
                        <span>${this._t('RECOMMENDATIONS.' + recommendationTranslationsMap[recommendation])}</span>
                        <span>${comment}</span>
                    </div>
                </div>`;
    }


    // prepare data & styling for 'Roles' column
    public getRolesStyling(value: any[]) {
        return `<div class="column-roles">
                    <span title="Guest" class="${value[0].includes(RoleTypes.Guest) ? "active" : ""} material-icon">person</span>
                    <span title="Operator" class="${value[0].includes(RoleTypes.Operator) ? "active" : ""} material-icon">build</span>
                    <span title="User Admin" class="${value[0].includes(RoleTypes.UserAdmin) ? "active" : ""} material-icon">manage_accounts</span>
                </div>`;
    }


    // prepare data & styling for 'Service Provider' column
    public getServiceProviderStyling(value: any[]) {
        let [provider, ticketUrl, ticketId] = value;

        // base link if ticket url is not set
        let baseLink = 'https://eon-emobility.crm4.dynamics.com/main.aspx?appid=cfae5785-6825-433b-82bb-c1ee19fcfbe2&pagetype=entityrecord&etn=eon_ladesaulentickets&id=';

        return `<div class="column-serviceprovider">
                    <span>${provider != null ? provider : '-'}</span>
                    <a 
                        target="_blank"
                        href="${ticketUrl ? ticketUrl : baseLink + ticketId}"
                        class="material-icon ${ticketUrl ? '' : ticketId == null ? 'disabled' : ''}"
                    >
                        open_in_new
                    </a>
                </div>`;
    }


    // prepare data & styling for 'Sessions 24 h' 'Sessions 2 W' column
    private _getSessionsStyling(value: any[]) {
        let [ok, zero, short] = value;
        if (ok == null || zero == null || short == null) return '-';

        let results = [];

        if (ok > 0) results.push(`<span class="ok ${this._stateClassPipe.transform("Ok")}">OK: ${ok}</span>`);

        if (zero > 0 || short > 0) {
            let errSessions = [];
            if (zero > 0) errSessions.push(`<span class="zero ${this._stateClassPipe.transform("To Be Monitored")}">${this._t('DETAILS_VIEW.SESSION.ZERO')}: ${zero}</span>`);
            if (short > 0) errSessions.push(`<span class="short ${this._stateClassPipe.transform("To Be Monitored")}">${this._t('DETAILS_VIEW.SESSION.SHORT')}: ${short}</span>`);
            results.push(errSessions.join(`<span class="sep ${this._stateClassPipe.transform("To Be Monitored")}">,&nbsp;</span>`));
        }

        if (results.length > 0) return '<div class="column-sessions">' + results.join('<br>') + '</div>';
        else return '-';
    }
    public getSessions24hStyling = this._getSessionsStyling
    public getSessions2WStyling = this._getSessionsStyling


    // prepare data & styling for 'Sim Card Online' column
    public getSimCardOnlineStyling(value: any[]) {
        if (value[0] == undefined || value[0] == null) return 'n/a';
        return value[0];
    }


    // prepare data & styling for 'State' column
    public getStateStylingTickets(value: any[]) {
        let isOpen = value[0] == null ? '-' : value[0] ? 'Open' : 'Closed';
        return `<div class="column-state-tickets">
                    <span class="${isOpen == 'Closed' ? "closed" : ""}">
                        ${isOpen}
                    </span>
                </div>`;
    }


    // prepare data & styling for 'State' column
    public getStateStylingUsers(value: any[]) {
        let iconMap: any = {
            'active': 'done',
            'inactive': 'pending',
            'invited': 'mail',
            'invite expired': 'hourglass_bottom'
        };

        let icon = iconMap[value[0]] || '';
        let title = value[0].charAt(0).toUpperCase() + value[0].slice(1);

        return `<div class="column-state-users">
                    <span title="${title}" class="${value[0].toLowerCase().replace(/ /g, '-')} material-icon">
                        ${icon}
                    </span>
                </div>`;
    }


    // prepare data & styling for 'Station' column
    public getStationHomeStyling(value: any[]) {
        // first is always stationId, second is overall (when available) or heartbeat, third is only set if overall is not available (guest role)
        let [stationId, overallOrHeartBeatState, chargingModelState] = value;
        let res = `<span class="station-id">${stationId}</span>`;
        let featuredState = chargingModelState
            ? this._stateHelper.getLowestEnumeratedStateInArray([chargingModelState, overallOrHeartBeatState])[0]
            : overallOrHeartBeatState

        if (value[1]) res += `<br><span class="state ${this._stateClassPipe.transform(featuredState, 'bg')}">${featuredState}</span>`;
        return res
    }

    // prepare data & styling for 'Station' column
    public getStationOverviewStyling(value: any[]) {
        return `<div class="column-station">
                    <span class="station-id">${value[0]}</span><br>
                    <span class="state ${this._stateClassPipe.transform(value[1], 'bg')}">
                        ${this._localizedStateName.instant(value[1])}
                    </span>
                </div>`;
    }


    // prepare data & styling for 'Tenant Groups' column
    public getTenantGroupsStyling(value: any[]) {
        if (value[0] == null || value[0].length === 0) return '-';

        let results: any[] = [];

        value[0].forEach((tenant: any) => {
            results.push(`<span class="bubble">${tenant}</span>`);
        });

        return `<div class="column-array">${results.join('')}</div>`;
    }

    // prepare data & styling for 'Tenant Groups' column in notifications view
    // checks for matches in "all" and "user" tenants [all, user] and displays non-matching
    // tenants in a different color
    public getNotificationsTenantGroupsStyling(value: any[]) {
        const [allTenants, userTenants] = value;
        if (allTenants == null || allTenants.length === 0) return '-';

        let results: any[] = [];

        allTenants.forEach((tenant: any) => {
            const isMatching = userTenants.includes(tenant);
            results.push(`<span class="bubble ${isMatching ? '' : 'disabled'}">${tenant}</span>`);
        });

        return `<div class="column-array">${results.join('')}</div>`;
    }


    // prepare data & styling for 'Tickets' column
    public getTicketsStyling(value: any[]) {
        let [totalTickets, ticketsOpen] = value;
        if (ticketsOpen == null || totalTickets == null) return '-';

        let ticketsClosed = totalTickets - ticketsOpen;

        return `<div class="column-tickets">
                    ${ticketsOpen > 0 ? `<span>${this._t('DASHBOARD.COLUMNS.CURRENT_TICKET.OPEN')}: ${ticketsOpen}</span>` : ''}
                    ${ticketsOpen > 0 && ticketsClosed > 0 ? '<br>' : ''}
                    ${ticketsClosed > 0 ? `<span class="closed">${this._t('DASHBOARD.COLUMNS.CURRENT_TICKET.CLOSED')}: ${ticketsClosed}</span>` : ''}
                </div>`;
    }


    // prepare data & styling for 'Usage' column
    public getUsageStyling(value: any[]) {
        let [energy, sessions] = value;
        
        if (energy == null || energy == -1 || sessions == null || sessions == -1) {
            return 'No Data'
        }

        // transform from Wh to MWh
        energy = energy / 1000000;
        // rounded to whole numbers if energy / sessions > 1 - else show one decimal
        energy = energy > 1 ? Math.round(energy) : formatNumber(Math.round(energy*10)/10, this._translate.currentLang);
        sessions = sessions > 1 ? Math.round(sessions) : formatNumber(Math.round(sessions*10)/10, this._translate.currentLang);

        return `<div class="column-twovalues">
                    <span>${energy} MWh / ${this._t('COMMON.ABBREVIATIONS.MONTH')}</span><br>
                    <span>${sessions} ${this._t('COMMON.SESSION.OTHER')+' / '+this._t('COMMON.ABBREVIATIONS.MONTH')}</span>
                </div>`
    }


    // prepare data & styling for 'Users' and 'Tenant Access' column
    private _getCutOffListStyling(value: any[]) {
        if (value[0] == null || value[0].length === 0) return '-';

        let results: any[] = [];
        const maxElements = 8;

        value[0].forEach((tenant: any, index: number) => {
            if (index < maxElements) {
                results.push(`<span class="bubble">${tenant}</span>`);
            }
        });

        if (value[0].length > maxElements) {
            const remainingCount = value[0].length - maxElements;
            results.push(`<span class="no-break">+${remainingCount}</span>`);
        }

        return `<div class="column-array">${results.join('')}</div>`;
    }
    public getUsersStyling = this._getCutOffListStyling;
    public getTenantAccessStyling = this._getCutOffListStyling;


    // prepare data & styling for 'Versions' column
    public getVersionsStyling(value: any[]) {
        if (value[0] == null || value[1] == null) return '-';

        let lastDigits = value[1].substring(value[1].length - 14);
        let shortenedFW = lastDigits.length == 14 ? `...${lastDigits}` : lastDigits;

        return `<div class="column-twovalues">
                    <span>OCPP: ${value[0]}</span><br>
                    <span title="${value[1]}">FW: ${shortenedFW}</span>
                </div>`;
    }

    // prepare data & styling for 'OCPP Status' column
    public getOcppStatusStyling(value: any[]) {
        if (value[0] == null && value[1] == null) return '-';
        if (value[1] == null) {
        return `<div class="column-ocppstatus">
                    <span>${value[0]}</span>
                </div>`;
        }
        else {
            let lastOkDate = formatDate(value[1], 'MMM d, y, HH:mm', this._translate.currentLang);
            if (value[0] == null) {
                return `<div class="column-ocppstatus">
                            <span>-</span><br>
                            <span class="subline">${this._t('DASHBOARD.COLUMNS.OCPP_STATUS.LAST_OK_STATE')}:</span><br>
                            <span class="subline">${lastOkDate}</span>
                        </div>`;
            } else {
                return `<div class="column-ocppstatus">
                            <span>${value[0]}</span><br>
                            <span class="subline">${this._t('DASHBOARD.COLUMNS.OCPP_STATUS.LAST_OK_STATE')}:</span><br>
                            <span class="subline">${lastOkDate}</span>
                        </div>`;
            }
        }
    }

    // returns kW from W, rounded to 2 decimals, in locale decimal format
    private _getWtoKWStyling(value: any[]) {
        const [ watt ] = value;
        if (watt === null || watt === undefined) return '-';
        return `${formatNumber(+(watt / 1000).toFixed(2), this._translate.currentLang)} kW`
    }

    // returns kWh, rounded to 2 decimals, in locale decimal format
    private _getKWHStyling(value: any[]) {
        const [ kWh ] = value;
        if (kWh === null || kWh === undefined) return '-';
        return `${formatNumber((kWh).toFixed(2), this._translate.currentLang)} kWh`
    }

    // prepare default styling
    public getDefaultStyling(value: any[]) {
        if (value[0] == null) return '-';
        return value.join('<br>');
    }

    public getSingleStateStyling(value: any[]) {
        return this._localizedStateName.instant(value[0])
    }

    private _getLastErrorStateCauseStyling(value: any[]) {
        const [state, lastErrorStateCause, lastErrorStateCauseDescription, lastErrorStateCauseInfo] = value;
        if (!state || !lastErrorStateCause) return ' - '
        const descDefined = lastErrorStateCauseDescription !== null && lastErrorStateCauseDescription !== undefined && lastErrorStateCauseDescription.length > 0;
        return `<span class="${this._stateClassPipe.transform(state)}">${lastErrorStateCause}</span><br>
                ${lastErrorStateCauseInfo ? '<span class="subline" title="Error State Cause Info">' + lastErrorStateCauseInfo + ',</span>  ' : ''}
                <span class="subline" title="Error State Cause Description">${descDefined ? lastErrorStateCauseDescription : ' - '}</span>`
    }


    // - - - - - - - - - - - - - - - - - - - - - - - //

    private _daysDiff(from: string, until: string): number {
        const fromDate = new Date(from);
        const untilDate = new Date(until);
        const timeDifference = untilDate.valueOf() - fromDate.valueOf();
        const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
        if (daysDifference < 0) return 0;
        else return daysDifference;
    }
}
