import { ChangeDetectionStrategy,Component, effect, ElementRef, EventEmitter, input, Input, Output, Renderer2, ViewChild } from '@angular/core';

@Component({
    selector: 'radio-button-group',
    template: `
        <div 
            class="button-group-wrapper"
            [class.line]="style === 'line'"
            [class.compact]="textStyle === 'compact'"
            #groupWrapper
        >
            @for (option of options(); track option.value) {
                <button
                    type="button"
                    [tooltip]="option.tooltip ? option.tooltip : null"
                    [size]="'small'"
                    [textAlign]="'left'"
                    (click)="updateValue(option.value)"
                >
                    {{ option.label }}
                </button>
            }
            <span 
                class="active-state-slider"
                #activeStateIndicator
            ></span>
        </div>
    `,
    styleUrls: ['./radio-button-group.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class RadioButtonGroupComponent {
    @ViewChild('groupWrapper', {static: false}) groupWrapper: ElementRef | undefined;
    private _activeStateIndicator: ElementRef | undefined;
    @ViewChild('activeStateIndicator', {static: false}) set indicator(activeStateIndicator: ElementRef) {
        // move indicator once rendered in DOM, fallback to first option value if no activeSelection is set
        this._activeStateIndicator = activeStateIndicator;
        const options = this.options();
        const selection = this._activeSelection ?? (options && options[0] && !this.toggleNull ? options[0].value : null);
        if (selection !== null) setTimeout(() => this.moveIndicator(selection), 1);
    };
    // available options
    public options = input<{label: string | number, value: string | number, tooltip?: string}[] | null>(null);
    // either button group or line below active options
    @Input() style: 'buttons' | 'line' = 'buttons';
    // styling for text
    @Input() textStyle: 'default' | 'compact' = 'default';
    // enables toggle functionality, if true, activeSelection can be null
    @Input() toggleNull: boolean = false;
    // active option
    private _activeSelection: string | number | undefined | null;
    @Input() set activeSelection(value: string | number | null | undefined) {
        if (!this.toggleNull && value == null) return
        this._activeSelection = value;
        if (value !== undefined) this.moveIndicator(value);
    }
    @Output() activeSelectionChange = new EventEmitter<number | string | null>();

    constructor(
        private _renderer: Renderer2
    ) {
        // call moveIndicator method when initial options are updated
        // this prevents the layout from breaking e.g. when switching translations in runtime
        effect(() => {
            const options = this.options();
            if (!options || !this._activeSelection) return;
            this.moveIndicator(this._activeSelection)
        })
    }

    public updateValue(value: string | number) {
        if (!this.options || (!this.toggleNull && this._activeSelection === value)) return;
        this._activeSelection = this.toggleNull && this._activeSelection === value ? null : value;
        // emit value, set activeStateSlider in background of button active index
        this.moveIndicator(this._activeSelection);
        this.activeSelectionChange.emit(this._activeSelection);
    }

    // moves and resizes indicator behind buttons
    public moveIndicator(toValue: string | number | null) {
        const options = this.options();
        if (!options || !this.groupWrapper || !this._activeStateIndicator) return;
        if (toValue == null) {
            this._renderer.setStyle(this._activeStateIndicator.nativeElement, 'opacity', '0');
            return;
        } else {
            this._renderer.setStyle(this._activeStateIndicator.nativeElement, 'opacity', '1');
        }
        // get index of button in group
        const index = options.map((option) => option.value).indexOf(toValue);
        // get reference element
        const activeButtonEl = this.groupWrapper.nativeElement.children[index];
        const activeButtonRect: DOMRect = activeButtonEl.getBoundingClientRect();
        const wrapperRect: DOMRect = this.groupWrapper.nativeElement.getBoundingClientRect();
        // set inidcator to dimensions of active button element
        this._renderer.setStyle(
            this._activeStateIndicator.nativeElement, 'width', activeButtonRect.width + 'px'
        )
        this._renderer.setStyle(
            this._activeStateIndicator.nativeElement, 'left', activeButtonRect.left - wrapperRect.left + 'px'
        )
    }
}
