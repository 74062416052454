/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { DataBaseService } from '../data-base-service';
import { DataApiConfiguration } from '../data-api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { HealthInformation } from '../models/health-information';


/**
 * Health Check
 */
@Injectable({
  providedIn: 'root',
})
export class HealthService extends DataBaseService {
  constructor(
    config: DataApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation healthCheck
   */
  static readonly HealthCheckPath = '/health/';

  /**
   * Returns health information of the server.
   *
   * This endpoint is added by the django healt check package and customized by adding the backend type including the customer prefix and the ci stage name the server is currently running.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `healthCheck()` instead.
   *
   * This method doesn't expect any request body.
   */
  healthCheck$Response(params?: {
  }): Observable<StrictHttpResponse<HealthInformation>> {

    const rb = new RequestBuilder(this.rootUrl, HealthService.HealthCheckPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<HealthInformation>;
      })
    );
  }

  /**
   * Returns health information of the server.
   *
   * This endpoint is added by the django healt check package and customized by adding the backend type including the customer prefix and the ci stage name the server is currently running.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `healthCheck$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  healthCheck(params?: {
  }): Observable<HealthInformation> {

    return this.healthCheck$Response(params).pipe(
      map((r: StrictHttpResponse<HealthInformation>) => r.body as HealthInformation)
    );
  }

}
