/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { DataBaseService } from '../data-base-service';
import { DataApiConfiguration } from '../data-api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ChargingStation } from '../models/charging-station';
import { Error } from '../models/error';


/**
 * Analysis Tab for the Errors of a Charging Station
 */
@Injectable({
  providedIn: 'root',
})
export class DetailsErrorsService extends DataBaseService {
  constructor(
    config: DataApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getChargingStation
   */
  static readonly GetChargingStationPath = '/chargingStations/{stationId}/';

  /**
   * Gets a single charging station.
   *
   * Fetch a single charging stations, optionally for a specific date
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getChargingStation()` instead.
   *
   * This method doesn't expect any request body.
   */
  getChargingStation$Response(params: {

    /**
     * Unique identifier of the Charging Station
     */
    stationId: string;

    /**
     * Time at which state the charging station should be returned
     */
    date?: string;
  }): Observable<StrictHttpResponse<ChargingStation>> {

    const rb = new RequestBuilder(this.rootUrl, DetailsErrorsService.GetChargingStationPath, 'get');
    if (params) {
      rb.path('stationId', params.stationId, {});
      rb.query('date', params.date, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ChargingStation>;
      })
    );
  }

  /**
   * Gets a single charging station.
   *
   * Fetch a single charging stations, optionally for a specific date
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getChargingStation$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getChargingStation(params: {

    /**
     * Unique identifier of the Charging Station
     */
    stationId: string;

    /**
     * Time at which state the charging station should be returned
     */
    date?: string;
  }): Observable<ChargingStation> {

    return this.getChargingStation$Response(params).pipe(
      map((r: StrictHttpResponse<ChargingStation>) => r.body as ChargingStation)
    );
  }

  /**
   * Path part for operation getErrorsOfChargingStation
   */
  static readonly GetErrorsOfChargingStationPath = '/chargingStations/{stationId}/errors/';

  /**
   * Gets errors of a charging station.
   *
   * Fetch the errors of a specific charging station, optionally for a specific time interval
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getErrorsOfChargingStation()` instead.
   *
   * This method doesn't expect any request body.
   */
  getErrorsOfChargingStation$Response(params: {

    /**
     * Unique identifier of the Charging Station
     */
    stationId: string;

    /**
     * The time up to which the list of errors should be displayed
     */
    date?: string;

    /**
     * The period in days before &#x27;date&#x27; from which all sessions should be returned
     */
    interval?: number;
  }): Observable<StrictHttpResponse<Array<Error>>> {

    const rb = new RequestBuilder(this.rootUrl, DetailsErrorsService.GetErrorsOfChargingStationPath, 'get');
    if (params) {
      rb.path('stationId', params.stationId, {});
      rb.query('date', params.date, {});
      rb.query('interval', params.interval, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Error>>;
      })
    );
  }

  /**
   * Gets errors of a charging station.
   *
   * Fetch the errors of a specific charging station, optionally for a specific time interval
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getErrorsOfChargingStation$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getErrorsOfChargingStation(params: {

    /**
     * Unique identifier of the Charging Station
     */
    stationId: string;

    /**
     * The time up to which the list of errors should be displayed
     */
    date?: string;

    /**
     * The period in days before &#x27;date&#x27; from which all sessions should be returned
     */
    interval?: number;
  }): Observable<Array<Error>> {

    return this.getErrorsOfChargingStation$Response(params).pipe(
      map((r: StrictHttpResponse<Array<Error>>) => r.body as Array<Error>)
    );
  }

}
