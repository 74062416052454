import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class LoggingService {
    protected trackLevel = 10;
    private _clientErrors: Error[] = [];
    private _serverErrors: HttpErrorResponse[] = [];

    public trackClientError(error: Error) {
        this._clientErrors.push(error);
        if (this._clientErrors.length > this.trackLevel) this._clientErrors.shift();
    }

    public trackServerError(error: HttpErrorResponse) {
        this._serverErrors.push(error);
        if (this._serverErrors.length > this.trackLevel) this._serverErrors.shift();
    }

    logError(message: string, stack: string) {
        // Send errors to server here
        console.log('LoggingService: ' + message);
    }

    get clientErrors(): Error[] {
        return this._clientErrors;
    }

    get serverErrors(): HttpErrorResponse[] {
        return this._serverErrors;
    }
}
