import { Directive, ElementRef,Input } from '@angular/core';

@Directive({ 
    selector: '[indeterminate]',
    standalone: false
})
// directive is callable on input[type="checkbox"] to easily set indeterminate state
export class IndeterminateDirective {
    @Input() set indeterminate(value: boolean) {
        this._checkbox.nativeElement.indeterminate = value;
    }

    constructor(
        private _checkbox: ElementRef
    ) {}
}
