import { Component, Input, OnInit, OnChanges, SimpleChanges, Output, EventEmitter, ChangeDetectionStrategy, ViewChild, ElementRef } from '@angular/core';

@Component({
  selector: 'app-select-single-radio',
  template: `
    <app-dropdown-button
        [title]="this.title"
        [desc]="this.selectedValDesc"
        [empty]="activeSelection == undefined || activeSelection == ''"
        [bodyClass]="this.bodyClasses.join(' ')"
        [maxBodyHeight]="this.bodyMaxHeight"
        [size]="size"
        [additionalInfo]="additionalInfo"
        [disabled]="disabled"
        [keepPosition]="keepPosition"
        (onOpen)="onOpen()"
        (onClose)="applyChanges()"
        (onEnter)="applyChanges()"
    >
        <ng-container body>
            <div 
                class="position-relative"
                *ngIf="this.options && this.options.length > 9"
            >
                <input
                    type="text"
                    [placeholder]="'COMMON.SEARCH' | translate"
                    (input)="filterOptions($event)"
                    #searchInput
                >
                <button
                    class="delete-search"
                    (click)="resetSearch()"
                    tabindex="-1"
                ></button>
            </div>
            <ul class="dropdown-list">
                <div 
                    *ngIf="!reinit"
                    #radioGroup
                >
                    <div class="radio-cont">
                        <input 
                            type="radio"
                            id="showall"
                            value="showall"
                            [checked]="activeSelection == undefined"
                            (change)="clearOption()"
                        >
                        <label for="showall"
                        >{{ firstOptionLabel }}</label>
                    </div>
                    <div 
                        *ngFor="let option of filteredOptions;"
                        class="radio-cont"
                    >
                        <input 
                            type="radio"
                            [id]="option.value"
                            [value]="option.value"
                            [checked]="activeSelection == option.value"
                            (change)="selectOption($event)"
                        >
                        <label [for]="option.value.toString()"
                        >{{ option.label.toString() }}</label>
                    </div>
                </div>
            </ul>
        </ng-container>
    </app-dropdown-button>
  `,
  styleUrls: ['./select-single-radio.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SelectSingleRadioComponent implements OnInit, OnChanges {
    @ViewChild('searchInput') searchInput: ElementRef | undefined = undefined;
    @ViewChild('radioGroup') radioGroup: ElementRef | undefined = undefined;
    // Title of Dropdown
    @Input('title') title!: string;
    // label of first (resetting) value
    @Input('firstOptionLabel') firstOptionLabel: string = 'All';
    // currently set filters
    @Input() activeSelection: string | number | null | undefined;
    @Output() activeSelectionChange = new EventEmitter<string | number>();
    // available options
    @Input('options') options!: any[] | undefined | null;
    // filtered options
    filteredOptions: any[] | undefined | null;
    @Input('size') size: 'default' | 'small' = 'default';
    // additional information on info icon in button
    @Input() additionalInfo: string | undefined;
    // whether this button is disabled
    @Input('disabled') disabled: boolean = false;
    // will keep initial position of dropdown content
    @Input() keepPosition: boolean = false;
    // classes for body of dropdown
    bodyClasses: string[] = ['swiper-no-swiping'];
    // manually setting max height if only a few options
    bodyMaxHeight: number | undefined;
    // reinit
    reinit: boolean = false;
    // previous value to check against before applying changes
    private _previousSelection!: string | number | null | undefined;
    // selected value with label
    selectedValDesc: string = "";

    constructor() { }

    ngOnInit(): void {
        this.updatePreselectedOption()
        // if less than 8 options, set max height to amt of options * height of selection element + 10px margin
        this.bodyMaxHeight = this.options && this.options.length < 9 ? (this.options?.length + 1) * 48 + 10 : undefined;
        this.filteredOptions = this.options;
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['options'] || changes['activeSelection']) {
            this.updatePreselectedOption()
            // fix to manually check eon's radio buttons after their init
            if (!this.activeSelection) {
                this.reinit = true;
                setTimeout(() => {
                    this.reinit = false
                }, 30);
            }
        }
    }

    filterOptions(search: any) {
        let query = search.target?.value;
        if (!this.options) return;
        if (query && query.length > 0) {
            this.filteredOptions = this.options.filter(option => {
                return JSON.stringify(option.label).toLowerCase().includes(query.toLowerCase())
            })
        } else {
            this.filteredOptions = this.options;
        }
    }

    resetSearch() {
        this.filteredOptions = this.options || [];
        if (this.searchInput) {
            this.searchInput.nativeElement.value = '';
            this.searchInput.nativeElement.focus()
        }
    }

    // update active and previous selection with current data from repo
    updatePreselectedOption() {
        this._previousSelection = this.activeSelection
        this.updateButtonTitle()
    }

    updateButtonTitle() {
        if (this.options != null && this.activeSelection) {
            let activeOption = this.options.find(option => option.value.toString() == this.activeSelection)
            this.selectedValDesc = activeOption.label;
        } else {
            this.selectedValDesc = this.firstOptionLabel;
        }
    }

    selectOption(event: any) {
        this.activeSelection = event.target?.value;
        this.updateButtonTitle()
    }
    clearOption() {
        this.activeSelection = undefined
        this.updateButtonTitle()
    }

    onOpen() {
        setTimeout(() => {
            // get index of active selection, shift focus to input
            let activeIndex = this.options?.map(x => x.value).indexOf(this.activeSelection);
            if (activeIndex == undefined) return;
            // index of -1 = firstOption, as its outside of the set options
            activeIndex++
            this.radioGroup?.nativeElement.children[activeIndex].querySelector('input')?.focus()
        }, 30)
    }

    // emit new value to parent when dropdown is closed
    applyChanges() {
        if (this.activeSelection === undefined || this.activeSelection === null) {
            if (this._previousSelection) {
                this.activeSelectionChange.emit()
            }
        } else {
            if (!this._previousSelection || (this._previousSelection !== this.activeSelection)) {
                // check if new selection differs from previous, only updating when needed
                this.activeSelectionChange.emit(this.activeSelection)
                this.updatePreselectedOption()
            }
        }
    }
}
