import { animate, style, transition, trigger } from "@angular/animations";
import { BehaviorSubject, catchError, combineLatest, EMPTY, map, Observable, startWith, Subject, take, takeUntil, tap } from "rxjs";
import { TrafficLoggerService } from "src/app/core/data-backend/data-services";
import {
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    HostListener,
    OnDestroy,
    OnInit,
    signal,
    ViewChild,
} from "@angular/core";
import { Title } from "@angular/platform-browser";
import { Router } from "@angular/router";
import { GlobalService } from "src/app/core/app-services/global.service";
import { SearchService } from "src/app/core/app-services/search.service";
import { appRepository } from "src/app/core/stores/app.repository";
import { detailsRepository } from "src/app/core/stores/details.repository";
import { overviewRepository } from "src/app/core/stores/overview.repository";
import { PermissionsService } from "src/app/core/app-services/permissions.service";
import packageJson from "package.json";
import { environment } from "src/environments/environment";
import { TranslateService } from "@ngx-translate/core";

@Component({
    selector: 'app-navigation',
    template: `
    <header 
        [class.condensed]="navCondensed()"
        [class.hidden]="navigationHidden$ | async"
    >
        <div class="nav-content">
            <div
                *ngIf="!(isDetailsPage$ | async)"
                class="nav-title"
                [innerHtml]="pageTitle"
            ></div>
            <div 
                class="nav-row flex-row align-items-center justify-content-start"
                *ngIf="isDetailsPage$ | async" 
            >
                <a
                    class="nav-link"
                    [routerLink]="(overviewRepository.activeView$ | async) == 'map' ? '/map' : '/overview'"
                    tabindex="1"
                >
                    <div class="material-icon">arrow_back</div>
                    <span class="position-relative">
                        {{ pageTitle }}
                    </span>
                </a>
                <ng-container *ngIf="detailsRepository.station$ | async as station">
                    <div [copy-clipboard]="station.stationId"></div>
                    <div 
                        class="nav-state"
                        [class]="station.featuredConnector.lastOverallState | stateClass: 'bg'"
                    >{{ station.featuredConnector.lastOverallState | localizedStateName | async }}</div>
                </ng-container>
            </div>
            <div class="flex-row h-100 align-items-center justify-content-center">
                <div
                    *ngIf="ngAppStageName != 'prod'" 
                    class="stage-indicator"
                >
                    <span title="Frontend Version">v{{ version }}</span> |
                    <span title="Stage Name">{{ngAppStageName}}</span> |
                    <span title="App ID">{{environment.appId}}</span> | 
                    <span title="Customer ID">{{appRepository.getSelectedCustomer()?.identifier}}</span> |
                    <span title="Branch">{{ ngAppBranch }}</span> |
                    <span title="Build Date">{{ ngAppBuildDate }}</span>
                </div>
                @if (searchAvailable$ | async) {
                    <ng-container *evcHasPermissions="'global.search'">
                        <div
                            *ngIf="showSearchInput"
                            class="nav-search"
                            [@inOutToSide]
                        >
                            <input
                                #searchInput
                                type="search"
                                [placeholder]="'COMMON.SEARCH' | translate"
                                [value]="searchService.searchQuery$ | async"
                            >

                            @if (((searchService.searchQuery$ | async) ?? '').length > 0){
                                <button
                                    class="delete-search"
                                    (click)="resetSearch($event)"
                                ></button>
                            }

                            <div
                                (click)="onSubmit(searchInput.value)"
                                class="material-icon"
                            >search</div>

                        </div>

                        <button
                            class="nav-btn"
                            (click)="toggleSearch()"
                            tabindex="1"
                            [evcTrackClick]="'nav-search'"
                            [evcTrackClickValue]="showSearchInput ? 'close' : 'open'"
                            [@inOutToSide]
                        >
                            <div class="nav-icon material-icon">
                                {{ showSearchInput ? 'close' : 'search' }}
                            </div>
                        </button>
                    </ng-container>
                }

                <button
                    *ngIf="(userHasBurgerPermissions$ | async) === true"
                    class="nav-btn menu-trigger"
                    (click)="menuOpen = !menuOpen"
                    [evcTrackClick]="'burger-menu'"
                    [evcTrackClickValue]="menuOpen ? 'close' : 'open'"
                    tabindex="1"
                >
                    <div class="nav-icon menu-trigger material-icon">menu</div>
                </button>

                <button
                    class="nav-btn user-trigger"
                    (click)="userOpen = !userOpen"
                    [evcTrackClick]="'user-menu'"
                    [evcTrackClickValue]="userOpen ? 'close' : 'open'"
                    tabindex="1"
                >
                    <div class="nav-icon user-trigger material-icon">person</div>
                </button>

                <div class="nav-brand">
                    <img
                        [src]="'../assets/logos/evailable-white.svg'" 
                        [alt]="environment.brandName"
                        [routerLink]="(canRouteOverview$ | async) ? '/overview' : '/admin-users'"
                    >
                </div>

            </div>
        </div>
    </header>
    <app-navigation-menu
        [(open)]="menuOpen"
    >
    </app-navigation-menu>
    <app-user-settings
        [(open)]="userOpen"
    >
    </app-user-settings>
    `,
    styleUrls: ['./navigation.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [
        trigger('inOutToSide',[
            transition(':enter',[
                style({ opacity: 0, transform: 'translateX(20px)' }),
                animate('.25s ease-out',
                    style({ opacity: 1, transform: 'translateX(0px)' })
                )
            ]),
            transition(':leave',[
                style({ opacity: 1, transform: 'translateX(0px)' }),
                animate('.25s ease-in',
                    style({ opacity: 0, transform: 'translateX(20px)' })
                )
            ])
        ])
    ]
})
export class NavigationComponent implements OnDestroy, OnInit {
    private readonly _destroying$ = new Subject<void>();
    pageTitle!: string;
    isDetailsPage$ = new BehaviorSubject<boolean>(false);
    searchAvailable$ = new BehaviorSubject<boolean>(true);
    showSearchInput: boolean = false;
    userOpen: boolean = false;
    menuOpen: boolean = false;
    public navCondensed = signal<boolean>(false);
    @ViewChild('searchInput') searchInput: ElementRef | undefined;
    private _storeInRepo: boolean = true;
    pageMappings: {[url: string]: {icon: string; title: string}} = {
        undefined: { icon: 'dashboard', title: 'DASHBOARD.TITLE' },
        "manage-users": { icon: 'manage_accounts', title: 'MANAGE_USERS_VIEW.TITLE' },
        "manage-tenants": { icon: 'groups', title: 'NAVIGATION.MANAGE_TENANT_ACCESS' },
        "notifications": { icon: 'chat', title: 'NOTIFICATIONS_VIEW.TITLE' },
        overview: { icon: 'dashboard', title: 'DASHBOARD.TITLE' },
        map: { icon: 'map', title: 'MAP_VIEW.TITLE' },
        kpi: { icon: 'speed', title: 'KPI_VIEW.TITLE' },
        filtersets: { icon: 'filter_alt', title: 'FILTER_SETS_VIEW.TITLE' },
        "insights": {icon: 'insights', title: 'NAVIGATION.INSIGHTS'},
        "error-insights": {icon: 'insights', title: 'ERROR_INSIGHTS_VIEW.TITLE'}
    };
    subpageMappings: {[key: string]: string} = {
        undefined: 'DETAILS_VIEW.HOME.TITLE',
        timeline: 'DETAILS_VIEW.TIMELINE.TITLE',
        'health-index': 'DETAILS_VIEW.HEALTH_INDEX.TITLE',
        errors: 'DETAILS_VIEW.ERRORS.TITLE',
        sessions: 'DETAILS_VIEW.SESSIONS.TITLE',
        tickets: 'DETAILS_VIEW.TICKETS.TITLE',
        restarts: 'DETAILS_VIEW.RESTARTS.TITLE',
        connection: 'DETAILS_VIEW.CONNECTION.TITLE',
        configuration: 'DETAILS_VIEW.CONFIGURATION.TITLE'
    };
    readonly ngAppStageName: string = process.env.NG_APP_STAGE_NAME
    readonly ngAppBuildDate: string = process.env.NG_APP_BUILD_DATE
    readonly ngAppBranch: string = process.env.NG_APP_BRANCH
    readonly version: string = packageJson.version
    readonly environment = environment;
    private _brandName: string;
    public userHasBurgerPermissions$ = this._permissionsService.userPermissions$.pipe(
        map((rolePermissionsList) => {
            for (let rolePermissions of rolePermissionsList) {
                if (rolePermissions.routes
                    && (rolePermissions.routes.dashboard !== false ||
                        rolePermissions.routes.operationMap !== false ||
                        rolePermissions.routes.kpiDashboard !== false ||
                        rolePermissions.routes.filterSets   !== false)) {
                    return true
                }
                else if (rolePermissions.global?.meterValueCalculator !== false) {
                    return true
                }
            }
            return false
        })
    );
    public canRouteOverview$ = this._permissionsService.ofPermission('routes.dashboard');
    public navigationHidden$: Observable<boolean>;

    constructor(
        public overviewRepository: overviewRepository,
        public detailsRepository: detailsRepository,
        public searchService: SearchService,
        public appRepository: appRepository,
        public globalService: GlobalService,
        public titleService: Title,
        public trafficLoggerService: TrafficLoggerService,
        private _router: Router,
        private _permissionsService: PermissionsService,
        private _translate: TranslateService
    ) {
        // listen to current url changes
        combineLatest({
            url: this.globalService.currentUrl$,
            newLang: this._translate.onLangChange.pipe(startWith(null))
        }).pipe(
            takeUntil(this._destroying$),
            // call setView on new navigation event
            tap(({url}) => this._setView(url))
        ).subscribe();

        // capitalize brand name
        this._brandName = environment.brandName.substring(0, 1).toUpperCase() + environment.brandName.substring(1);

        // hide navigation on fullscreen mode on map view
        this.navigationHidden$ = combineLatest({
            isMap: this.globalService.urlEnd$.pipe(map(url => url == 'map')),
            isFullscreen: this.globalService.isFullscreen$
        }).pipe(
            map(({isMap, isFullscreen}) => isMap && isFullscreen)
        );
    }

    ngOnInit(): void {
        // call set fn, the router only starts emitting on first in-app navigation event
        this._setView(this._router.url);
    }

    private _setView(url: string) {
        if (!url) return;
        const urlArr = url.split('?')[0].split('/').filter(x => x !== '');
        this.isDetailsPage$.next(urlArr.includes('details'));

        const searchAvailableOn = ['overview', 'map'];
        this.searchAvailable$.next(searchAvailableOn.includes(urlArr[0]));

        // set page title and tab name in browser
        if (this.isDetailsPage$.getValue()) {
            this.setDetailsPageTitle(urlArr)
        } else {
            this.setPageTitle(urlArr)
        }

        // resets stores and views for different routes
        if (urlArr.includes('map')) {
            this._resetForMap();
        } else if (urlArr.includes('kpi')) {
            this._resetForKpi()
        } else if (urlArr.includes('details')) {
            this._resetForDetails()
        } else {
            this._resetForOverview()
        }
    }

    @HostListener('window:keydown.enter', ['$event'])
    onEnterKey(event: KeyboardEvent) {
        if (!this.searchInput) return;
        let input = this.searchInput.nativeElement.value;
        if (input === undefined) return
        this.onSubmit(input)
    }

    @HostListener("window:scroll")
    onWindowScroll() {
        if (document.body.scrollTop > 50 || document.documentElement.scrollTop > 50) {
            this.navCondensed.set(true)
        } else {
            this.navCondensed.set(false)
        }
    }

    // if repo has active search query, show input and fill in value
    private _resetForOverview() {
        // enable storage in repo
        this._storeInRepo = true;
        // hide input until repo returns active value (if present)
        this.searchService.removeSearchQuery()
        this.showSearchInput = false
        // store active view
        this.overviewRepository.setActiveView('table')
        // get active val from repo, update input
        this.overviewRepository.searchQuery$.pipe(
            takeUntil(this._destroying$)
        ).subscribe(query => {
            if (query) {
                this.searchService.setSearchQuery(query)
                this.showSearchInput = true
            }
        })
    }

    // reset input for details pages (no search queries will be saved in a repo)
    private _resetForDetails() {
        window.scrollTo({ top: 0 })
        this._storeInRepo = false;
        this.searchService.removeSearchQuery()
        this.showSearchInput = false;
    }

    private _resetForMap() {
        window.scrollTo({ top: 0 })
        this.overviewRepository.setOffsetTop(0)
        this.overviewRepository.setActiveView('map')
    }

    private _resetForKpi() {
        window.scrollTo({ top: 0 })
        this.overviewRepository.setOffsetTop(0)
    }

    setPageTitle(urlArr: string[]) {
        const currentPage = this.pageMappings[urlArr[urlArr.length - 1]] || { icon: 'help', title: 'NOT_FOUND_VIEW.TITLE' };
        const localizedTitle = this._translate.instant(currentPage.title);
        this.pageTitle = `<div class="material-icon">${currentPage.icon}</div>${localizedTitle}`;
        this.titleService.setTitle(`${localizedTitle} | ${this._brandName}`);
    }

    setDetailsPageTitle(urlArr: string[]) {
        const currentSubpage = this.subpageMappings[urlArr[2]] || 'NOT_FOUND_VIEW.TITLE';
        const stationId = decodeURIComponent(urlArr[1]);
        if (currentSubpage == 'NOT_FOUND_VIEW.TITLE') this.pageTitle = currentSubpage;
        else this.pageTitle = stationId;
        const localizedTitle = this._translate.instant(currentSubpage);
        this.titleService.setTitle(`${stationId} - ${localizedTitle} | ${this._brandName}`);
    }

    onSubmit(data: string): void {
        data = data.trim()
        this.searchService.setSearchQuery(data)
        if (this._storeInRepo) {
            this.overviewRepository.setSearchQuery(data)
        }
    }

    resetSearch(e: Event) {
        e.preventDefault()
        if (this.searchInput && this.searchInput.nativeElement.value != '') {
            this.searchService.removeSearchQuery()
            if (this._storeInRepo) {
                this.overviewRepository.deleteSearchQuery();
            }
            this.searchInput.nativeElement.value = '';
            this.searchInput.nativeElement.focus();
        } else {
            this.toggleSearch()
        }
    }

    toggleSearch() {
        this.showSearchInput = !this.showSearchInput
        if (this.showSearchInput == false) {
            this.searchService.removeSearchQuery()
            if (this._storeInRepo) {
                this.overviewRepository.deleteSearchQuery();
            }
        } else {
            setTimeout(() => {
                this.searchInput?.nativeElement.focus();
            }, 300)
        }
    }

    sendKeywordToBackend(keyword: string): void {
        this.trafficLoggerService.trafficLogger({'body': {keyword: keyword}}).pipe(
            take(1),
            catchError(_ => EMPTY)
        ).subscribe();
    }

    ngOnDestroy(): void {
        this._destroying$.next(undefined);
        this._destroying$.complete();
    }
}
