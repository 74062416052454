import { Injectable, OnDestroy } from "@angular/core";
import { createStore, emitOnce, getRegistry, getStore, select, setProp, setProps, withProps } from "@ngneat/elf";
import { localStorageStrategy, persistState, sessionStorageStrategy } from "@ngneat/elf-persist-state";
import { Customer } from "../auth-backend/models";
import { CustomersConfig } from "../auth-backend/models/customers-config";
import { CustomersConfigService } from "../auth-backend/services/customers-config.service";
import { ReplaySubject, Subject, catchError, combineLatest, combineLatestWith, debounceTime, map, of, pairwise, share, startWith, switchMap, takeUntil, tap } from "rxjs";
import { User } from "../data-backend/models/user";
import { addEntities, deleteEntities, hasEntity, selectAllEntities, withEntities } from "@ngneat/elf-entities";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { RoleTypes } from "../data-backend/models";

const customerStateStore = createStore(
    {name: 'customerState'},
    withProps<{
        selectedCustomer: Customer | null
    }>({
        selectedCustomer: null
    })
)

const userProfileStore = createStore(
    {name: 'userProfile'},
    withProps<{
        user: User | null
    }>({
        user: null
    })
)

const availableCustomersStore = createStore(
    {name: 'availableCustomers'},
    withProps<{
        availableCustomers: string[] | null,
    }>({
        availableCustomers: null
    })
)

const customersConfigStore = createStore(
    {name: 'customersConfig'},
    withProps<{
        customersConfig: CustomersConfig | null,
    }>({
        customersConfig: null
    })
)

// stores last Store Values for all pre-defined stores for the current customer
const storedStatesStore = createStore(
    {name: 'storedStates'},
    withEntities<{
        customerId: string,
        stores: Record<string, Record<string, any>>
    }, 'customerId'>({idKey: 'customerId'})
)

persistState(customerStateStore, {
    key: 'customerState',
    storage: sessionStorageStrategy
})

persistState(userProfileStore, {
    key: 'userProfile',
    storage: sessionStorageStrategy
})

persistState(availableCustomersStore, {
    key: 'availableCustomerState',
    storage: sessionStorageStrategy
})

persistState(customersConfigStore, {
    key: 'customersConfigState',
    storage: sessionStorageStrategy
})

persistState(storedStatesStore, {
    key: 'storedStates',
    storage: localStorageStrategy
})

@Injectable({ providedIn: 'root' })
export class appRepository {
    constructor(
        private _customersConfigService: CustomersConfigService,
    ) {
        // stores last state of all pre-defined stores for the current customer
        // keeps them in localStorage until the customer is switched back

        // all store IDs that should have persistent state based on customerID
        const storeIds = ['overview', 'activeFilters', 'tableStore', 'mapState', 'searchStore', 'charts'];
        // get all requested stores from registry
        const stores = [...getRegistry()].filter((store) => storeIds.includes(store[0]));
        // separate store names and store updates
        // keep names separate incase not all storeIds were available
        const storeNames = stores.map((store) => store[0]);
        const storeUpdates = stores.map((store) => store[1]);
        // listen to any store's update
        combineLatest(storeUpdates).pipe(
            combineLatestWith(this.selectedCustomerId$),
            takeUntilDestroyed(),
            debounceTime(80),
            tap(([storesUpdate, customerId]) => {
                if (!customerId) return

                const storeValues = storeNames.reduce((prev, curr, index) => {
                    prev[curr] = storesUpdate[index]
                    return prev
                }, {} as Record<string, Record<string, any>>)

                emitOnce(() => {
                    // makes sure that deeply nested data is properly updated by deleting old data first
                    if (storedStatesStore.query(hasEntity(customerId))) {
                        storedStatesStore.update(deleteEntities(customerId))
                    }
                    storedStatesStore.update(addEntities({customerId, stores: storeValues}))
                })
            })
        ).subscribe()
    }

    public selectedCustomer$ = customerStateStore.pipe(
        select(state => state.selectedCustomer)
    )

    public selectedCustomerId$ = customerStateStore.pipe(
        select(state => state.selectedCustomer?.identifier || null)
    )

    public currentUser$ = userProfileStore.pipe(
        select(state => state.user)
    )

    public customersConfig$ = customersConfigStore.pipe(
        select(state => state.customersConfig)
    )

    public getSelectedCustomer = (): Customer | null => customerStateStore.getValue().selectedCustomer

    public getCurrentUser = (): User | null => userProfileStore.getValue().user

    public getUserRoles = (): User['roles'] => userProfileStore.getValue().user?.roles ?? []

    public availableCustomers$ = availableCustomersStore.pipe(
        switchMap((state) => {
            if (state.availableCustomers === null) {
                return this._customersConfigService.customersConfig().pipe(
                    map((customersConfig) => customersConfig.customers.map((customer) => customer.identifier)),
                    tap((customerList) => {
                        availableCustomersStore.update(setProp('availableCustomers', customerList));
                    }),
                    catchError(() => of(null))
                );
            } else {
                return of(state.availableCustomers);
            }
        }),
        share({ connector: () => new ReplaySubject(1) })
    );

    public updateSelectedCustomer(value: Customer | null) {
        customerStateStore.update(setProp('selectedCustomer', value))
    }

    public updateUser(value: User | null) {
        userProfileStore.update(setProp('user', value))
    }

    public updateCustomersConfig(value: CustomersConfig) {
        customersConfigStore.update(setProp('customersConfig', value))
    }

    public getAvailableCustomers() {
        return availableCustomersStore.getValue().availableCustomers;
    }

    public getCustomersConfig() {
        return customersConfigStore.getValue().customersConfig;
    }

    /**
     * The function `applyStoredStates` applies stored states to current app stores for a specific
     * customer.
     * @param {string} customerId - The `customerId` parameter is a string that represents the unique
     * identifier of a customer.
     * @returns If `configuredState` is falsy (null, undefined, or false), then nothing is being returned.
     */
    public applyStoredStates(customerId: string) {
        // states stored for customer
        const configuredState = storedStatesStore.getValue().entities[customerId];

        if (!configuredState || !configuredState.stores) return

        // update current stores with stored states
        Object.keys(configuredState.stores).forEach((key) => {
            const storedValues = configuredState.stores[key];
            const match = getStore(key);
            match?.update(
                setProps((state) => storedValues)
            );
        });

        console.log(`%cRestored customizations for "${customerId}" from last session`, 'color: #027B75;');
    }

    public storedStates$ = storedStatesStore.pipe(
        selectAllEntities()
    )

    /**
     * The function resets all stores except for the ones specified in the "except" parameter, with a
     * special handling for the "customerState" store.
     * @param {string[]} except - The `except` parameter is an optional array of strings. It specifies
     * the names of the stores that should not be reset. If a store's name is included in the `except`
     * array, it will not be reset.
     * @returns a Promise that resolves to a boolean value of `true`.
     */
    public async resetStores(except: string[] = []) {
        // always persist storedStores repo
        except.push('storedStates')
        return new Promise((resolve) => {
            const stores = getRegistry();
            // make sure customer is deleted first, then all other stores
            // this prevents unwanted fetches while switching customers (as cache services try to react to the now missing data)
            // once the customer state is removed, no other calls will be completed
            const customerStateStore = stores.get('customerState')
            // this.updateSelectedCustomer(null);
            customerStateStore?.reset();
            stores.delete('customerState');
            stores.forEach(store => {
                if (!except.includes(store.name)) store.reset()
            })

            resolve(true)
        })

    }
}
