/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { User } from '../../models/user';

export interface SetCurrentUsersLanguage$Params {
      body: {

/**
 * Preferred language of the user e.g. "en" or "de".
 */
'language'?: 'en' | 'de';
}
}

export function setCurrentUsersLanguage(http: HttpClient, rootUrl: string, params: SetCurrentUsersLanguage$Params, context?: HttpContext): Observable<StrictHttpResponse<User>> {
  const rb = new RequestBuilder(rootUrl, setCurrentUsersLanguage.PATH, 'patch');
  if (params) {
    rb.body(params.body, 'application/json');
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<User>;
    })
  );
}

setCurrentUsersLanguage.PATH = '/users/setLanguage/';
