import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
    providedIn: 'root'
})
export class ErrorService {
    constructor(
        private _translate: TranslateService
    ) { }

    getClientErrorMessage(error: Error): string {
        if (!navigator.onLine) {
            return this._translate.instant('APP_ERRORS.NO_INTERNET_CONNECTION');
        }
        return error.message ? error.message : error.toString();
    }

    getClientErrorStackTrace(error: Error): string {
        let stackTrace: string;
        if (error.stack == undefined) {
            stackTrace = "-"
        } else {
            stackTrace = error.stack as string
        }
        return stackTrace;
    }

    getServerErrorMessage(error: HttpErrorResponse): string {
        return error.message;
    }

    getServerErrorStackTrace(error: HttpErrorResponse): string {
        // handle stack trace
        return 'stack';
    }
}
