import { Environment } from "./environment.interface";
import config from "../assets/json/config.json";

export const environment: Environment = {
    production: true,
    dataBaseUrl: `${window.location.origin}/data/`,
    dataPath: "/api/v1",
    authUrl: `${window.location.origin}/auth/api/v1`,
    accessTokenRefreshInterval: 10 * 60 * 1000,
    appId: config.appId,
    loginBaseUrl: config.ciamLoginBaseUrl,
    clientId: config.ciamClientId,
    portalUrl: config.portalUrl,
    brandName: config.brandName,
    mapboxToken: config.mapboxToken,
    appUpdateCheckInterval: config.appUpdateCheckInterval,
    userGuideUrl: config.userGuideUrl,
    supportMail: config.supportMail,
    availableLanguagesList: config.availableLanguagesList
};
