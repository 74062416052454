import { AUTO_STYLE, animate, style, transition, trigger } from '@angular/animations';
import { ChangeDetectionStrategy, Component, computed, input, Input, model, Signal, signal } from '@angular/core';

export type StandaloneEventSelectOption = {
    id: string
    icon: string
    title: string
};

export type GroupEventSelectOption = StandaloneEventSelectOption & {
    children?: EventSelectOption[]
    expandDefault?: boolean // expand button is hidden and group is expanded if true
};

export type EventSelectOption = StandaloneEventSelectOption | GroupEventSelectOption;

// internal type for state management
type InternalEventSelectOption = EventSelectOption & {
    _checked?: boolean
    _indeterminate?: boolean
    _expanded?: boolean
};

@Component({
    selector: 'evc-plot-event-selection',
    template: `
        <div class="event-wrapper">
            <app-dropdown-button
                [desc]="title"
                size="small"
                type="clean"
                bodyClass="reduced-width"
                [maxBodyHeight]="400"
                [chevron]="true"
                [heightFollowsChild]="true"
                (onClose)="openState.set(false)"
                (onEnter)="openState.set(false)"
                (onOpen)="openState.set(true)"
            >
                <ng-container body>
                    <div class="selection-wrapper" #wrapper>
                        @for (event of internalEvents(); track event.id ) {
                            <ng-container *ngTemplateOutlet="selectRow; context: {$implicit: event}"></ng-container>
                        }
                    </div>
                </ng-container>
            </app-dropdown-button>
        </div>
    
        <ng-template #selectRow let-event>
            <div class="flex-row align-items-center justify-content-start">
                @if (event.children && !event.expandDefault) {
                    <button 
                        class="expand-children"
                        [class.open]="event._expanded"
                        (click)="toggleExpandedGroup(event.id)"
                    ></button>
                }
                <label 
                    class="flex-row align-items-center justify-content-start"
                    [class.has-children]="event.children"
                    [class.additional-margin]="event.expandDefault"
                    [class.reduced-margin]="allGroupsExpandedDefault()"
                >
                    <input 
                        type="checkbox"
                        [indeterminate]="event._indeterminate"
                        [name]="event.id"
                        [checked]="event._checked"
                        (change)="toggleSelection(event)"
                    >
                    <span class="material-icon">{{ event.icon }}</span>
                    <span class="title">{{ event.title }}</span>
                </label>
            </div>
            @if (event._expanded && openState() == true) {
                <div 
                    [@expandHeight]
                    class="select-children"
                >
                    @for (child of event.children; track child.id) {
                        <ng-container *ngTemplateOutlet="selectRow; context: {$implicit: child}"></ng-container>
                    }
                </div>
            }
        </ng-template>
    `,
    styleUrls: ['./plot-event-selection.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [
        trigger('expandHeight', [
            transition(':enter', [
                style({ height: 0 }),
                animate('.25s ease-out', 
                style({ height: AUTO_STYLE }))
            ]),
            transition(':leave', [
                style({ height: AUTO_STYLE }),
                animate('.25s ease-out', 
                style({ height: 0 }))
            ])
        ])
    ]
})
export class PlotEventSelectionComponent {
    @Input() title: string = 'Events';
    // Input and Output of selected IDs
    public selectedEvents = model<EventSelectOption['id'][] | null>([]);
    // Events provided by parent
    public events = input<EventSelectOption[] | null>([]);
    // Internal Events with additional state properties
    public internalEvents: Signal<InternalEventSelectOption[]>;
    // ids of parent EventSelectOptions whose children are expanded
    // combines initial settings with component state
    public expandedGroups: Signal<string[]>;
    // used to track only internal updates of expanded groups
    private _internalExpandedGroups = signal<string[]>([]);
    // keep track of dropdown state to update state animation accordingly on re-open (little changeDetection workaround)
    public openState = signal<boolean>(false);
    // flag to determine if all provided GroupEventSelectOptions are expanded by default
    // this flag helps with padding and margin adjustments - if all groups are expanded by default, the left padding is reduced
    public allGroupsExpandedDefault: Signal<boolean>;
    
    constructor() {
        // get all IDs of events that are flagged with "expandDefault"
        const defaultExpandedGroups = computed(() => {
            const events = this.events();
            if (!events) return [];
            return events.reduce((acc, event) => {
                if (this._isGroup(event) && event.expandDefault) acc.push(event.id);
                return acc;
            }, [] as string[]);
        });

        // combine defaultExpandedGroups with internal state
        this.expandedGroups = computed(() => {
            const internalExpandedGroups = this._internalExpandedGroups();
            const defaultGroups = defaultExpandedGroups();
            return Array.from(new Set([...internalExpandedGroups, ...defaultGroups]));
        })

        // true if all provided GroupEventSelectOptions contain the flag "expandDefault"
        this.allGroupsExpandedDefault = computed(() => {
            const events = this.events();
            if (!events) return false;
            return events.filter((event) => this._isGroup(event)).every((event: GroupEventSelectOption) => event.expandDefault);
        })

        // map events with internal state flags
        this.internalEvents = computed(() => {
            let events = structuredClone(this.events());
            if (!events) return [];
            const selectedIDs = this.selectedEvents();
            const expandedGroups = this.expandedGroups();
            events = events.map((event) => {
                const checked = this._isChecked(event, selectedIDs);
                const indeterminate = this._isGroup(event) ? this._isIndeterminate(event, selectedIDs) : false;
                const expanded = expandedGroups.includes(event.id);
                const children = this._isGroup(event) && event.children ? event.children.map((child) => {
                    const checked = this._isChecked(child, selectedIDs);
                    return { ...child, _checked: checked }                    
                }) : undefined;
                return { ...event, children, _checked: checked, _indeterminate: indeterminate, _expanded: expanded };
            })

            return events
        })
    }

    private _isGroup(input: EventSelectOption): input is GroupEventSelectOption {
        return input.hasOwnProperty('children');
    }

    public toggleExpandedGroup(id: EventSelectOption['id']) {
        const values = this.expandedGroups();
        const index = values.indexOf(id);
        index > -1 ? values.splice(index, 1) : values.push(id);
        this._internalExpandedGroups.set(values);
    }

    // returns ids of matching selections of child options
    private _getMatches(event: EventSelectOption, selectedIDs: EventSelectOption['id'][] | null): string[] {
        if (!this._isGroup(event) || !event.children || !selectedIDs) return [];
        const matches: string[] = [];
        event.children.forEach((child) => {
            if (selectedIDs.includes(child.id)) matches.push(child.id)
        });
        return matches
    }

    public _isIndeterminate(event: EventSelectOption, selectedIDs: EventSelectOption['id'][] | null): boolean {
        if (!this._isGroup(event) || !event.children || !selectedIDs) return false
        const matches = this._getMatches(event, selectedIDs);
        return matches.length > 0 && matches.length < event.children.length
    }

    private _isChecked(event: EventSelectOption, selectedIDs: EventSelectOption['id'][] | null): boolean {
        if (!selectedIDs) return false
        if (!this._isGroup(event) || !event.children) return selectedIDs.includes(event.id)
        const matches = this._getMatches(event, selectedIDs);
        return matches.length == event.children.length
    }

    public toggleSelection(event: InternalEventSelectOption) {
        let selectedIDs = structuredClone(this.selectedEvents()) ?? [];
        const isChecked = this._isChecked(event, selectedIDs);

        if (isChecked) {
            if (this._isGroup(event) && event.children) {
                // remove all children selection of Group, as we don't track the groupID itself but derive its state from the children selection
                const childrenIDs = event.children.map((child) => child.id);
                selectedIDs = selectedIDs.filter(id => !childrenIDs.includes(id));
            } else {
                // remove eventID from selectedIDs
                selectedIDs = selectedIDs.filter((id) => id !== event.id)
            }
        } else {
            if (this._isGroup(event) && event.children) {
                // add each child ID to selection if it's not currently selected
                event.children.forEach((child) => {
                    !selectedIDs.includes(child.id) && selectedIDs.push(child.id)
                })
            } else {
                // add event ID to selection
                !selectedIDs.includes(event.id) && selectedIDs.push(event.id)
            }
        }

        this.selectedEvents.set(selectedIDs);
    }
}
