import { Injectable } from "@angular/core";
import { OAuthService } from "angular-oauth2-oidc";
import { authCodeFlowConfig } from "../../auth.config";
import { environment } from "src/environments/environment";
import { appRepository } from "../stores/app.repository";
import {
    distinctUntilChanged,
    map,
    shareReplay,
    startWith,
} from "rxjs";
import { formatDate } from "@angular/common";
import { addSeconds } from "date-fns";

@Injectable({
    providedIn: 'root'
})
export class AppAuthService {
    public isLoggedIn$ = this._oAuthService.events.pipe(
        startWith(undefined),
        map(() => this._oAuthService.hasValidAccessToken()),
        distinctUntilChanged(),
        shareReplay(1)
    );

    constructor(
        private _oAuthService: OAuthService,
        private _appRepo: appRepository
    ) {
        this._configure();
    }

    private _configure() {
        this._oAuthService.configure(authCodeFlowConfig);
        this._oAuthService.setupAutomaticSilentRefresh({setScope: false});
        this.login();
    }
    
    /**
     * The `_logout` function removes selected customer data, clears session storage, resets stored
     * data, logs out the user, and redirects them to a specified URL.
     * @param {boolean} [reLoginViaPortal=false] - reLoginViaPortal is a boolean parameter that
     * determines whether the user should be redirected to the login portal after logging out. If
     * reLoginViaPortal is true, the user will be redirected to the portal URL specified in the
     * environment variable. If reLoginViaPortal is false, the user will be redirected to the constructed
     * redirect URL.
     */
    private _logout(reLoginViaPortal: boolean = false) {
        return logout(this._oAuthService, this._appRepo, reLoginViaPortal);
    }

    /** starts the login flow of chained request */
    public login() {
        return this._oAuthService.loadDiscoveryDocument()
            .then(() => {
                return this._oAuthService.tryLogin({disableNonceCheck: true})
            })
            .then((hasReceivedTokens) => {
                // if we have valid tokens, we are done
                if (this._oAuthService.hasValidIdToken() && this._oAuthService.hasValidAccessToken()) return
                // else check if tryLogin returned tokens
                if (hasReceivedTokens) {
                    // if we have received tokens, we try to refresh the token
                    this.tryRefresh();
                } else {
                    // if we have not received tokens, we start the code flow
                    this._oAuthService.initCodeFlow();
                }
            })
    }
    
    /**
     * The function `tryRefresh` attempts to refresh the authentication token, logs out if the token is
     * not valid, and returns the new authentication token if the refresh is successful.
     * @returns `tryRefresh()` returns a Promise that resolves to the new auth token
     */
    public tryRefresh(): Promise<string> {
        return tryRefresh(this._oAuthService, this._appRepo);
    }
    
    public logout() {
        this._logout();
    }

    public switchCustomer() {
        // resets all data stored in session and local storage
        this._appRepo.updateSelectedCustomer(null);
        this._appRepo.resetStores(['availableCustomers', 'customersConfig']);
        // make sure to not redirect to old url after customer switch
        sessionStorage.removeItem('postLoginURL');
    }
}

/**
 * The `logout` function removes selected customer data, clears session storage, resets stored
 * data, logs out the user, and redirects them to a specified URL.
 * @param {boolean} [reLoginViaPortal=false] - reLoginViaPortal is a boolean parameter that
 * determines whether the user should be redirected to the login portal after logging out. If
 * reLoginViaPortal is true, the user will be redirected to the portal URL specified in the
 * environment variable. If reLoginViaPortal is false, the user will be redirected to the constructed
 * redirect URL.
 */
export function logout(oAuthService: OAuthService, appRepo: appRepository, reLoginViaPortal: boolean = false) {
    // reset post login url if it contains a details route
    // we can't really delete it here as it may be overwritten again through the logout process
    const currentPostLoginURL = sessionStorage.getItem('postLoginURL');
    if (currentPostLoginURL && currentPostLoginURL.includes('/details')) {
        sessionStorage.setItem('postLoginURL', '/overview');
    }
    appRepo.updateSelectedCustomer(null);
    // delete all stored data in session and local storage
    appRepo.resetStores().then(() => {
        oAuthService.logOut(true);
        if (reLoginViaPortal) {
            window.location.href = environment.portalUrl;
        } else {
            window.location.href = `${environment.loginBaseUrl}/secur/logout.jsp?retUrl=/vforcesite/frontgate%3Fexpid%3DA${environment.appId}L01%26returnUrl%3D${environment.portalUrl}`;
        }
    });
}


/**
* The function `tryRefresh` attempts to refresh the authentication token, logs out if the token is
* not valid, and returns the new authentication token if the refresh is successful.
* @returns `tryRefresh()` returns a Promise that resolves to the new auth token
*/
export function tryRefresh(oAuthService: OAuthService, appRepo: appRepository): Promise<string> {
    return oAuthService.refreshToken({ setScope: false })
       .catch((error) => {
           logout(oAuthService, appRepo);
           return Promise.reject(error);
       })
       .then((e) => {
           console.log(`%csession restored at ${formatDate(new Date(), 'yyyy-MM-dd HH:mm:ss', 'en')}`, 'color: green; font-weight: bold;');
           // return new auth token
           return oAuthService.getAccessToken();
       })
}
