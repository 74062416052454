/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { DataBaseService } from '../data-base-service';
import { DataApiConfiguration } from '../data-api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { GenericOcppEvents } from '../models/generic-ocpp-events';
import { LoadBalancingPeriod } from '../models/load-balancing-period';
import { OcppAuthorization } from '../models/ocpp-authorization';
import { OcppStatusNotification } from '../models/ocpp-status-notification';


/**
 * Analysis Tab for the restart History of a Charging Station
 */
@Injectable({
  providedIn: 'root',
})
export class DetailsTimelineService extends DataBaseService {
  constructor(
    config: DataApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getAuthorizationsOfChargingStation
   */
  static readonly GetAuthorizationsOfChargingStationPath = '/chargingStations/{stationId}/ocppAuthorizations/';

  /**
   * Gets authorization events of a charging station.
   *
   * Fetch authorizations of a specific charging station
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAuthorizationsOfChargingStation()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAuthorizationsOfChargingStation$Response(params: {

    /**
     * Unique identifier of the Charging Station
     */
    stationId: string;

    /**
     * The time up to which the list of authorizations should be displayed
     */
    date?: string;

    /**
     * The period in days before &#x27;date&#x27; from which all authorizations should be returned
     */
    interval?: number;
  }): Observable<StrictHttpResponse<Array<OcppAuthorization>>> {

    const rb = new RequestBuilder(this.rootUrl, DetailsTimelineService.GetAuthorizationsOfChargingStationPath, 'get');
    if (params) {
      rb.path('stationId', params.stationId, {});
      rb.query('date', params.date, {});
      rb.query('interval', params.interval, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<OcppAuthorization>>;
      })
    );
  }

  /**
   * Gets authorization events of a charging station.
   *
   * Fetch authorizations of a specific charging station
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAuthorizationsOfChargingStation$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAuthorizationsOfChargingStation(params: {

    /**
     * Unique identifier of the Charging Station
     */
    stationId: string;

    /**
     * The time up to which the list of authorizations should be displayed
     */
    date?: string;

    /**
     * The period in days before &#x27;date&#x27; from which all authorizations should be returned
     */
    interval?: number;
  }): Observable<Array<OcppAuthorization>> {

    return this.getAuthorizationsOfChargingStation$Response(params).pipe(
      map((r: StrictHttpResponse<Array<OcppAuthorization>>) => r.body as Array<OcppAuthorization>)
    );
  }

  /**
   * Path part for operation getStatusNotificationsOfChargingStation
   */
  static readonly GetStatusNotificationsOfChargingStationPath = '/chargingStations/{stationId}/ocppStatusNotifications/';

  /**
   * Gets status notification events of a charging station.
   *
   * Fetch status notification of a specific charging station
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getStatusNotificationsOfChargingStation()` instead.
   *
   * This method doesn't expect any request body.
   */
  getStatusNotificationsOfChargingStation$Response(params: {

    /**
     * Unique identifier of the Charging Station
     */
    stationId: string;

    /**
     * The time up to which the list of status notifications should be displayed
     */
    date?: string;

    /**
     * The period in days before &#x27;date&#x27; from which all status notifications should be returned
     */
    interval?: number;
  }): Observable<StrictHttpResponse<Array<OcppStatusNotification>>> {

    const rb = new RequestBuilder(this.rootUrl, DetailsTimelineService.GetStatusNotificationsOfChargingStationPath, 'get');
    if (params) {
      rb.path('stationId', params.stationId, {});
      rb.query('date', params.date, {});
      rb.query('interval', params.interval, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<OcppStatusNotification>>;
      })
    );
  }

  /**
   * Gets status notification events of a charging station.
   *
   * Fetch status notification of a specific charging station
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getStatusNotificationsOfChargingStation$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getStatusNotificationsOfChargingStation(params: {

    /**
     * Unique identifier of the Charging Station
     */
    stationId: string;

    /**
     * The time up to which the list of status notifications should be displayed
     */
    date?: string;

    /**
     * The period in days before &#x27;date&#x27; from which all status notifications should be returned
     */
    interval?: number;
  }): Observable<Array<OcppStatusNotification>> {

    return this.getStatusNotificationsOfChargingStation$Response(params).pipe(
      map((r: StrictHttpResponse<Array<OcppStatusNotification>>) => r.body as Array<OcppStatusNotification>)
    );
  }

  /**
   * Path part for operation getLoadBalancingOfChargingStation
   */
  static readonly GetLoadBalancingOfChargingStationPath = '/chargingStations/{stationId}/loadBalancingPeriods/';

  /**
   * Gets load balancing events of a charging station.
   *
   * Fetch load balancing information of a specific charging station
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getLoadBalancingOfChargingStation()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLoadBalancingOfChargingStation$Response(params: {

    /**
     * Unique identifier of the Charging Station
     */
    stationId: string;

    /**
     * The time up to which the load balancing events should be displayed
     */
    date?: string;

    /**
     * The period in days before &#x27;date&#x27; from which all load balancing events should be returned
     */
    interval?: number;
  }): Observable<StrictHttpResponse<Array<LoadBalancingPeriod>>> {

    const rb = new RequestBuilder(this.rootUrl, DetailsTimelineService.GetLoadBalancingOfChargingStationPath, 'get');
    if (params) {
      rb.path('stationId', params.stationId, {});
      rb.query('date', params.date, {});
      rb.query('interval', params.interval, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<LoadBalancingPeriod>>;
      })
    );
  }

  /**
   * Gets load balancing events of a charging station.
   *
   * Fetch load balancing information of a specific charging station
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getLoadBalancingOfChargingStation$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLoadBalancingOfChargingStation(params: {

    /**
     * Unique identifier of the Charging Station
     */
    stationId: string;

    /**
     * The time up to which the load balancing events should be displayed
     */
    date?: string;

    /**
     * The period in days before &#x27;date&#x27; from which all load balancing events should be returned
     */
    interval?: number;
  }): Observable<Array<LoadBalancingPeriod>> {

    return this.getLoadBalancingOfChargingStation$Response(params).pipe(
      map((r: StrictHttpResponse<Array<LoadBalancingPeriod>>) => r.body as Array<LoadBalancingPeriod>)
    );
  }

  /**
   * Path part for operation getGenericOcppEventsOfChargingStation
   */
  static readonly GetGenericOcppEventsOfChargingStationPath = '/chargingStations/{stationId}/genericOcppEvents/';

  /**
   * Miscellaneous ocpp events of a charging station.
   *
   * Fetch ocpp events of a specific charging station that are not fetchable via other endpoints
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getGenericOcppEventsOfChargingStation()` instead.
   *
   * This method doesn't expect any request body.
   */
  getGenericOcppEventsOfChargingStation$Response(params: {

    /**
     * Unique identifier of the Charging Station
     */
    stationId: string;

    /**
     * The time up to which the list of ocpp events should be displayed
     */
    date?: string;

    /**
     * The period in days before &#x27;date&#x27; from which all ocpp events should be returned
     */
    interval?: number;
  }): Observable<StrictHttpResponse<Array<GenericOcppEvents>>> {

    const rb = new RequestBuilder(this.rootUrl, DetailsTimelineService.GetGenericOcppEventsOfChargingStationPath, 'get');
    if (params) {
      rb.path('stationId', params.stationId, {});
      rb.query('date', params.date, {});
      rb.query('interval', params.interval, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<GenericOcppEvents>>;
      })
    );
  }

  /**
   * Miscellaneous ocpp events of a charging station.
   *
   * Fetch ocpp events of a specific charging station that are not fetchable via other endpoints
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getGenericOcppEventsOfChargingStation$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getGenericOcppEventsOfChargingStation(params: {

    /**
     * Unique identifier of the Charging Station
     */
    stationId: string;

    /**
     * The time up to which the list of ocpp events should be displayed
     */
    date?: string;

    /**
     * The period in days before &#x27;date&#x27; from which all ocpp events should be returned
     */
    interval?: number;
  }): Observable<Array<GenericOcppEvents>> {

    return this.getGenericOcppEventsOfChargingStation$Response(params).pipe(
      map((r: StrictHttpResponse<Array<GenericOcppEvents>>) => r.body as Array<GenericOcppEvents>)
    );
  }

}
