import { AuthConfig } from "angular-oauth2-oidc";
import { environment } from "src/environments/environment";

export const authCodeFlowConfig: AuthConfig = {
    clientId: environment.clientId,

    // Url of the Identity Provider
    issuer: environment.loginBaseUrl,

    // URL of the SPA to redirect the user to after login
    redirectUri: window.location.origin,
    responseType: "code",

    // set the scope for the permissions the client should request
    // The first four are defined by OIDC.
    // Important: Request offline_access to get a refresh token
    // The api scope is a usecase specific one
    scope: "openid refresh_token",

    showDebugInformation: false,

    // configures receiving events of terminated sessions
    // which we can act upon (e.g. delete all session data)
    // sessionChecksEnabled: true

    fallbackAccessTokenExpirationTimeInSec: 10 * 60,
};
