import { Directive, Input, OnDestroy, TemplateRef, ViewContainerRef } from '@angular/core';
import { PermissionsService } from '../app-services/permissions.service';
import { BehaviorSubject, Subject, combineLatest, takeUntil, tap } from 'rxjs';
import { Paths, RolePermissions } from 'src/assets/files/roles_access/roles-permissions.types';

type StateModel = 'lastOverallState' | 'lastHeartbeatState' | 'lastChargingModelState' | 'lastErrorState' | 'lastHealthIndexValue';
@Directive({
    selector: '[evcHasPermissions]',
    standalone: false
})
export class HasPermissionsDirective<T> implements OnDestroy {
    private readonly _destroying$ = new Subject<void>();
    private _hasView: boolean = false;
    private _hasElseView: boolean = false;
    private _accessPath$ = new BehaviorSubject<Paths<RolePermissions> | null>(null);
    private _accessStateModel$ = new BehaviorSubject<StateModel | null>(null);
    @Input() set evcHasPermissions(pathOrStateModel: Paths<RolePermissions> | StateModel) {
        if (this._isPermissionPath(pathOrStateModel)) {
            this._accessPath$.next(pathOrStateModel)
        } else {
            this._accessStateModel$.next(pathOrStateModel)
        }
    }
    private _elseTemplateRef$ = new BehaviorSubject<TemplateRef<any> | null>(null);
    // optional template ref to be rendered if user doesnt have the requested permissions
    @Input() set evcHasPermissionsElse(ref: undefined | TemplateRef<any>) {
        this._elseTemplateRef$.next(ref ?? null)
    }

    constructor(
        private _templateRef: TemplateRef<any>,
        private _viewContainerRef: ViewContainerRef,
        private _permissionsService: PermissionsService
    ) {
        combineLatest({
            requestedAccess: this._accessPath$,
            requestedStateModel: this._accessStateModel$,
            permissions: this._permissionsService.userPermissions$,
            elseTemplateRef: this._elseTemplateRef$
        }).pipe(
            takeUntil(this._destroying$),
            tap(({requestedAccess, requestedStateModel, permissions, elseTemplateRef}) => {
                const canAccess = requestedAccess 
                    ? this._permissionsService.checkPermissions(requestedAccess, permissions) 
                    : requestedStateModel 
                        ? this._permissionsService.hasStateModel(requestedStateModel)
                        : false;

                this._handleEmbeddedView(canAccess, elseTemplateRef)
            })
        ).subscribe()

        combineLatest({
            requestedStateModel: this._accessStateModel$,
            permissions: this._permissionsService.userPermissions$
        }).pipe(
            takeUntil(this._destroying$),
        ).subscribe()
    }

    /**
     * The function checks if a given input is a valid user permissions path.
     * @param {Paths<RolePermissions> | StateModel} pathOrStateModel - The parameter `pathOrStateModel`
     * can be either of type `Paths<RolePermissions>` or `StateModel`.
     * @returns a boolean value.
     */
    private _isPermissionPath(pathOrStateModel: Paths<RolePermissions> | StateModel): pathOrStateModel is Paths<RolePermissions> {
        return !['lastOverallState', 'lastHeartbeatState', 'lastChargingModelState', 'lastErrorState', 'lastHealthIndexValue'].includes(pathOrStateModel as string)
    }

    /**
     * The function `_handleEmbeddedView` creates or clears an embedded view based on the value of
     * `hasAccess`.
     * @param {boolean} hasAccess - A boolean value indicating whether the user has access or not.
     */
    private _handleEmbeddedView(hasAccess: boolean, elseTemplateRef: TemplateRef<any> | null) {
        if (hasAccess) {
            // clear else content
            if (this._hasElseView) {
                this._viewContainerRef.clear();
                this._hasElseView = false
            }
            // create correct view
            if (!this._hasView) {
                this._viewContainerRef.createEmbeddedView(this._templateRef);
                this._hasView = true;
            }
        } else {
            // clear correct view
            if (this._hasView) {
                this._viewContainerRef.clear();
                this._hasView = false;
            }
            // embed placeholder content (elseTemplateRef)
            if (!this._hasElseView && elseTemplateRef) {
                this._viewContainerRef.createEmbeddedView(elseTemplateRef);
                this._hasElseView = true
            }
        }
    }

    ngOnDestroy(): void {
        this._destroying$.next(undefined);
        this._destroying$.complete();
    }

    static ngTemplateGuard_evcHasPermissions<T>(
        dir: HasPermissionsDirective<T>,
        state: Paths<RolePermissions> | StateModel,
    ): state is Paths<RolePermissions> | StateModel {
        return true;
    }
}
