/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { DataBaseService } from '../data-base-service';
import { DataApiConfiguration } from '../data-api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ApiResponse } from '../models/api-response';
import { ChargingSession } from '../models/charging-session';
import { ChargingStation } from '../models/charging-station';
import { Error } from '../models/error';
import { HealthIndexValue } from '../models/health-index-value';
import { OcppMessageAggregation } from '../models/ocpp-message-aggregation';
import { OcppMessageEvent } from '../models/ocpp-message-event';
import { OcppConfiguration } from '../models/ocpp-configuration';
import { OverallStatus } from '../models/overall-status';
import { ResetResponse } from '../models/reset-response';


/**
 * Overview Tab for a Charging Station
 */
@Injectable({
  providedIn: 'root',
})
export class DetailsOverviewService extends DataBaseService {
  constructor(
    config: DataApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation resetChargingStations
   */
  static readonly ResetChargingStationsPath = '/chargingStations/reset/';

  /**
   * Resets a list of charging stations.
   *
   * Send a OCPP reset command to a list of charging stations
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `resetChargingStations()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  resetChargingStations$Response(params: {
    body: {

/**
 * Unique identifiers of the Charging Stations
 */
'stationIds'?: Array<string>;
}
  }): Observable<StrictHttpResponse<ResetResponse>> {

    const rb = new RequestBuilder(this.rootUrl, DetailsOverviewService.ResetChargingStationsPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ResetResponse>;
      })
    );
  }

  /**
   * Resets a list of charging stations.
   *
   * Send a OCPP reset command to a list of charging stations
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `resetChargingStations$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  resetChargingStations(params: {
    body: {

/**
 * Unique identifiers of the Charging Stations
 */
'stationIds'?: Array<string>;
}
  }): Observable<ResetResponse> {

    return this.resetChargingStations$Response(params).pipe(
      map((r: StrictHttpResponse<ResetResponse>) => r.body as ResetResponse)
    );
  }

  /**
   * Path part for operation changeVisibilityOfChargingStations
   */
  static readonly ChangeVisibilityOfChargingStationsPath = '/chargingStations/changeVisibility/';

  /**
   * Changes the visibility of a list of charging stations.
   *
   * The list of charging stations will be shown or hidden for the current user
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `changeVisibilityOfChargingStations()` instead.
   *
   * This method doesn't expect any request body.
   */
  changeVisibilityOfChargingStations$Response(params: {

    /**
     * Unique identifier of the Charging Station
     */
    stationIds: Array<string>;

    /**
     * New visibility status of the specific stations
     */
    visibility?: 'show' | 'hide';

    /**
     * For how many days the stations should be hidden, default 14
     */
    interval?: number;
  }): Observable<StrictHttpResponse<ApiResponse>> {

    const rb = new RequestBuilder(this.rootUrl, DetailsOverviewService.ChangeVisibilityOfChargingStationsPath, 'patch');
    if (params) {
      rb.query('stationIds', params.stationIds, {});
      rb.query('visibility', params.visibility, {});
      rb.query('interval', params.interval, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ApiResponse>;
      })
    );
  }

  /**
   * Changes the visibility of a list of charging stations.
   *
   * The list of charging stations will be shown or hidden for the current user
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `changeVisibilityOfChargingStations$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  changeVisibilityOfChargingStations(params: {

    /**
     * Unique identifier of the Charging Station
     */
    stationIds: Array<string>;

    /**
     * New visibility status of the specific stations
     */
    visibility?: 'show' | 'hide';

    /**
     * For how many days the stations should be hidden, default 14
     */
    interval?: number;
  }): Observable<ApiResponse> {

    return this.changeVisibilityOfChargingStations$Response(params).pipe(
      map((r: StrictHttpResponse<ApiResponse>) => r.body as ApiResponse)
    );
  }

  /**
   * Path part for operation getChargingStation
   */
  static readonly GetChargingStationPath = '/chargingStations/{stationId}/';

  /**
   * Gets a single charging station.
   *
   * Fetch a single charging stations, optionally for a specific date
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getChargingStation()` instead.
   *
   * This method doesn't expect any request body.
   */
  getChargingStation$Response(params: {

    /**
     * Unique identifier of the Charging Station
     */
    stationId: string;

    /**
     * Time at which state the charging station should be returned
     */
    date?: string;
  }): Observable<StrictHttpResponse<ChargingStation>> {

    const rb = new RequestBuilder(this.rootUrl, DetailsOverviewService.GetChargingStationPath, 'get');
    if (params) {
      rb.path('stationId', params.stationId, {});
      rb.query('date', params.date, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ChargingStation>;
      })
    );
  }

  /**
   * Gets a single charging station.
   *
   * Fetch a single charging stations, optionally for a specific date
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getChargingStation$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getChargingStation(params: {

    /**
     * Unique identifier of the Charging Station
     */
    stationId: string;

    /**
     * Time at which state the charging station should be returned
     */
    date?: string;
  }): Observable<ChargingStation> {

    return this.getChargingStation$Response(params).pipe(
      map((r: StrictHttpResponse<ChargingStation>) => r.body as ChargingStation)
    );
  }

  /**
   * Path part for operation getHealthIndexValuesOfChargingStation
   */
  static readonly GetHealthIndexValuesOfChargingStationPath = '/chargingStations/{stationId}/healthIndexValues/';

  /**
   * Gets health index values of charging station.
   *
   * Fetch the health index values of a specific charging station, optionally for a specific date
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getHealthIndexValuesOfChargingStation()` instead.
   *
   * This method doesn't expect any request body.
   */
  getHealthIndexValuesOfChargingStation$Response(params: {

    /**
     * Unique identifier of the Charging Station
     */
    stationId: string;

    /**
     * Time at which the health index values of a specific charging station should be returned
     */
    date?: string;

    /**
     * The period in days before &#x27;date&#x27; from which all health index values should be returned
     */
    interval?: number;
  }): Observable<StrictHttpResponse<Array<HealthIndexValue>>> {

    const rb = new RequestBuilder(this.rootUrl, DetailsOverviewService.GetHealthIndexValuesOfChargingStationPath, 'get');
    if (params) {
      rb.path('stationId', params.stationId, {});
      rb.query('date', params.date, {});
      rb.query('interval', params.interval, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<HealthIndexValue>>;
      })
    );
  }

  /**
   * Gets health index values of charging station.
   *
   * Fetch the health index values of a specific charging station, optionally for a specific date
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getHealthIndexValuesOfChargingStation$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getHealthIndexValuesOfChargingStation(params: {

    /**
     * Unique identifier of the Charging Station
     */
    stationId: string;

    /**
     * Time at which the health index values of a specific charging station should be returned
     */
    date?: string;

    /**
     * The period in days before &#x27;date&#x27; from which all health index values should be returned
     */
    interval?: number;
  }): Observable<Array<HealthIndexValue>> {

    return this.getHealthIndexValuesOfChargingStation$Response(params).pipe(
      map((r: StrictHttpResponse<Array<HealthIndexValue>>) => r.body as Array<HealthIndexValue>)
    );
  }

  /**
   * Path part for operation getAggregatedOcppMessagesOfChargingStation
   */
  static readonly GetAggregatedOcppMessagesOfChargingStationPath = '/chargingStations/{stationId}/ocppMessageAggregations/';

  /**
   * Gets aggregated messages of charging station.
   *
   * Fetch the aggregated messages of a specific charging station, optionally for a specific time interval
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAggregatedOcppMessagesOfChargingStation()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAggregatedOcppMessagesOfChargingStation$Response(params: {

    /**
     * Unique identifier of the Charging Station
     */
    stationId: string;

    /**
     * The time up to which the list of aggregated messages should be displayed
     */
    date?: string;

    /**
     * The period in days before &#x27;date&#x27; from which all message aggregations should be returned
     */
    interval?: number;
  }): Observable<StrictHttpResponse<Array<OcppMessageAggregation>>> {

    const rb = new RequestBuilder(this.rootUrl, DetailsOverviewService.GetAggregatedOcppMessagesOfChargingStationPath, 'get');
    if (params) {
      rb.path('stationId', params.stationId, {});
      rb.query('date', params.date, {});
      rb.query('interval', params.interval, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<OcppMessageAggregation>>;
      })
    );
  }

  /**
   * Gets aggregated messages of charging station.
   *
   * Fetch the aggregated messages of a specific charging station, optionally for a specific time interval
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAggregatedOcppMessagesOfChargingStation$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAggregatedOcppMessagesOfChargingStation(params: {

    /**
     * Unique identifier of the Charging Station
     */
    stationId: string;

    /**
     * The time up to which the list of aggregated messages should be displayed
     */
    date?: string;

    /**
     * The period in days before &#x27;date&#x27; from which all message aggregations should be returned
     */
    interval?: number;
  }): Observable<Array<OcppMessageAggregation>> {

    return this.getAggregatedOcppMessagesOfChargingStation$Response(params).pipe(
      map((r: StrictHttpResponse<Array<OcppMessageAggregation>>) => r.body as Array<OcppMessageAggregation>)
    );
  }

  /**
   * Path part for operation getOcppMessagesTypesOfChargingStation
   */
  static readonly GetOcppMessagesTypesOfChargingStationPath = '/chargingStations/{stationId}/ocppMessageEvents/';

  /**
   * Gets OCPP message occurrences by type for a charging station.
   *
   * Fetch OCPP message occurrences. The response includes the type and time of occurrence for each message, optionally for a specific time interval
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getOcppMessagesTypesOfChargingStation()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOcppMessagesTypesOfChargingStation$Response(params: {

    /**
     * Unique identifier of the Charging Station
     */
    stationId: string;

    /**
     * The time up to which the list of aggregated messages should be displayed
     */
    date?: string;

    /**
     * The period in days before &#x27;date&#x27; from which all message aggregations should be returned
     */
    interval?: number;
  }): Observable<StrictHttpResponse<Array<OcppMessageEvent>>> {

    const rb = new RequestBuilder(this.rootUrl, DetailsOverviewService.GetOcppMessagesTypesOfChargingStationPath, 'get');
    if (params) {
      rb.path('stationId', params.stationId, {});
      rb.query('date', params.date, {});
      rb.query('interval', params.interval, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<OcppMessageEvent>>;
      })
    );
  }

  /**
   * Gets OCPP message occurrences by type for a charging station.
   *
   * Fetch OCPP message occurrences. The response includes the type and time of occurrence for each message, optionally for a specific time interval
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getOcppMessagesTypesOfChargingStation$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOcppMessagesTypesOfChargingStation(params: {

    /**
     * Unique identifier of the Charging Station
     */
    stationId: string;

    /**
     * The time up to which the list of aggregated messages should be displayed
     */
    date?: string;

    /**
     * The period in days before &#x27;date&#x27; from which all message aggregations should be returned
     */
    interval?: number;
  }): Observable<Array<OcppMessageEvent>> {

    return this.getOcppMessagesTypesOfChargingStation$Response(params).pipe(
      map((r: StrictHttpResponse<Array<OcppMessageEvent>>) => r.body as Array<OcppMessageEvent>)
    );
  }

  /**
   * Path part for operation getChargingSessionsOfChargingStation
   */
  static readonly GetChargingSessionsOfChargingStationPath = '/chargingStations/{stationId}/chargingSessions/';

  /**
   * Gets charging sessions of a charging station.
   *
   * Fetch the charging sessions of a specific charging station, optionally for a specific time interval
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getChargingSessionsOfChargingStation()` instead.
   *
   * This method doesn't expect any request body.
   */
  getChargingSessionsOfChargingStation$Response(params: {

    /**
     * Unique identifier of the Charging Station
     */
    stationId: string;

    /**
     * The time up to which the list of charging sessions should be displayed
     */
    date?: string;

    /**
     * The period in days before &#x27;date&#x27; from which all sessions should be returned
     */
    interval?: number;

    /**
     * Wether to return all attributes of the charging sessions or a specific subset
     */
    detailed?: boolean;
  }): Observable<StrictHttpResponse<Array<ChargingSession>>> {

    const rb = new RequestBuilder(this.rootUrl, DetailsOverviewService.GetChargingSessionsOfChargingStationPath, 'get');
    if (params) {
      rb.path('stationId', params.stationId, {});
      rb.query('date', params.date, {});
      rb.query('interval', params.interval, {});
      rb.query('detailed', params.detailed, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ChargingSession>>;
      })
    );
  }

  /**
   * Gets charging sessions of a charging station.
   *
   * Fetch the charging sessions of a specific charging station, optionally for a specific time interval
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getChargingSessionsOfChargingStation$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getChargingSessionsOfChargingStation(params: {

    /**
     * Unique identifier of the Charging Station
     */
    stationId: string;

    /**
     * The time up to which the list of charging sessions should be displayed
     */
    date?: string;

    /**
     * The period in days before &#x27;date&#x27; from which all sessions should be returned
     */
    interval?: number;

    /**
     * Wether to return all attributes of the charging sessions or a specific subset
     */
    detailed?: boolean;
  }): Observable<Array<ChargingSession>> {

    return this.getChargingSessionsOfChargingStation$Response(params).pipe(
      map((r: StrictHttpResponse<Array<ChargingSession>>) => r.body as Array<ChargingSession>)
    );
  }

  /**
   * Path part for operation getErrorsOfChargingStation
   */
  static readonly GetErrorsOfChargingStationPath = '/chargingStations/{stationId}/errors/';

  /**
   * Gets errors of a charging station.
   *
   * Fetch the errors of a specific charging station, optionally for a specific time interval
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getErrorsOfChargingStation()` instead.
   *
   * This method doesn't expect any request body.
   */
  getErrorsOfChargingStation$Response(params: {

    /**
     * Unique identifier of the Charging Station
     */
    stationId: string;

    /**
     * The time up to which the list of errors should be displayed
     */
    date?: string;

    /**
     * The period in days before &#x27;date&#x27; from which all sessions should be returned
     */
    interval?: number;
  }): Observable<StrictHttpResponse<Array<Error>>> {

    const rb = new RequestBuilder(this.rootUrl, DetailsOverviewService.GetErrorsOfChargingStationPath, 'get');
    if (params) {
      rb.path('stationId', params.stationId, {});
      rb.query('date', params.date, {});
      rb.query('interval', params.interval, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Error>>;
      })
    );
  }

  /**
   * Gets errors of a charging station.
   *
   * Fetch the errors of a specific charging station, optionally for a specific time interval
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getErrorsOfChargingStation$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getErrorsOfChargingStation(params: {

    /**
     * Unique identifier of the Charging Station
     */
    stationId: string;

    /**
     * The time up to which the list of errors should be displayed
     */
    date?: string;

    /**
     * The period in days before &#x27;date&#x27; from which all sessions should be returned
     */
    interval?: number;
  }): Observable<Array<Error>> {

    return this.getErrorsOfChargingStation$Response(params).pipe(
      map((r: StrictHttpResponse<Array<Error>>) => r.body as Array<Error>)
    );
  }

  /**
   * Path part for operation getOverallStatesOfChargingStation
   */
  static readonly GetOverallStatesOfChargingStationPath = '/chargingStations/{stationId}/overallStates/';

  /**
   * Gets overall state history of a charging station.
   *
   * Fetch the overall states of a specific charging station, optionally for a specific time interval
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getOverallStatesOfChargingStation()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOverallStatesOfChargingStation$Response(params: {

    /**
     * Unique identifier of the Charging Station
     */
    stationId: string;

    /**
     * The time up to which the list of overall states should be displayed
     */
    date?: string;

    /**
     * The period in days before &#x27;date&#x27; from which all overall states should be returned
     */
    interval?: number;

    /**
     * Whether to aggregate per day and return the worst result
     */
    aggregate?: boolean;
  }): Observable<StrictHttpResponse<Array<OverallStatus>>> {

    const rb = new RequestBuilder(this.rootUrl, DetailsOverviewService.GetOverallStatesOfChargingStationPath, 'get');
    if (params) {
      rb.path('stationId', params.stationId, {});
      rb.query('date', params.date, {});
      rb.query('interval', params.interval, {});
      rb.query('aggregate', params.aggregate, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<OverallStatus>>;
      })
    );
  }

  /**
   * Gets overall state history of a charging station.
   *
   * Fetch the overall states of a specific charging station, optionally for a specific time interval
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getOverallStatesOfChargingStation$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOverallStatesOfChargingStation(params: {

    /**
     * Unique identifier of the Charging Station
     */
    stationId: string;

    /**
     * The time up to which the list of overall states should be displayed
     */
    date?: string;

    /**
     * The period in days before &#x27;date&#x27; from which all overall states should be returned
     */
    interval?: number;

    /**
     * Whether to aggregate per day and return the worst result
     */
    aggregate?: boolean;
  }): Observable<Array<OverallStatus>> {

    return this.getOverallStatesOfChargingStation$Response(params).pipe(
      map((r: StrictHttpResponse<Array<OverallStatus>>) => r.body as Array<OverallStatus>)
    );
  }

  /**
   * Path part for operation getOcppConfigurationOfChargingStation
   */
  static readonly GetOcppConfigurationOfChargingStationPath = '/chargingStations/{stationId}/ocppConfiguration/';

  /**
   * Gets latest ocpp configuration of a charging station.
   *
   * Fetch latest ocpp configuration of a specific charging station
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getOcppConfigurationOfChargingStation()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOcppConfigurationOfChargingStation$Response(params: {

    /**
     * Unique identifier of the Charging Station
     */
    stationId: string;
  }): Observable<StrictHttpResponse<Array<OcppConfiguration>>> {

    const rb = new RequestBuilder(this.rootUrl, DetailsOverviewService.GetOcppConfigurationOfChargingStationPath, 'get');
    if (params) {
      rb.path('stationId', params.stationId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<OcppConfiguration>>;
      })
    );
  }

  /**
   * Gets latest ocpp configuration of a charging station.
   *
   * Fetch latest ocpp configuration of a specific charging station
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getOcppConfigurationOfChargingStation$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOcppConfigurationOfChargingStation(params: {

    /**
     * Unique identifier of the Charging Station
     */
    stationId: string;
  }): Observable<Array<OcppConfiguration>> {

    return this.getOcppConfigurationOfChargingStation$Response(params).pipe(
      map((r: StrictHttpResponse<Array<OcppConfiguration>>) => r.body as Array<OcppConfiguration>)
    );
  }

}
