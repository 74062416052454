import { Component, EventEmitter, Input, Output, Signal, computed, input } from '@angular/core';
import { selectFilterOption } from '../select-filters/select-filters.component';
import { Alert, FilterSet, SharedFilterSet } from 'src/app/core/data-backend/models';
import { Filter } from 'src/app/core/stores/station-filters.repository';
import { TranslateService } from '@ngx-translate/core';
import { toSignal } from '@angular/core/rxjs-interop';

@Component({
    selector: 'filterset-modal',
    template: `
        <app-modal
            [open]="open"
            [width]="'large'"
            [noBodyPadding]="true"
            (openChange)="openChange.emit($event)"
        >
            <div header>
                <p class="headline">
                    {{ title() }}
                </p>
            </div>
            <div body class="wrapper">
                <!-- Mask -->
                <evc-filterset-mask
                    [availableFilters]="availableFilters"
                    [baseFilters]="baseFilters"
                    [preselectedFilters]="preselectedFilters"
                    [currentFilterSets]="currentFilterSets"
                    [showCancel]="true"
                    [mode]="mode()"
                    [type]="editFilterset?.isShared ? 'shared' : 'default'"
                    [canSwitchType]="canSwitchType"
                    [editFilterset]="editFilterset"
                    [editAlert]="editAlert"
                    (onSuccess)="closeModal()"
                    (onCancel)="closeModal()"
                    (onError)="closeModal()"
                >
                </evc-filterset-mask>
            </div>
        </app-modal>
    `,
    styleUrls: ['./filterset-modal.component.scss']
})
export class FiltersetModalComponent {
    @Input() availableFilters: selectFilterOption[] | null = null;
    @Input() baseFilters: Filter[] | null = null;
    @Input() preselectedFilters: Filter[] | null = null;
    @Input() currentFilterSets: (FilterSet | SharedFilterSet)[] | null = null;
    mode = input<'create' | 'update' | 'preview'>('create');
    @Input() editAlert: Alert | null = null;
    @Input() open: boolean = false;
    @Input() canSwitchType: boolean = false;
    @Input() editFilterset: FilterSet | SharedFilterSet | null = null;
    // Output
    @Output() openChange = new EventEmitter<boolean>();
    // Dynamic title
    public title: Signal<string>;
    
    constructor(
        public translate: TranslateService
    ) {
        const langChanged = toSignal(this.translate.onLangChange);
        this.title = computed(() => {
            // call langChanged to update the title when the language changes
            const newLang = langChanged();
            const localizedFiltersetType = this.translate.instant(this.editFilterset?.isShared ? 'FILTERS.SHARED_FILTER_SET.ONE' : 'FILTERS.FILTER_SET.ONE');
            return this.translate.instant(`FILTERS.MANAGE.${this.mode().toUpperCase()}_FILTER_SET`, { content: localizedFiltersetType });
        })
    }

    closeModal() {
        this.open = false;
        this.openChange.emit(false);
    }
}
