import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SearchService {
  // Search Service to make query available in all components of view
  // should not be used to store query between views
  searchQuery$: BehaviorSubject<string> = new BehaviorSubject<string>('');

  constructor() { }

  setSearchQuery(query: string) {
    if (query === undefined) return
    this.searchQuery$.next(query)
  }

  removeSearchQuery() {
    this.searchQuery$.next('');
  }
}
