import { Injectable, inject } from "@angular/core";
import { createStore, select, setProp, setProps, withProps } from "@ngneat/elf";
import { catchError, combineLatest, distinctUntilChanged, filter, map, Observable, of, ReplaySubject, retry, share, switchMap, take, tap, withLatestFrom } from "rxjs";
import { DateRange } from "../plots/plot.models";
import { ChargingStation } from "../data-backend/models";
import { DetailsOverviewService } from "../data-backend/data-services";
import { persistState, sessionStorageStrategy } from "@ngneat/elf-persist-state";
import { overviewRepository } from "./overview.repository";
import { Router } from "@angular/router";
import { NotificationService } from "../app-services";
import { eachDayOfInterval } from "date-fns";
import { appRepository } from "./app.repository";
import { ChargingStationTransformer, ExtendedChargingStation } from "../helpers/transform-stations.helper";
import { HttpErrorResponse } from "@angular/common/http";
import { TranslateService } from "@ngx-translate/core";

export interface stationStoreProps {
    station: ChargingStation | null;
    stationId: ChargingStation['stationId'] | null;
    lastStationId: ChargingStation['stationId'] | null;
}

export type AutomaticRecovery = 'Recover on OCPP message' | 'Recover on regular boot' | 'Recover on regular session' | 'Never Recover';
export interface createTicketStoreProps {
    stationId: ChargingStation['stationId'] | null;
    automaticRecovery: AutomaticRecovery[];
    affectedConnectors: 'Station' | number[];
    startOfDefect: Date;
    description: string;
}

const defaultInterval   = 14;
export const getDefaultRange = (): DateRange => {
    let today               = new Date(),
        twoWeeksAgo         = new Date();
    twoWeeksAgo.setDate(twoWeeksAgo.getDate() - defaultInterval);
    return {from: twoWeeksAgo, until: today}
}

const defDateRange = getDefaultRange();

const stationStore = createStore(
    {name: 'station'},
    withProps<stationStoreProps>({
        station: null, 
        stationId: '',
        lastStationId: null
    })
)

const createTicketStore = createStore(
    {name: 'createTicket'},
    withProps<createTicketStoreProps>({
        stationId: '',
        automaticRecovery: ['Never Recover'],
        affectedConnectors: 'Station',
        startOfDefect: new Date(),
        description: ''
    })
)

const chartsStore = createStore(
    {name: 'charts'},
    withProps<{
        chartsZoomRange: [number, number]; // zoom range of timeline plot, start & end in percent
        chartsZoomSource: string; // source of latest zoom event - prevents recursive zoom events
        featuredUnit: 'power' | 'current';
        featuredSoC: 'soc' | null;
        dateRange: DateRange;
        setDateRangeAt: Date; // date of last change to dateRange
        interval: number; // calculated numbers of days between dateRange
    }>({
        chartsZoomRange: [80, 100],
        chartsZoomSource: 'init',
        featuredUnit: 'power',
        featuredSoC: 'soc',
        dateRange: defDateRange,
        setDateRangeAt: defDateRange.until,
        interval: defaultInterval
    }),
)

persistState(createTicketStore, {
    key: 'createTicket',
    storage: sessionStorageStrategy
});

persistState(chartsStore, {
    key: 'chartSettings',
    storage: sessionStorageStrategy
})

@Injectable({ providedIn: 'root' })
export class detailsRepository {
    private _chargingStationTransformer = inject(ChargingStationTransformer);

    constructor(
        public service: DetailsOverviewService,
        private _appRepo: appRepository,
        private _overviewRepo: overviewRepository,
        private _router: Router,
        private _notificationService: NotificationService,
        private _translate: TranslateService
    ) {}

    stationId$ = stationStore.pipe(
        select((state) => state.stationId)
    )

    lastStationId$ = stationStore.pipe(
        select((state) => state.lastStationId)
    )

    dateRange$ = chartsStore.pipe(
        filter(() => this._checkDateRange()),
        select((state) => state.dateRange),
        map(dateRange => {
            // fix for dates stored as string in session
            if (typeof(dateRange.from) !== 'object') {
                dateRange = {
                    from: new Date(dateRange.from),
                    until: new Date(dateRange.until)
                }
            }

            return dateRange
        }),
        share({connector: () => new ReplaySubject(1)})
    )

    interval$ = chartsStore.pipe(
        select((state) => state.interval)
    )

    createTicket$ = createTicketStore.pipe(
        select((state) => state)
    )

    featuredUnit$ = chartsStore.pipe(
        select((state) => state.featuredUnit)
    )

    featuredSoC$ = chartsStore.pipe(
        select((state) => state.featuredSoC)
    )

    chartsZoomRange$: Observable<[[number, number], string]> = chartsStore.pipe(
        map(({ chartsZoomRange, chartsZoomSource }) =>
            [chartsZoomRange, chartsZoomSource]
        )
    )

    // latest zoom range with source !== dataZoom, to reduce multiple DOM manipulations per range
    // these obs values get passed into plot-range-slider
    // filter out all emissions from dataZoom source ("id" of timeline-range-slider events)
    // always pass filter on first emission, as the last stored value could be source = dataZoom, causing slider to reset
    filteredChartsZoomRange$ = this.chartsZoomRange$.pipe(
        // filter(([zoom, source]) => source !== ),
        distinctUntilChanged((prev, current) => {
            const prevZoom = prev[0];
            const currZoom = current[0];
            // all plots emit on dataZoom after dispatching its corresponding action, thus we
            // filter out multiple emissions of same range to reduce amt of total dataZoom calls
            return prevZoom[0] === currZoom[0] && prevZoom[1] === currZoom[1] && current[1] !== 'dataZoom'
        }),
        map(([zoom]) => zoom)
    )

    setChartsZoomRange(range: [number, number], source: string) {
        chartsStore.update(setProps({
            chartsZoomRange: range,
            chartsZoomSource: source
        }))
    }

    setFeaturedUnit(value: 'power' | 'current') {
        chartsStore.update(setProp('featuredUnit', value))
    }

    setFeaturedSoC(value: 'soc' | null) {
        chartsStore.update(setProp('featuredSoC', value))
    }

    // checks if stored state is of today, else reset
    private _checkDateRange(): boolean {
        let setAt = chartsStore.getValue().setDateRangeAt;
        if (typeof(setAt) !== 'object') setAt = new Date(setAt);
        const today = new Date();
        if (
            setAt.getFullYear() === today.getFullYear() &&
            setAt.getMonth() === today.getMonth() &&
            setAt.getDate() === today.getDate()
        ) {
            return true
        }
        
        const resetRange = getDefaultRange();
        this.setDateRange(resetRange)
        return false
    }
    
    private _storedStation$ = stationStore.pipe(
        select((state) => state.station)
    )

    private _additionalInfo$ = combineLatest({
        stations: this._overviewRepo.stations$,
        storedStation: this._storedStation$,
        customer: this._appRepo.currentUser$
    })
    
    // stationId$ is set on new detail views
    // get stored station if applicable, else fetch new one
    station$: Observable<ExtendedChargingStation | null> = this.stationId$.pipe(
        withLatestFrom(this._additionalInfo$),
        switchMap(([id, {stations, storedStation, customer}]) => {
            if (id === null || id.length === 0 || customer == null) return of(null)
            // return stored station if it matches current id
            if (storedStation && storedStation.stationId == id) return of(storedStation)
            // fetch new station from backend
            return this.service.getChargingStation({
                stationId: id
            }).pipe(
                retry(1),
                catchError((error: HttpErrorResponse) => {
                    // if no data available, show error, redirect to overview
                    if (error.status === 404) {
                        this._notificationService.showError(
                            this._translate.instant('APP_ERRORS.STATION_NOT_FOUND', {id: id}),
                            this._translate.instant('APP_ERRORS.NO_DATA_AVAILABLE')
                        )
                        this._router.navigate(['/overview'])
                        this.setStationId(null)
                        stationStore.update(setProp('station', null))
                    }

                    // return observable of null if error occurs
                    return of(null)
                }),
            )
        }),
        map((station) => {
            if (!station) return null
            return this._chargingStationTransformer.extendedChargingStationTransformer(station)
        }),
        share({connector: () => new ReplaySubject(1)})
    )

    setStationId(id: ChargingStation['stationId'] | null) {
        // get current id, save it as last
        const lastId = stationStore.getValue().stationId;
        if (lastId !== null) stationStore.update(setProp('lastStationId', lastId))
        // then update current stationId
        stationStore.update(setProp('stationId', id))
    }

    setDateRange(dateRange: DateRange) {
        // calculate days between ranges
        const interval = eachDayOfInterval({
            start: dateRange.from,
            end: dateRange.until
        }).length - 1; // subtract the start day from interval
        chartsStore.update(
            setProp('dateRange', dateRange),
            setProp('interval', interval > 0 ? interval : 1), 
            setProp('setDateRangeAt', new Date())
        )
    }

    resetCreateTicket(stationId: string) {
        createTicketStore.update(setProps({
            stationId: stationId,
            automaticRecovery: ['Never Recover'],
            affectedConnectors: 'Station',
            startOfDefect: new Date(),
            description: ''
        }));
    }

    setCreateTicketFromTimeline(affConns: number[] | 'Station', startOfDef: Date, desc: string) {
        affConns = this.allConnectorsAffected(affConns);
        createTicketStore.update(setProps({
            affectedConnectors: affConns,
            startOfDefect: startOfDef,
            description: desc
        }));
    };

    setCreateTicketAttr(attr: keyof createTicketStoreProps, value: any, stationId: string) {
        if (attr == "affectedConnectors") value = this.allConnectorsAffected(value);
        createTicketStore.update(setProp('stationId', stationId));
        createTicketStore.update(setProp(attr, value));
    }

    allConnectorsAffected(value: number[] | 'Station'): number[] | 'Station' {
        if (value == 'Station') return value;
        this.station$.pipe(take(1)).subscribe(station => {
            if (station?.connectors.length == value.length) value = 'Station';
        })
        return value;
    }
}
