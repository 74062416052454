/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { DataBaseService } from '../data-base-service';
import { DataApiConfiguration } from '../data-api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { Alert } from '../models/alert';
import { deleteAlert } from '../fn/filtersets/delete-alert';
import { DeleteAlert$Params } from '../fn/filtersets/delete-alert';
import { deleteFilterSet } from '../fn/filtersets/delete-filter-set';
import { DeleteFilterSet$Params } from '../fn/filtersets/delete-filter-set';
import { FilterSet } from '../models/filter-set';
import { getAlert } from '../fn/filtersets/get-alert';
import { GetAlert$Params } from '../fn/filtersets/get-alert';
import { getAlerts } from '../fn/filtersets/get-alerts';
import { GetAlerts$Params } from '../fn/filtersets/get-alerts';
import { getFilterSet } from '../fn/filtersets/get-filter-set';
import { GetFilterSet$Params } from '../fn/filtersets/get-filter-set';
import { getFilterSets } from '../fn/filtersets/get-filter-sets';
import { GetFilterSets$Params } from '../fn/filtersets/get-filter-sets';
import { postAlert } from '../fn/filtersets/post-alert';
import { PostAlert$Params } from '../fn/filtersets/post-alert';
import { postFilterSets } from '../fn/filtersets/post-filter-sets';
import { PostFilterSets$Params } from '../fn/filtersets/post-filter-sets';
import { putFilterSet } from '../fn/filtersets/put-filter-set';
import { PutFilterSet$Params } from '../fn/filtersets/put-filter-set';
import { SharedFilterSet } from '../models/shared-filter-set';
import { subscribeFilterSet } from '../fn/filtersets/subscribe-filter-set';
import { SubscribeFilterSet$Params } from '../fn/filtersets/subscribe-filter-set';
import { updateAlert } from '../fn/filtersets/update-alert';
import { UpdateAlert$Params } from '../fn/filtersets/update-alert';

@Injectable({ providedIn: 'root' })
export class FiltersetsService extends DataBaseService {
  constructor(config: DataApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getFilterSets()` */
  static readonly GetFilterSetsPath = '/filterSets/';

  /**
   * Return list of filter sets.
   *
   * Return list of filter sets for user with given uuid
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getFilterSets()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFilterSets$Response(params?: GetFilterSets$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<(FilterSet | SharedFilterSet)>>> {
    return getFilterSets(this.http, this.rootUrl, params, context);
  }

  /**
   * Return list of filter sets.
   *
   * Return list of filter sets for user with given uuid
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getFilterSets$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFilterSets(params?: GetFilterSets$Params, context?: HttpContext): Observable<Array<(FilterSet | SharedFilterSet)>> {
    return this.getFilterSets$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<(FilterSet | SharedFilterSet)>>): Array<(FilterSet | SharedFilterSet)> => r.body)
    );
  }

  /** Path part for operation `postFilterSets()` */
  static readonly PostFilterSetsPath = '/filterSets/';

  /**
   * Add list of filter sets.
   *
   * Add list of filter sets for user with given uuid
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postFilterSets()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postFilterSets$Response(params?: PostFilterSets$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<(FilterSet | SharedFilterSet)>>> {
    return postFilterSets(this.http, this.rootUrl, params, context);
  }

  /**
   * Add list of filter sets.
   *
   * Add list of filter sets for user with given uuid
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `postFilterSets$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postFilterSets(params?: PostFilterSets$Params, context?: HttpContext): Observable<Array<(FilterSet | SharedFilterSet)>> {
    return this.postFilterSets$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<(FilterSet | SharedFilterSet)>>): Array<(FilterSet | SharedFilterSet)> => r.body)
    );
  }

  /** Path part for operation `getFilterSet()` */
  static readonly GetFilterSetPath = '/filterSets/{filtersetid}/';

  /**
   * Return one filter set.
   *
   * Return one filter set with given filter set id for user with given uuid
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getFilterSet()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFilterSet$Response(params: GetFilterSet$Params, context?: HttpContext): Observable<StrictHttpResponse<(FilterSet | SharedFilterSet)>> {
    return getFilterSet(this.http, this.rootUrl, params, context);
  }

  /**
   * Return one filter set.
   *
   * Return one filter set with given filter set id for user with given uuid
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getFilterSet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFilterSet(params: GetFilterSet$Params, context?: HttpContext): Observable<(FilterSet | SharedFilterSet)> {
    return this.getFilterSet$Response(params, context).pipe(
      map((r: StrictHttpResponse<(FilterSet | SharedFilterSet)>): (FilterSet | SharedFilterSet) => r.body)
    );
  }

  /** Path part for operation `putFilterSet()` */
  static readonly PutFilterSetPath = '/filterSets/{filtersetid}/';

  /**
   * Update one filter set.
   *
   * Update one filter set with given filter set id for user with given uuid
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `putFilterSet()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putFilterSet$Response(params: PutFilterSet$Params, context?: HttpContext): Observable<StrictHttpResponse<string>> {
    return putFilterSet(this.http, this.rootUrl, params, context);
  }

  /**
   * Update one filter set.
   *
   * Update one filter set with given filter set id for user with given uuid
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `putFilterSet$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putFilterSet(params: PutFilterSet$Params, context?: HttpContext): Observable<string> {
    return this.putFilterSet$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /** Path part for operation `deleteFilterSet()` */
  static readonly DeleteFilterSetPath = '/filterSets/{filtersetid}/';

  /**
   * Delete one filter set.
   *
   * Delete the one filter set with the given filter set id for the user with given uuid
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteFilterSet()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteFilterSet$Response(params: DeleteFilterSet$Params, context?: HttpContext): Observable<StrictHttpResponse<string>> {
    return deleteFilterSet(this.http, this.rootUrl, params, context);
  }

  /**
   * Delete one filter set.
   *
   * Delete the one filter set with the given filter set id for the user with given uuid
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteFilterSet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteFilterSet(params: DeleteFilterSet$Params, context?: HttpContext): Observable<string> {
    return this.deleteFilterSet$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /** Path part for operation `subscribeFilterSet()` */
  static readonly SubscribeFilterSetPath = '/filterSets/{filtersetid}/subscription/';

  /**
   * (Un)Subscribe to one filter set.
   *
   * Subscribe or unsubscribe to one filter set with given filter set id for user with given uuid
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `subscribeFilterSet()` instead.
   *
   * This method doesn't expect any request body.
   */
  subscribeFilterSet$Response(params: SubscribeFilterSet$Params, context?: HttpContext): Observable<StrictHttpResponse<string>> {
    return subscribeFilterSet(this.http, this.rootUrl, params, context);
  }

  /**
   * (Un)Subscribe to one filter set.
   *
   * Subscribe or unsubscribe to one filter set with given filter set id for user with given uuid
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `subscribeFilterSet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  subscribeFilterSet(params: SubscribeFilterSet$Params, context?: HttpContext): Observable<string> {
    return this.subscribeFilterSet$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /** Path part for operation `getAlerts()` */
  static readonly GetAlertsPath = '/alerts/';

  /**
   * Return list of alerts.
   *
   * Return list of alerts for user with given uuid
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAlerts()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAlerts$Response(params?: GetAlerts$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<Alert>>> {
    return getAlerts(this.http, this.rootUrl, params, context);
  }

  /**
   * Return list of alerts.
   *
   * Return list of alerts for user with given uuid
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAlerts$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAlerts(params?: GetAlerts$Params, context?: HttpContext): Observable<Array<Alert>> {
    return this.getAlerts$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<Alert>>): Array<Alert> => r.body)
    );
  }

  /** Path part for operation `postAlert()` */
  static readonly PostAlertPath = '/alerts/';

  /**
   * Create an alert.
   *
   * Add an alert to the list of alerts for user with given uuid and filterset id.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postAlert()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postAlert$Response(params: PostAlert$Params, context?: HttpContext): Observable<StrictHttpResponse<string>> {
    return postAlert(this.http, this.rootUrl, params, context);
  }

  /**
   * Create an alert.
   *
   * Add an alert to the list of alerts for user with given uuid and filterset id.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `postAlert$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postAlert(params: PostAlert$Params, context?: HttpContext): Observable<string> {
    return this.postAlert$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /** Path part for operation `getAlert()` */
  static readonly GetAlertPath = '/alerts/{alertId}/';

  /**
   * Return alert.
   *
   * Return the alert which is registered under the alertId given.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAlert()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAlert$Response(params: GetAlert$Params, context?: HttpContext): Observable<StrictHttpResponse<Alert>> {
    return getAlert(this.http, this.rootUrl, params, context);
  }

  /**
   * Return alert.
   *
   * Return the alert which is registered under the alertId given.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAlert$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAlert(params: GetAlert$Params, context?: HttpContext): Observable<Alert> {
    return this.getAlert$Response(params, context).pipe(
      map((r: StrictHttpResponse<Alert>): Alert => r.body)
    );
  }

  /** Path part for operation `updateAlert()` */
  static readonly UpdateAlertPath = '/alerts/{alertId}/';

  /**
   * Update alert.
   *
   * Update an existing alert by alert id.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateAlert()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateAlert$Response(params: UpdateAlert$Params, context?: HttpContext): Observable<StrictHttpResponse<string>> {
    return updateAlert(this.http, this.rootUrl, params, context);
  }

  /**
   * Update alert.
   *
   * Update an existing alert by alert id.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateAlert$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateAlert(params: UpdateAlert$Params, context?: HttpContext): Observable<string> {
    return this.updateAlert$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /** Path part for operation `deleteAlert()` */
  static readonly DeleteAlertPath = '/alerts/{alertId}/';

  /**
   * Delete one alert.
   *
   * Delete the alert by the given alert id.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteAlert()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteAlert$Response(params: DeleteAlert$Params, context?: HttpContext): Observable<StrictHttpResponse<string>> {
    return deleteAlert(this.http, this.rootUrl, params, context);
  }

  /**
   * Delete one alert.
   *
   * Delete the alert by the given alert id.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteAlert$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteAlert(params: DeleteAlert$Params, context?: HttpContext): Observable<string> {
    return this.deleteAlert$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

}
