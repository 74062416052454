import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { appRepository } from 'src/app/core/stores/app.repository';
import { environment } from "src/environments/environment";

@Component({
    selector: 'app-admin-selection',
    template: `
        <div 
            class="background"
        >
            <div class="selection-field">
                <div class="selection-cont">
                    <div class="logo evailable"></div>
                    <div class="admin-title">Admin</div>
                    <button 
                        class="default-button w-100"
                        title="manage users"
                        (click)="goToPage('/admin-users')"
                        tabindex="1"
                    >
                        <div class="material-icon">group</div>
                        {{ 'NAVIGATION.MANAGE_USERS' | translate }}
                    </button>
                    <button 
                        class="default-button w-100"
                        title="manage tenant access"
                        (click)="goToPage('/admin-tenants')"
                        tabindex="1"
                    >
                        <div class="material-icon">groups</div>
                        {{ 'NAVIGATION.MANAGE_TENANT_ACCESS' | translate }}
                    </button>
                    <button 
                        class="default-button w-100"
                        title="manage notifications"
                        (click)="goToPage('/admin-notifications')"
                        tabindex="1"
                    >
                        <div class="material-icon">chat</div>
                        {{ 'NAVIGATION.NOTIFICATIONS' | translate }}
                    </button>
                </div>
            </div>
        </div>
    `,
    styleUrls: ['./admin-selection.component.scss']
})
export class AdminSelectionComponent {

    readonly brandName: string = environment.brandName;

    constructor(
        public repo: appRepository,
        private _router: Router
    ) {}

    goToPage(path: string) {
        this._router.navigate([path])
    }
}
