/**
 * This helper exports functions used in different charts / plots globally
 */

import { inject } from "@angular/core";
import { ChargingSession } from "../data-backend/models";
import { TranslateService } from "@ngx-translate/core";


// === T O O L T I P S === //

export type DisplayInfo = {
    headline?: string,
    info?: string,
    pre?: string,
    main?: string,
    sub?: string,
    sectionClass?: string,
    useForDefect?: false,
    show: boolean
} | {
    // if useForDefect, main and sub must be provided
    useForDefect: true,
    main: string,
    sub: string,
    info: string, // will be used as button text
    sectionClass?: string,
    show: boolean
};

// returns html string based on given sections
export const tooltipContent = (displayInfo: DisplayInfo[]): string => {
    let eventContent = '';
    displayInfo.forEach((displayItem) => {
        if (displayItem.show) {
            let sectionContent = ``;

            if (displayItem.useForDefect) {
                let useForDefect = `<div 
                                        class="use-for-defect"
                                        data-title="${displayItem.main}"
                                        data-date="${displayItem.sub}"
                                    >
                                        <div class="material-icon">build_circle</div>
                                        ${displayItem.info}
                                    </div>`
                sectionContent = sectionContent + useForDefect;
            } else {
                if (displayItem.headline !== undefined) sectionContent = sectionContent + `<p class="headline">${displayItem.headline}</p>`;
                if (displayItem.info !== undefined)     sectionContent = sectionContent + `<p class="info">${displayItem.info}</p>`;
                if (displayItem.pre !== undefined)      sectionContent = sectionContent + `<p class="pre">${displayItem.pre}</p>`;
                if (displayItem.main !== undefined)     sectionContent = sectionContent + `<p class="main">${displayItem.main}</p>`;
                if (displayItem.sub !== undefined)      sectionContent = sectionContent + `<p class="sub">${displayItem.sub}</p>`;
            }

            if (sectionContent.trim().length > 0) {
                eventContent +=
                    `<div class="section ${displayItem.sectionClass || ''}">
                        ${sectionContent}
                    </div>`
            }
        }
    })

    return eventContent
}

// basic position function
export const defaultTooltipPositionFn = (point: any[], params: Object | Array<Object>, dom: HTMLElement, rect: DOMRect, size: any) => {
    let posLeft = rect.x + rect.width + 10;
    const viewWidth = size['viewSize'][0];
    const tooltipWidth = size['contentSize'][0];

    posLeft = posLeft + tooltipWidth > viewWidth - 30 ? rect.x - tooltipWidth - 10 : posLeft;

    return [
        posLeft,
        rect.y - 20
    ]
}


/**
 * The `setTooltipListeners` function adds event listeners to a chart instance to handle clicks in the
 * tooltip, allowing for interaction with the chart's data.
 * @param chartInstance - The `chartInstance` parameter is of type `echarts.EChartsType` and represents
 * the instance of the ECharts chart that the tooltip listeners will be added to.
 * @param onClickIdentifier - The `onClickIdentifier` parameter is the html element dataset identifier and determines which data 
 * of the clicked elements is returned (eg. 'eventData' if the event is on data-event-data="" attribute of element)
 * @param onClick - The `onClick` parameter is a callback function that returns the clicked elements value of 
 + the attribute specified in onClickIdentifier (eg. call with 'eventData' if the event is on data-event-data="" attribute of element)
 * @param ticketCallback - The `ticketCallback` parameter is a callback function that takes three
 * arguments: `connectorId`, `date`, and `title`. It is called when the "use-for-defect" button is
 * clicked in the tooltip.
 */
export const setTooltipListeners = (chartInstance: echarts.EChartsType, onClickIdentifier: string, onClick: (event: any[]) => void | null, ticketCallback?: (connectorId: number[], date: Date, title: string) => void) => {
    // add event listener to chart instance to handle clicks in tooltip
    const tooltipInDOM = document.querySelector(`.echarts-id-${chartInstance.id}`);

    if (!tooltipInDOM) return

    const tooltipContentListener = (event: any) => {
        const target = event.target;
        const _showEventContent = (id: string) => {
            const currentlyActiveButton: HTMLElement | null = tooltipInDOM.querySelector('.chart-event-button.active');
            const currentlyActiveId = currentlyActiveButton?.dataset['eventId'];
            if (currentlyActiveId == undefined) return
            // remove active state from current button, hide current info
            currentlyActiveButton?.classList.remove('active');
            const currentContent: HTMLElement | null = tooltipInDOM.querySelector('[data-content-id="'+ currentlyActiveId +'"]');
            currentContent!.style.display = 'none'
    
            const eventButton: HTMLElement | null = tooltipInDOM.querySelector('[data-event-id="'+ id +'"]');
            const eventContent: HTMLElement | null = tooltipInDOM.querySelector('[data-content-id="'+ id +'"]');
            if (!eventButton || !eventContent) return;
            const eventData = eventButton.dataset[onClickIdentifier];
            if (eventData) {
                onClick(JSON.parse(eventData))
            }

            eventButton.classList.add('active')
            eventContent.style.display = 'flex'
            eventButton.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' })
        }
    
        if (target.classList.contains('chart-event-button')) {
            // handles single event selection
            const newId = target.dataset.eventId;
            _showEventContent(newId);
        } else if (target.classList.contains('chart-filter-button')) {
            // handles evaluation filters
            const resetButton = tooltipInDOM.querySelector('[data-filter-evaluation="reset-all"]');
            const allEventIcons: NodeListOf<HTMLElement> | null = tooltipInDOM.querySelectorAll('.chart-event-button');
            if (target.classList.contains('active')) {
                // if active, remove active status, show hidden event icons again
                target.classList.remove('active');
            } else {
                target.classList.add('active');
            }
            const allActiveFilterButtons: NodeListOf<HTMLElement> | null = tooltipInDOM.querySelectorAll('.chart-filter-button.active');
            if (allActiveFilterButtons.length > 0 && target.dataset['filterEvaluation'] !== 'reset-all') {
                // get active filter values
                let activeEvaluations: string[] = [];
                allActiveFilterButtons.forEach((filterButton) => {
                    const evaluation = filterButton.dataset['filterEvaluation'];
                    if (evaluation) activeEvaluations.push(evaluation)
                })
                
                // remove active state from "all" if at least one other filter is set
                if (activeEvaluations.includes('reset-all')) {
                    resetButton!.classList.remove('active')
                }
    
                let firstVisibleEventSet: boolean = false;
                allEventIcons.forEach((eventIcon) => {
                    const eventEvaluation = eventIcon.dataset['eventEvaluation'];
                    if (eventEvaluation && activeEvaluations.includes(eventEvaluation)) {
                        // set first visible event to selected state
                        if (!firstVisibleEventSet) {
                            const eventId = eventIcon.dataset['eventId'];
                            if (eventId !== undefined) {
                                firstVisibleEventSet = true
                                _showEventContent(eventId)
                            }
                        }
                        eventIcon.style.display = 'inline-block'
                    } else {
                        eventIcon.style.display = 'none'
                    }
                })
            } else {
                allActiveFilterButtons.forEach((filterButton) => {
                    filterButton.classList.remove('active')
                })
                // reset default "all"
                resetButton!.classList.add('active');
    
                allEventIcons.forEach((eventIcon) => {
                    eventIcon.style.display = 'inline-block'
                })
            }
    
        } else if (target.classList.contains('use-for-defect')) {
            // update 'create ticket' data and show notification
            let connectorId: string = '';
            let pattern = /charts-con-(\d+)/;
            for (let className of target.offsetParent.classList) {
                let match = className.match(pattern);
                if (match) connectorId = match[1].toString();
            }
            ticketCallback && ticketCallback(
                [parseInt(connectorId)], 
                new Date(target.dataset.date),
                target.dataset.title
            )
        }
    }

    tooltipInDOM?.removeEventListener('click', tooltipContentListener, false);
    tooltipInDOM?.addEventListener('click', tooltipContentListener, { passive: true });

    // mutation observer to handle the scroll in tooltips
    const observer = new MutationObserver(() => {
        const tooltip = tooltipInDOM as HTMLElement;
        const scrollList = tooltip.querySelector('.scroll-overflow-container');
        const buttons = tooltip.querySelectorAll('.chart-event-button');
        const isVisible = tooltip.style.display === 'block' && tooltip.style.visibility !== 'hidden';
        const prevVis = tooltip.dataset['prevVis'] === 'true';
        const prevBtnIndex = tooltip.dataset['prevBtn'];
        
        // check if active button got switched
        buttons.forEach((button, index) => {
            const isActive = button.classList.contains('active');
            if (isActive && prevBtnIndex !== index.toString()) {
                scrollToButton();
                tooltip.dataset['prevBtn'] = index.toString();
            }
        });

        // check if tooltip got opened
        if (!prevVis && isVisible) scrollToButton();
        if (prevVis != isVisible) tooltip.dataset['prevVis'] = isVisible.toString();
    
        function scrollToButton() {
            const activeBtn: HTMLElement | null = tooltip.querySelector('.chart-event-button.active');
            if (!scrollList || !activeBtn) return;
            scrollList.scrollLeft = activeBtn.offsetLeft - 13;
        }
    });
    observer.observe(tooltipInDOM, { attributes: true, childList: true });
}

// === H E L P E R S === //

// returns same methods used in different scopes of different functions
// optionally provide target DataSet, defaults to configured "event" dataSetIndex
export const dataMethods = (chartInstance: echarts.EChartsType, forDatasetId: number): {
    _getDimensionIndex: (dimension: string) => number | undefined,
    _getBulkDimensionIndexes: (dimensions: string[]) => {[name: string]: number | undefined}
} => {
    // get index of dimension in dataSet
    const _getDimensionIndex = (dimension: string): number | undefined => {
        const dataSets: any = chartInstance.getOption()['dataset'];
        if (!dataSets) return
        return dataSets[forDatasetId]['dimensions'].indexOf(dimension)
    }

    // get multiple requested dimensions in dataSet
    const _getBulkDimensionIndexes = (dimensions: string[]): {
        [name: string]: number | undefined
    } => {
        let res: any = {};
        dimensions.forEach((dimension) => {
            res[dimension + 'DimensionIndex'] = _getDimensionIndex(dimension)
        });
        return res
    }

    return {
        _getDimensionIndex,
        _getBulkDimensionIndexes
    }
}

// === G R O U P I N G == //

// takes grouped entries and returns them indexed by the current chart zoom
export const addGroup = (
    currentGroups: {
        zoom: {
            start: number,
            end: number
        },
        groups: [any[]]
    } | undefined,
    currentZoomLevels: {
        start: number,
        end: number
    },
    group: any[], 
    idIndex: number = 0
): {
    zoom: {
        start: number,
        end: number
    },
    groups: [any[]]
} => {
    const isInCurrentZoom = currentGroups && currentGroups.zoom.start === currentZoomLevels.start && currentGroups.zoom.end === currentZoomLevels.end;

    if (isInCurrentZoom) {
        // get all ids of new group
        const idsInGroup = new Set(group.map((entry) => entry[idIndex]));
        let indexes: any[] = [];
        currentGroups!.groups.forEach((group, index) => {
            // check if existing group contains entries of new group
            const groupHasId = group.some((entry) => idsInGroup.has(entry[idIndex]));
            // append loop index to "indexes" to clean up
            if (groupHasId) {
                indexes.push(index)
            }
        })

        // in reverse order, remove old groups that contain at least one of the new group's index
        for (let i = indexes.length - 1; i >= 0; i--) {
            currentGroups!.groups.splice(indexes[i], 1)
        }
        // add new group
        currentGroups!.groups.push(group)
    } else {
        // if no groups exist yet, remove old stack and collect new groups
        currentGroups = {
            zoom: structuredClone(currentZoomLevels),
            groups: [group]
        };
    }

    return currentGroups!
}

// returns stopDate of session, calculates a vague stopDate if not available
export const getStopDate = (session: ChargingSession) => {
    return session.stopDate ?? session.meterValues
            .flatMap(meterValue => meterValue.values.map(value => value.date))
            // find latest date
            .reduce((latest, date) => (new Date(date) > new Date(latest) ? date : latest));
}
