/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { DataBaseService } from '../data-base-service';
import { DataApiConfiguration } from '../data-api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { OcppMessageAggregation } from '../models/ocpp-message-aggregation';
import { OcppMessageEvent } from '../models/ocpp-message-event';


/**
 * Analysis Tab for information on ocpp messages transmitted and connectivity information from the sim card portal.
 */
@Injectable({
  providedIn: 'root',
})
export class DetailsConnectivityService extends DataBaseService {
  constructor(
    config: DataApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getAggregatedOcppMessagesOfChargingStation
   */
  static readonly GetAggregatedOcppMessagesOfChargingStationPath = '/chargingStations/{stationId}/ocppMessageAggregations/';

  /**
   * Gets aggregated messages of charging station.
   *
   * Fetch the aggregated messages of a specific charging station, optionally for a specific time interval
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAggregatedOcppMessagesOfChargingStation()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAggregatedOcppMessagesOfChargingStation$Response(params: {

    /**
     * Unique identifier of the Charging Station
     */
    stationId: string;

    /**
     * The time up to which the list of aggregated messages should be displayed
     */
    date?: string;

    /**
     * The period in days before &#x27;date&#x27; from which all message aggregations should be returned
     */
    interval?: number;
  }): Observable<StrictHttpResponse<Array<OcppMessageAggregation>>> {

    const rb = new RequestBuilder(this.rootUrl, DetailsConnectivityService.GetAggregatedOcppMessagesOfChargingStationPath, 'get');
    if (params) {
      rb.path('stationId', params.stationId, {});
      rb.query('date', params.date, {});
      rb.query('interval', params.interval, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<OcppMessageAggregation>>;
      })
    );
  }

  /**
   * Gets aggregated messages of charging station.
   *
   * Fetch the aggregated messages of a specific charging station, optionally for a specific time interval
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAggregatedOcppMessagesOfChargingStation$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAggregatedOcppMessagesOfChargingStation(params: {

    /**
     * Unique identifier of the Charging Station
     */
    stationId: string;

    /**
     * The time up to which the list of aggregated messages should be displayed
     */
    date?: string;

    /**
     * The period in days before &#x27;date&#x27; from which all message aggregations should be returned
     */
    interval?: number;
  }): Observable<Array<OcppMessageAggregation>> {

    return this.getAggregatedOcppMessagesOfChargingStation$Response(params).pipe(
      map((r: StrictHttpResponse<Array<OcppMessageAggregation>>) => r.body as Array<OcppMessageAggregation>)
    );
  }

  /**
   * Path part for operation getOcppMessagesTypesOfChargingStation
   */
  static readonly GetOcppMessagesTypesOfChargingStationPath = '/chargingStations/{stationId}/ocppMessageEvents/';

  /**
   * Gets OCPP message occurrences by type for a charging station.
   *
   * Fetch OCPP message occurrences. The response includes the type and time of occurrence for each message, optionally for a specific time interval
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getOcppMessagesTypesOfChargingStation()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOcppMessagesTypesOfChargingStation$Response(params: {

    /**
     * Unique identifier of the Charging Station
     */
    stationId: string;

    /**
     * The time up to which the list of aggregated messages should be displayed
     */
    date?: string;

    /**
     * The period in days before &#x27;date&#x27; from which all message aggregations should be returned
     */
    interval?: number;
  }): Observable<StrictHttpResponse<Array<OcppMessageEvent>>> {

    const rb = new RequestBuilder(this.rootUrl, DetailsConnectivityService.GetOcppMessagesTypesOfChargingStationPath, 'get');
    if (params) {
      rb.path('stationId', params.stationId, {});
      rb.query('date', params.date, {});
      rb.query('interval', params.interval, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<OcppMessageEvent>>;
      })
    );
  }

  /**
   * Gets OCPP message occurrences by type for a charging station.
   *
   * Fetch OCPP message occurrences. The response includes the type and time of occurrence for each message, optionally for a specific time interval
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getOcppMessagesTypesOfChargingStation$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOcppMessagesTypesOfChargingStation(params: {

    /**
     * Unique identifier of the Charging Station
     */
    stationId: string;

    /**
     * The time up to which the list of aggregated messages should be displayed
     */
    date?: string;

    /**
     * The period in days before &#x27;date&#x27; from which all message aggregations should be returned
     */
    interval?: number;
  }): Observable<Array<OcppMessageEvent>> {

    return this.getOcppMessagesTypesOfChargingStation$Response(params).pipe(
      map((r: StrictHttpResponse<Array<OcppMessageEvent>>) => r.body as Array<OcppMessageEvent>)
    );
  }

}
