import { animate, style, transition, trigger } from '@angular/animations';
import { EventEmitter, Component, Input, Output, ElementRef, ChangeDetectionStrategy, Renderer2, ViewChild, OnInit, NgZone, OnDestroy } from '@angular/core';

@Component({
    selector: 'app-dropdown-button',
    template: `
        <div 
            class="dropdown-group {{ parentClass }}"
            [class.btn-hide]="hideBtn"
            [class.small]="size == 'small'"
            [class.clean]="type == 'clean'"
            [class.custom-column]="type == 'custom-column'"
        >
            <button 
                class="button-dropdown" 
                [class.dropdown-small]="size == 'small'"
                [class.dropdown-clean]="type == 'clean'"
                [class.dropdown-filter]="type == 'filter'"
                [class.dropdown-kpi-filter]="type == 'filter' && kpiFilter == true"
                [class.has-chevron]="chevron"
                [class.open]="open == true"
                [class.required]="required"
                [disabled]="disabled"
                (click)="$event.stopPropagation(); open ? this.hideDropdown() : this.showDropdown()"
                tabindex="1"
                type="button"
            >
                @if (title) {
                    <span class="button-title">{{ title }}</span>
                }
                
                @if (type == 'filter') {
                    <div class="filter-icon">
                        <span class="material-icon">filter_alt</span>
                        @if (info > 0) {
                            <span class="filter-circle">{{ info <= 9 ? info : '' }}</span>
                        }
                    </div>
                }

                <span 
                    class="button-desc"
                    [class.has-chevron]="chevron"
                    [class.has-info]="type != 'filter' && info"
                    [class.is-empty]="empty"
                >{{ desc }}</span>

                @if (type != 'filter') {
                    @if (info) {
                        <span class="button-info">
                            ({{ info }})
                        </span>
                    }
                    @if (additionalInfo) {
                        <div 
                            class="info-icon"
                            [class.has-info]="info"
                            [tooltip]="additionalInfo"
                            toSide="bottom"
                            size="small"
                            textAlign="center"
                        ></div>
                    }
                }
            </button>
            @if (open) {
                <div 
                    class="dropdown {{ bodyClass }}"
                    [class.no-fixed-height]="heightFollowsChild"
                    role="dialog"
                    [style.maxHeight]="maxBodyHeight ? maxBodyHeight + 'px' : null"
                    #dropdownRef
                    [@fadeInAnimation]
                    (@fadeInAnimation.start)="updateDropdownPosition(dropdownRef)"
                >
                    <ng-content select="[body]"></ng-content>
                </div>
            }
        </div>
  `,
    styleUrls: ['./dropdown-button.component.scss'],
    animations: [
        trigger('fadeInAnimation',[
            transition('void => *', [
                style({ transform: 'translateY(-10px)', opacity: 0 }),
                animate('.15s ease-out', style({ transform: 'translateY(0px)', opacity: 1 }))
            ]),
            transition('* => void', [
                style({ transform: 'translateY(0px)', opacity: 1 }),
                animate('.15s ease-in', style({ transform: 'translateY(-10px)', opacity: 0 }))
            ])
        ])
    ],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DropdownButtonComponent implements OnInit, OnDestroy {
    @Input('type') type: 'default' | 'filter' | 'clean' | 'custom-column' = 'default';
    @Input('title') title: string | null = null;
    @Input('desc') desc: string = 'Default';
    @Input('info') info!: number;
    // shows red info icon with this text as tooltip in button
    @Input('additionalInfo') additionalInfo: string | undefined;
    @Input('size') size: 'default' | 'small' = 'default';
    @Input('chevron') chevron: boolean = false;
    @Input('kpiFilter') kpiFilter: boolean = false;
    @Input('pos') pos: 'bottom' | 'site' = 'bottom';
    @Input('alignment') alignment: 'left' | 'right' = 'left';
    @Input('parentClass') parentClass!: string;
    @Input('hideBtn') hideBtn: boolean = false;
    @Input('disabled') disabled: boolean = false;
    // disables focusout listener, to enable interaction with nested components
    @Input('hasInteractiveContent') hasInteractiveContent: boolean = false;
    @Input('required') required: boolean | undefined;
    @Input('bodyClass') bodyClass: string | undefined;
    // fixed max height of dropdown button, if left empty, height defined in scss is used
    @Input('maxBodyHeight') maxBodyHeight: number | null | undefined;
    // disables all height settings
    @Input('heightFollowsChild') heightFollowsChild: boolean = false;
    // will keep initial position of dropdown content
    @Input() keepPosition: boolean = false;
    @Input() empty: boolean = false;
    @Input('open') open: boolean = false;
    @Output('onClose') onCloseEvent = new EventEmitter<string>();
    @Output('onOpen') onOpenEvent = new EventEmitter<string>();

    @Output('onEnter') onEnter = new EventEmitter<void>();
    @Output('onSearch') onSearch = new EventEmitter<void>();

    // using setter with viewchild to properly get moment when element is rendered
    @ViewChild('dropdownRef', {static: false}) set listener(elRef: ElementRef) {
        if (!elRef) return;
        this._dropdownRef = elRef;
        // set event listeners once dropdown is rendered
        this._setListeners()
    }
    private _dropdownRef: ElementRef | undefined;
    private _windowDimensions: {height: number, width: number};
    private _isDateSelector: boolean = false;

    private _unlistenEvents: (() => void)[] = [];

    constructor(
        private _elRef: ElementRef,
        private _renderer: Renderer2,
        public ngZone: NgZone
    ) {
        this._windowDimensions = {
            width: window.innerWidth,
            height: window.innerHeight
        }
    }

    ngOnInit(): void {
        this._isDateSelector = this.bodyClass?.split(' ').includes('body-date-range-select') || false;
    }

    updateDropdownPosition(refEl?: HTMLElement) {
        if (this.keepPosition) return;
        const element = refEl || this._dropdownRef?.nativeElement;
        if (!element) return
        const placement = this._getDropdownPos();
        this._renderer.setStyle(element, 'top', placement.top + 'px');
        this._renderer.setStyle(element, 'left', placement.left + 'px');
    }

    showDropdown() {
        this.open = true;
        this.updateDropdownPosition()
        this.onOpenEvent.emit('open')
    }

    hideDropdown() {
        this.open = false;
        // emit event when closed
        this.onCloseEvent.emit('closed')
        this._removeListeners()
    }

    private _getDropdownPos(): {top: number, left: number} {
        const groupRect: DOMRect = this._elRef.nativeElement.getBoundingClientRect();
        const dropdownRect = this._dropdownRef?.nativeElement.getBoundingClientRect();

        // set general position of dropdown element in accordance to the button
        let groupPos: {top: number, left: number} = {top: 0, left: 0};
        if (this.pos == 'bottom') groupPos.top = groupRect.top + groupRect.height;
        else groupPos.top = groupRect.top;
        
        if (this.alignment == 'left')
            if (this.pos == 'bottom') groupPos.left = groupRect.left;
            else groupPos.left = groupRect.left + groupRect.width + 12;
        else 
            if (this.pos == 'bottom') groupPos.left = groupRect.left + groupRect.width - dropdownRect.width;
            else groupPos.left = groupRect.left + groupRect.width - dropdownRect.width + 12;

        // if space below is not sufficient, display dropdown above select field if possible
        const dropdownHeight = dropdownRect?.height || this.maxBodyHeight;
        if (groupPos.top + dropdownHeight > this._windowDimensions.height && groupPos.top > dropdownHeight) {
            groupPos.top = groupPos.top - groupRect.height - dropdownHeight;
        }

        // if dropdown would touch one edge of window, align to the other
        if (this.alignment == 'left' && groupRect.left + dropdownRect.width > this._windowDimensions.width) {
            if (this.pos == 'bottom') groupPos.left = groupRect.left + groupRect.width - dropdownRect.width;
            else groupPos.left = groupRect.left - 12 - dropdownRect.width;
        }
        if (this.alignment == 'right' && groupRect.right - dropdownRect.width < 0) {
            if (this.pos == 'bottom') groupPos.left = groupRect.left;
            else groupPos.left = groupRect.left + groupRect.width + 12;
        }

        return groupPos;
    }

    private _setListeners() {
        // listen to scroll events outside of angular zone to reduce calls to changeDetection
        // data bindings wont work outside of angular, thus "updateDropdownPosition()" directly sets position through renderer2
        this.ngZone.runOutsideAngular(() => {
            this._unlistenEvents.push(
                // listen to scroll and mousedown on document once dropdown is rendered
                this._renderer.listen('document', 'scroll', (event) => {
                    this.updateDropdownPosition()
                })
            )
        })

        this._unlistenEvents.push(
            this._renderer.listen('window', 'resize', (event) => {
                this._windowDimensions = {
                    width: window.innerWidth,
                    height: window.innerHeight
                };
                this.updateDropdownPosition()
            }),
            // close dropdown on esc
            // using angular pseudo events (https://angular.io/guide/user-input#key-event-filtering-with-keyenter)
            // poorly documented, more info can be found here: https://medium.com/claritydesignsystem/angular-pseudo-events-d4e7f89247ee
            this._renderer.listen('document', 'keydown.esc', (event) => {
                this.hideDropdown()
            }),
            this._renderer.listen('document', 'keydown.enter', (event: KeyboardEvent) => {
                if (event.target instanceof Element && event.target.classList.contains('button-dropdown')) return
                this.onEnter.emit()
                this.hideDropdown()
            }),
            // emit on search (cmd + f for mac, control + f for win)
            this._renderer.listen('document', 'keydown.Meta.f', (event) => {
                event.preventDefault();
                this.onSearch.emit()
            }),
            this._renderer.listen('document', 'keydown.control.f', (event) => {
                event.preventDefault();
                this.onSearch.emit()
            })
        )

        if (!this.hasInteractiveContent) {
            this._unlistenEvents.push(
                this._renderer.listen('window', 'focusout', (event) => {
                    if (!this._elRef.nativeElement.contains(event.target)) {
                        this.hideDropdown()
                    }
                })
            )
        }

        // listen to clicks outside of dropdown once opened
        // has to be handled manually on type "custom-column", as the main button is hidden
        if (this.type !== 'custom-column') {
            this._unlistenEvents.push(
                this._renderer.listen('document', 'mousedown', (event) => {
                    // prevent closing button while selecting date
                    const datePickerOverlay = document.querySelector('date-range-popup');
                    const onDatePicker = datePickerOverlay && datePickerOverlay.contains(event.target);
                    if (!this._elRef.nativeElement.contains(event.target) && this.open && !onDatePicker) {
                        this.hideDropdown()
                    }
                })
            )
        }
    }

    private _removeListeners() {
        // remove event listeners
        this._unlistenEvents.forEach((fn) => fn())
    }

    ngOnDestroy(): void {
        this._removeListeners()
    }
}
