/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { DataBaseService } from '../data-base-service';
import { DataApiConfiguration } from '../data-api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ChargingSession } from '../models/charging-session';
import { ChargingStation } from '../models/charging-station';


/**
 * Analysis Tab for the Charging History of a Charging Station
 */
@Injectable({
  providedIn: 'root',
})
export class DetailsChargingService extends DataBaseService {
  constructor(
    config: DataApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getChargingStation
   */
  static readonly GetChargingStationPath = '/chargingStations/{stationId}/';

  /**
   * Gets a single charging station.
   *
   * Fetch a single charging stations, optionally for a specific date
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getChargingStation()` instead.
   *
   * This method doesn't expect any request body.
   */
  getChargingStation$Response(params: {

    /**
     * Unique identifier of the Charging Station
     */
    stationId: string;

    /**
     * Time at which state the charging station should be returned
     */
    date?: string;
  }): Observable<StrictHttpResponse<ChargingStation>> {

    const rb = new RequestBuilder(this.rootUrl, DetailsChargingService.GetChargingStationPath, 'get');
    if (params) {
      rb.path('stationId', params.stationId, {});
      rb.query('date', params.date, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ChargingStation>;
      })
    );
  }

  /**
   * Gets a single charging station.
   *
   * Fetch a single charging stations, optionally for a specific date
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getChargingStation$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getChargingStation(params: {

    /**
     * Unique identifier of the Charging Station
     */
    stationId: string;

    /**
     * Time at which state the charging station should be returned
     */
    date?: string;
  }): Observable<ChargingStation> {

    return this.getChargingStation$Response(params).pipe(
      map((r: StrictHttpResponse<ChargingStation>) => r.body as ChargingStation)
    );
  }

  /**
   * Path part for operation getChargingSessionsOfChargingStation
   */
  static readonly GetChargingSessionsOfChargingStationPath = '/chargingStations/{stationId}/chargingSessions/';

  /**
   * Gets charging sessions of a charging station.
   *
   * Fetch the charging sessions of a specific charging station, optionally for a specific time interval
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getChargingSessionsOfChargingStation()` instead.
   *
   * This method doesn't expect any request body.
   */
  getChargingSessionsOfChargingStation$Response(params: {

    /**
     * Unique identifier of the Charging Station
     */
    stationId: string;

    /**
     * The time up to which the list of charging sessions should be displayed
     */
    date?: string;

    /**
     * The period in days before &#x27;date&#x27; from which all sessions should be returned
     */
    interval?: number;

    /**
     * Wether to return all attributes of the charging sessions or a specific subset
     */
    detailed?: boolean;
  }): Observable<StrictHttpResponse<Array<ChargingSession>>> {

    const rb = new RequestBuilder(this.rootUrl, DetailsChargingService.GetChargingSessionsOfChargingStationPath, 'get');
    if (params) {
      rb.path('stationId', params.stationId, {});
      rb.query('date', params.date, {});
      rb.query('interval', params.interval, {});
      rb.query('detailed', params.detailed, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ChargingSession>>;
      })
    );
  }

  /**
   * Gets charging sessions of a charging station.
   *
   * Fetch the charging sessions of a specific charging station, optionally for a specific time interval
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getChargingSessionsOfChargingStation$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getChargingSessionsOfChargingStation(params: {

    /**
     * Unique identifier of the Charging Station
     */
    stationId: string;

    /**
     * The time up to which the list of charging sessions should be displayed
     */
    date?: string;

    /**
     * The period in days before &#x27;date&#x27; from which all sessions should be returned
     */
    interval?: number;

    /**
     * Wether to return all attributes of the charging sessions or a specific subset
     */
    detailed?: boolean;
  }): Observable<Array<ChargingSession>> {

    return this.getChargingSessionsOfChargingStation$Response(params).pipe(
      map((r: StrictHttpResponse<Array<ChargingSession>>) => r.body as Array<ChargingSession>)
    );
  }

}
