import { inject, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, combineLatest, EMPTY, forkJoin, iif, map, Observable, ReplaySubject, share, startWith, switchMap, take, tap, withLatestFrom } from 'rxjs';
import { NotificationService } from './notification.service';
import { OverviewService } from '../data-backend/data-services';
import { ChargingStation, ResetResponse } from '../data-backend/models';
import { DeleteSchedulesResponse } from '../data-backend/models/delete-schedules-response';
import { HttpErrorResponse } from '@angular/common/http';
import { DeleteRestartsResponse } from '../data-backend/models/delete-restarts-response';
import { detailsRepository } from '../stores/details.repository';

export type StationWithSchedules = ChargingStation & { onIdleActive: boolean };

@Injectable({
    providedIn: 'root'
})
export class StationRestartService {
    // controls refetching of data
    private _refetchRef$ = new BehaviorSubject<number>(0);
    // loading state of schedules and OnIdle requests
    private _isLoading$ = new BehaviorSubject<boolean>(false);
    // true when manual instant restart is requested
    private _isRestarting$ = new BehaviorSubject<boolean>(false);

    // station from details repository might contain old schedule data, as it's from the OV-Index
    // this observable will contain the station with the latest schedules and added onIdle restarts
    public stationWithSchedules$: Observable<StationWithSchedules | null>;
    // observables for loading states
    public get isLoading$() {
        return this._isLoading$.asObservable();
    };
    public get isRestarting$() {
        return this._isRestarting$.asObservable();
    };

    // services
    private _overviewService = inject(OverviewService);
    private _notificationService = inject(NotificationService);
    private _translate = inject(TranslateService);
    private _detailsRepo = inject(detailsRepository);

    constructor() {
        const restartData$ = combineLatest({
            station: this._detailsRepo.station$,
            refetchRef: this._refetchRef$
        }).pipe(
            switchMap(({station}) => {
                if (!station) return EMPTY;
                this._isLoading$.next(true);

                return forkJoin({
                    onIdleRestarts: this._overviewService.getOnIdleRestarts({ stationIds: [station.stationId] }),
                    schedules: this._overviewService.getSchedules({ stationIds: [station.stationId] })
                }).pipe(
                    tap(() => this._isLoading$.next(false))
                );
            }),
            startWith({
                onIdleRestarts: [],
                schedules: []
            }),
            share({ connector: () => new ReplaySubject(1) })
        );

        const onIdle$ = restartData$.pipe(
            map(({ onIdleRestarts }) => onIdleRestarts.length > 0 ? onIdleRestarts[0] : null)
        );
        const onIdleActive$ = onIdle$.pipe(
            map((restart) => restart !== null && restart.mode == 'onIdle' && restart.status == 'Pending'),
            startWith(false)
        );
        const schedule$ = restartData$.pipe(
            map(({ schedules }) => schedules.length > 0 ? schedules[0] : null)
        );

        this.stationWithSchedules$ = combineLatest({
            onIdleActive: onIdleActive$,
            schedule: schedule$
        }).pipe(
            withLatestFrom(this._detailsRepo.station$),
            map(([{onIdleActive, schedule}, station]) => {
                if (!station) return null

                let out: StationWithSchedules = {
                    ...station,
                    onIdleActive
                }

                if (!schedule) return {
                    ...out,
                    restartSchedule: undefined
                };

                return {
                    ...out,
                    restartSchedule: schedule.weekdays.length === 7 
                        ? 'Daily restart' 
                        : schedule.weekdays.length > 0 
                            ? 'Weekly restart' 
                            : undefined as ChargingStation['restartSchedule']
                }
            })
        );
    }

    public refetch() {
        this._refetchRef$.next(this._refetchRef$.getValue() + 1);
    }

    public restartStation(stationIds: string | string[], onIdle: boolean = false, successCallback?: (v: ResetResponse) => void, errorCallback?: (v: HttpErrorResponse) => void) {
        const ids = typeof stationIds == 'string' ? [stationIds] : stationIds;
        if (onIdle) {
            // set general loading for config change
            this._isLoading$.next(true);
        } else {
            // set loading for instant restart
            this._isRestarting$.next(true);
        }

        this._overviewService.restartChargingStationsManually({
            body: {
                stationIds: ids,
                onIdle
            }
        }).pipe(take(1)).subscribe({
            next: (v) => {
                if (v.new.length > 0) {
                    const message = onIdle ? 'DETAILS_VIEW.RESTARTS.RESTART_PENDING_SUCCESS' : 'DETAILS_VIEW.RESTARTS.RESTART_SUCCESS';
                    this._notificationService.showSuccess(
                        this._translate.instant(message, {id: ids.join(', ')}),
                    );
                } else if (v.update.length == 0) {
                    const message = onIdle ? 'DETAILS_VIEW.RESTARTS.RESTART_PENDING_ERROR' : 'DETAILS_VIEW.RESTARTS.RESTART_ERROR';
                    this._notificationService.showError(
                        this._translate.instant(message, {id: ids.join(', ')}),
                        this._translate.instant('COMMON.ERROR.ONE')
                    );
                }
                this._isRestarting$.next(false);
                this._isLoading$.next(false);
                this.refetch();
                if (successCallback) successCallback(v);
            },
            error: (v) => {
                const message = onIdle ? 'DETAILS_VIEW.RESTARTS.RESTART_PENDING_ERROR' : 'DETAILS_VIEW.RESTARTS.RESTART_ERROR';
                this._notificationService.showError(
                    this._translate.instant(message, {id: ids.join(', ')}),
                    this._translate.instant('COMMON.ERROR.ONE')
                );
                this._isRestarting$.next(false);
                this._isLoading$.next(false);
                if (errorCallback) errorCallback(v);
            }
        })
    };

    public deleteOnIdleRestart(stationIds: string | string[], successCallback?: (v: DeleteRestartsResponse) => void, errorCallback?: (v: HttpErrorResponse) => void) {
        const ids = typeof stationIds == 'string' ? [stationIds] : stationIds;
        this._isLoading$.next(true);
        this._overviewService.deleteRestarts({stationIds: ids}).pipe(
            take(1)
        ).subscribe({
            next: (v) => {
                this._notificationService.showLocalizedSuccess('DETAILS_VIEW.RESTARTS.DELETE_PENDING_RESTART_SUCCESS');
                this._isLoading$.next(false);
                this.refetch();
                if (successCallback) successCallback(v)
            },
            error: (v) => {
                this._notificationService.showLocalizedError('DETAILS_VIEW.RESTARTS.DELETE_PENDING_RESTART_ERROR', 'COMMON.ERROR.ONE');
                this._isLoading$.next(false);
                if (errorCallback) errorCallback(v);
            }
        })
    }

    private _scheduleRestart(stationIds: string[], type: 'Daily restart' | 'Weekly restart'): Observable<ResetResponse> {
        let weekdays: number[] = [];
        if (type === 'Daily restart') {
            weekdays = Array(7).fill(null).map((_, i) => i);
        } else {
            weekdays = [6]; // Sunday (as per backend)
        }

        return this._overviewService.restartChargingStationsBySchedule({
            body: {
                stationIds,
                weekdays
            }
        })
    };

    private _deleteSchedule(stationIds: string[]): Observable<DeleteSchedulesResponse> {
        return this._overviewService.deleteSchedules({stationIds})
    }

    public updateSchedule(
        stationIds: string | string[],
        activeSchedule: ChargingStation['restartSchedule'],
        newSchedule: ChargingStation['restartSchedule'],
        successCallback?: (v: ResetResponse | DeleteSchedulesResponse) => void, 
        errorCallback?: (v: HttpErrorResponse) => void
    ) {
        const ids = typeof stationIds == 'string' ? [stationIds] : stationIds;

        // show loading animation
        this._isLoading$.next(true);

        // determine action types
        const isDeletion = !newSchedule || activeSchedule === newSchedule;
        const isDaily = newSchedule === 'Daily restart';

        // get correct observable based on schedule change
        iif(
            () => isDeletion,
            this._deleteSchedule(ids),
            this._scheduleRestart(ids, newSchedule!)
        ).pipe(take(1)).subscribe({
            next: (v) => {
                if (isDeletion) {
                    this._notificationService.showLocalizedSuccess('DETAILS_VIEW.RESTARTS.DELETE_SCHEDULE_SUCCESS');
                } else {
                    const res = v as ResetResponse;
                    if (res.new.length > 0 || res.update.length > 0) {
                        isDaily
                            ? this._notificationService.showLocalizedSuccess('DETAILS_VIEW.RESTARTS.SCHEDULE_DAILY_SUCCESS')
                            : this._notificationService.showLocalizedSuccess('DETAILS_VIEW.RESTARTS.SCHEDULE_WEEKLY_SUCCESS');
                    } else {
                        isDaily
                            ? this._notificationService.showLocalizedError('DETAILS_VIEW.RESTARTS.SCHEDULE_DAILY_ERROR', 'COMMON.ERROR.ONE')
                            : this._notificationService.showLocalizedError('DETAILS_VIEW.RESTARTS.SCHEDULE_WEEKLY_ERROR', 'COMMON.ERROR.ONE');
                    }
                }

                this._isLoading$.next(false);
                this.refetch();
                if (successCallback) successCallback(v)
            },
            error: (v) => {
                if (isDeletion) {
                    this._notificationService.showLocalizedError('DETAILS_VIEW.RESTARTS.DELETE_SCHEDULE_ERROR', 'COMMON.ERROR.ONE');
                } else {
                    isDaily 
                        ? this._notificationService.showLocalizedError('DETAILS_VIEW.RESTARTS.SCHEDULE_DAILY_ERROR', 'COMMON.ERROR.ONE')
                        : this._notificationService.showLocalizedError('DETAILS_VIEW.RESTARTS.SCHEDULE_WEEKLY_ERROR', 'COMMON.ERROR.ONE');
                }

                this._isLoading$.next(false);
                if (errorCallback) errorCallback(v);
            }
        })
    }
}
