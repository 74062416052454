/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { DataBaseService } from '../data-base-service';
import { DataApiConfiguration } from '../data-api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { Ticket } from '../models/ticket';


/**
 * Tab that lists the ticket information for a selected station
 */
@Injectable({
  providedIn: 'root',
})
export class DetailsTicketsService extends DataBaseService {
  constructor(
    config: DataApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation chargingStationsStationIdTicketsGet
   */
  static readonly ChargingStationsStationIdTicketsGetPath = '/chargingStations/{stationId}/tickets/';

  /**
   * Gets ticket information of a charging station from a ticket system.
   *
   * Gets information of all tickets that are found in the tenant's specified ticket system for a selected charging station.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `chargingStationsStationIdTicketsGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  chargingStationsStationIdTicketsGet$Response(params: {

    /**
     * Unique identifier of the Charging Station
     */
    stationId: string;
  }): Observable<StrictHttpResponse<Array<Ticket>>> {

    const rb = new RequestBuilder(this.rootUrl, DetailsTicketsService.ChargingStationsStationIdTicketsGetPath, 'get');
    if (params) {
      rb.path('stationId', params.stationId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Ticket>>;
      })
    );
  }

  /**
   * Gets ticket information of a charging station from a ticket system.
   *
   * Gets information of all tickets that are found in the tenant's specified ticket system for a selected charging station.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `chargingStationsStationIdTicketsGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  chargingStationsStationIdTicketsGet(params: {

    /**
     * Unique identifier of the Charging Station
     */
    stationId: string;
  }): Observable<Array<Ticket>> {

    return this.chargingStationsStationIdTicketsGet$Response(params).pipe(
      map((r: StrictHttpResponse<Array<Ticket>>) => r.body as Array<Ticket>)
    );
  }

}
