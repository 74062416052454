/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { AuthBaseService } from '../auth-base-service';
import { AuthApiConfiguration } from '../auth-api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ApiResponse } from '../models/api-response';
import { Customer } from '../models/customer';
import { User } from '../models/user';


/**
 * User administration
 */
@Injectable({
  providedIn: 'root',
})
export class AdminService extends AuthBaseService {
  constructor(
    config: AuthApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getUser
   */
  static readonly GetUserPath = '/admin/users/:uuid/';

  /**
   * get an existing user from the customer authorization backend.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getUser()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUser$Response(params: {

    /**
     * Unique identifier of the user
     */
    uuid: string;
  }): Observable<StrictHttpResponse<User>> {

    const rb = new RequestBuilder(this.rootUrl, AdminService.GetUserPath, 'get');
    if (params) {
      rb.path('uuid', params.uuid, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<User>;
      })
    );
  }

  /**
   * get an existing user from the customer authorization backend.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getUser$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUser(params: {

    /**
     * Unique identifier of the user
     */
    uuid: string;
  }): Observable<User> {

    return this.getUser$Response(params).pipe(
      map((r: StrictHttpResponse<User>) => r.body as User)
    );
  }

  /**
   * Path part for operation adminUsersUuidDelete
   */
  static readonly AdminUsersUuidDeletePath = '/admin/users/:uuid/';

  /**
   * Delete an existing user from the customer authorization backend.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminUsersUuidDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminUsersUuidDelete$Response(params: {

    /**
     * Uuid of the user
     */
    uuid: string;
  }): Observable<StrictHttpResponse<ApiResponse>> {

    const rb = new RequestBuilder(this.rootUrl, AdminService.AdminUsersUuidDeletePath, 'delete');
    if (params) {
      rb.path('uuid', params.uuid, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ApiResponse>;
      })
    );
  }

  /**
   * Delete an existing user from the customer authorization backend.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `adminUsersUuidDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminUsersUuidDelete(params: {

    /**
     * Uuid of the user
     */
    uuid: string;
  }): Observable<ApiResponse> {

    return this.adminUsersUuidDelete$Response(params).pipe(
      map((r: StrictHttpResponse<ApiResponse>) => r.body as ApiResponse)
    );
  }

  /**
   * Path part for operation updateUser
   */
  static readonly UpdateUserPath = '/admin/users/:uuid/';

  /**
   * Updates an existing user from the customer authorization backend.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateUser()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateUser$Response(params: {

    /**
     * Unique identifier of the user
     */
    uuid: string;

    /**
     * User Details
     */
    body: User
  }): Observable<StrictHttpResponse<ApiResponse>> {

    const rb = new RequestBuilder(this.rootUrl, AdminService.UpdateUserPath, 'patch');
    if (params) {
      rb.path('uuid', params.uuid, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ApiResponse>;
      })
    );
  }

  /**
   * Updates an existing user from the customer authorization backend.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateUser$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateUser(params: {

    /**
     * Unique identifier of the user
     */
    uuid: string;

    /**
     * User Details
     */
    body: User
  }): Observable<ApiResponse> {

    return this.updateUser$Response(params).pipe(
      map((r: StrictHttpResponse<ApiResponse>) => r.body as ApiResponse)
    );
  }

  /**
   * Path part for operation getUsers
   */
  static readonly GetUsersPath = '/admin/users/';

  /**
   * get existing users from the customer authorization backend.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getUsers()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUsers$Response(params?: {
  }): Observable<StrictHttpResponse<Array<User>>> {

    const rb = new RequestBuilder(this.rootUrl, AdminService.GetUsersPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<User>>;
      })
    );
  }

  /**
   * get existing users from the customer authorization backend.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getUsers$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUsers(params?: {
  }): Observable<Array<User>> {

    return this.getUsers$Response(params).pipe(
      map((r: StrictHttpResponse<Array<User>>) => r.body as Array<User>)
    );
  }

  /**
   * Path part for operation registerUser
   */
  static readonly RegisterUserPath = '/admin/users/';

  /**
   * Add a new user to the customer authorization backend.
   *
   * /TODO fill me
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `registerUser()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  registerUser$Response(params: {

    /**
     * User Details
     */
    body: User
  }): Observable<StrictHttpResponse<ApiResponse>> {

    const rb = new RequestBuilder(this.rootUrl, AdminService.RegisterUserPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ApiResponse>;
      })
    );
  }

  /**
   * Add a new user to the customer authorization backend.
   *
   * /TODO fill me
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `registerUser$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  registerUser(params: {

    /**
     * User Details
     */
    body: User
  }): Observable<ApiResponse> {

    return this.registerUser$Response(params).pipe(
      map((r: StrictHttpResponse<ApiResponse>) => r.body as ApiResponse)
    );
  }

  /**
   * Path part for operation getCustomer
   */
  static readonly GetCustomerPath = '/admin/customers/:uuid/';

  /**
   * get an existing customer from the customer authorization backend.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCustomer()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomer$Response(params: {

    /**
     * Unique identifier of the customer
     */
    uuid: string;
  }): Observable<StrictHttpResponse<Customer>> {

    const rb = new RequestBuilder(this.rootUrl, AdminService.GetCustomerPath, 'get');
    if (params) {
      rb.path('uuid', params.uuid, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Customer>;
      })
    );
  }

  /**
   * get an existing customer from the customer authorization backend.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getCustomer$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomer(params: {

    /**
     * Unique identifier of the customer
     */
    uuid: string;
  }): Observable<Customer> {

    return this.getCustomer$Response(params).pipe(
      map((r: StrictHttpResponse<Customer>) => r.body as Customer)
    );
  }

  /**
   * Path part for operation adminCustomersUuidDelete
   */
  static readonly AdminCustomersUuidDeletePath = '/admin/customers/:uuid/';

  /**
   * Delete an existing customer from the customer authorization backend.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminCustomersUuidDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminCustomersUuidDelete$Response(params: {

    /**
     * Uuid of the customer
     */
    uuid: string;
  }): Observable<StrictHttpResponse<ApiResponse>> {

    const rb = new RequestBuilder(this.rootUrl, AdminService.AdminCustomersUuidDeletePath, 'delete');
    if (params) {
      rb.path('uuid', params.uuid, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ApiResponse>;
      })
    );
  }

  /**
   * Delete an existing customer from the customer authorization backend.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `adminCustomersUuidDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminCustomersUuidDelete(params: {

    /**
     * Uuid of the customer
     */
    uuid: string;
  }): Observable<ApiResponse> {

    return this.adminCustomersUuidDelete$Response(params).pipe(
      map((r: StrictHttpResponse<ApiResponse>) => r.body as ApiResponse)
    );
  }

  /**
   * Path part for operation changeCustomer
   */
  static readonly ChangeCustomerPath = '/admin/customers/:uuid/';

  /**
   * Add a new customer to the customer authorization backend.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `changeCustomer()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  changeCustomer$Response(params: {

    /**
     * Unique identifier of the customer
     */
    uuid: string;

    /**
     * Customer Details
     */
    body: Customer
  }): Observable<StrictHttpResponse<ApiResponse>> {

    const rb = new RequestBuilder(this.rootUrl, AdminService.ChangeCustomerPath, 'patch');
    if (params) {
      rb.path('uuid', params.uuid, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ApiResponse>;
      })
    );
  }

  /**
   * Add a new customer to the customer authorization backend.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `changeCustomer$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  changeCustomer(params: {

    /**
     * Unique identifier of the customer
     */
    uuid: string;

    /**
     * Customer Details
     */
    body: Customer
  }): Observable<ApiResponse> {

    return this.changeCustomer$Response(params).pipe(
      map((r: StrictHttpResponse<ApiResponse>) => r.body as ApiResponse)
    );
  }

  /**
   * Path part for operation getCustomers
   */
  static readonly GetCustomersPath = '/admin/customers/';

  /**
   * get existing customers from the customer authorization backend.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCustomers()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomers$Response(params?: {
  }): Observable<StrictHttpResponse<Array<Customer>>> {

    const rb = new RequestBuilder(this.rootUrl, AdminService.GetCustomersPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Customer>>;
      })
    );
  }

  /**
   * get existing customers from the customer authorization backend.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getCustomers$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomers(params?: {
  }): Observable<Array<Customer>> {

    return this.getCustomers$Response(params).pipe(
      map((r: StrictHttpResponse<Array<Customer>>) => r.body as Array<Customer>)
    );
  }

  /**
   * Path part for operation createCustomer
   */
  static readonly CreateCustomerPath = '/admin/customers/';

  /**
   * Add a new customer to the customer authorization backend.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createCustomer()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createCustomer$Response(params: {

    /**
     * Customer Details
     */
    body: Customer
  }): Observable<StrictHttpResponse<ApiResponse>> {

    const rb = new RequestBuilder(this.rootUrl, AdminService.CreateCustomerPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ApiResponse>;
      })
    );
  }

  /**
   * Add a new customer to the customer authorization backend.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `createCustomer$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createCustomer(params: {

    /**
     * Customer Details
     */
    body: Customer
  }): Observable<ApiResponse> {

    return this.createCustomer$Response(params).pipe(
      map((r: StrictHttpResponse<ApiResponse>) => r.body as ApiResponse)
    );
  }

}
