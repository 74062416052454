import { formatDate } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { DateRange } from 'src/app/core/plots/plot.models';
import { Filter } from 'src/app/core/stores/station-filters.repository';

@Component({
  selector: 'app-select-date-range',
  template: `
        <app-dropdown-button
            [title]="title"
            [desc]="buttonDesc"
            [empty]="!dateRange"
            [bodyClass]="bodyClasses.join(' ')"
            [additionalInfo]="additionalInfo"
            [size]="size"
            [keepPosition]="keepPosition"
            [open]="dropdownOpen"
            (onOpen)="dropdownOpen = true"
            (onClose)="applyChanges()"
        >
            <ng-container body>
                <div class="p-16">
                    <app-date-range
                        [showTime]="showTime"
                        format="vertical"
                        [dateRange]="dateRange"
                        [minDate]="dateMin"
                        [maxDate]="dateMax"
                        (dateRangeChange)="updateDates($event)"
                    >
                    </app-date-range>
                </div>
            </ng-container>
        </app-dropdown-button>
  `,
  styleUrls: ['./select-date-range.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SelectDateRangeComponent implements OnInit, OnChanges {
    // Title of Dropdown
    @Input('title') title!: string;
    // preset date ranges
    @Input() minMaxDates?: Filter['options'];
    // preset dates
    @Input() activeSelection: (Date | number)[] | undefined;
    @Output() activeSelectionChange = new EventEmitter<(Date | number)[]>();
    // enables time inputs
    @Input('showTime') showTime!: boolean;
    // size of the button
    @Input('size') size: 'default' | 'small' = 'default';
    // additional information on info icon in button
    @Input() additionalInfo: string | undefined;
    // will keep initial position of dropdown content
    @Input() keepPosition: boolean = false;
    // classes for body of dropdown
    bodyClasses: string[] = ['body-date-range-select', 'swiper-no-swiping', 'overflow-hidden'];
    // Description of Button
    buttonDesc!: string;
    // value of date-range, stored before emitting
    dateRange: DateRange | undefined;
    // min/max ranges of selection
    dateMin: Date | undefined;
    dateMax: Date | undefined;
    public dropdownOpen = false;
    // store previous filter value
    private _prevValues!: DateRange;

    constructor() { }

    ngOnInit(): void {
        if (this.minMaxDates) {
            // set min/max of filter
            this.dateMin = new Date(this.minMaxDates[0].value)
            this.dateMax = new Date(this.minMaxDates[1].value)
        }

        if (this.activeSelection || this._prevValues) {
            let dateFrom    = this.activeSelection ? this.activeSelection[0] : this._prevValues.from,
                dateUntil   = this.activeSelection ? this.activeSelection[1] : this._prevValues.until,
                activeRange = {from: new Date(dateFrom), until: new Date(dateUntil)};

            this._prevValues = activeRange;
            this.dateRange = activeRange;
        } else if (this.dateMin && this.dateMax) {
            // store max ranges as prev values to compare against
            const range = {from: this.dateMin, until: this.dateMax};
            this._prevValues = range;
            this.dateRange = range;
        }

        this.updateButtonDesc()
    }

    ngOnChanges(changes: SimpleChanges): void {
        let prevChange  = changes['activeSelection'],
            newChange   = changes['activeSelection'];

        if (prevChange && !newChange && this.dateMin && this.dateMax) {
            this.dateRange = {from: this.dateMin, until: this.dateMax}
            this.updateButtonDesc()
        }
    }

    // updates button description
    updateButtonDesc() {
        if (this.dateRange) {
            let from = this.dateRange.from,
                until = this.dateRange.until;
            this.buttonDesc = `${formatDate(from, 'dd.MM.yyyy', 'en')} - ${formatDate(until, 'dd.MM.yyyy', 'en')}`
        } else {
            this.buttonDesc = 'All time'
        }
    }

    // update stored dates
    updateDates(value: DateRange) {
        this.dateRange = value
        this.updateButtonDesc();
        // the datePicker might emit changes after dropdown is closed, for this case, apply changes again
        if (!this.dropdownOpen) this.applyChanges();
    }

    // applies changes when dropdown is closed
    applyChanges() {
        this.dropdownOpen = false;
        if (!this.dateRange) return
        // compare prev values with new ones, only update if new values are present
        let prevDateFrom = this._prevValues && this._prevValues.from ? this._prevValues.from : undefined,
            prevDateUntil = this._prevValues && this._prevValues.until ? this._prevValues.until : undefined;

        if (!(prevDateFrom && prevDateUntil) || !(this.dateRange.from.getTime() === prevDateFrom.getTime() && this.dateRange.until.getTime() === prevDateUntil.getTime())) {
            this.activeSelectionChange.emit([this.dateRange.from.getTime(), this.dateRange.until.getTime()])
        }
        this._prevValues = structuredClone(this.dateRange);
    }
}
