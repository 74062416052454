import { Directive, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { NotificationService } from '../app-services';
import { TranslateService } from '@ngx-translate/core';

@Directive({
  selector: '[copy-clipboard]',
  standalone: false
})
export class CopyToClipboardDirective {

    constructor(
        private _notificationService: NotificationService,
        private _translate: TranslateService
    ) { }

    @Input("copy-clipboard")
    public payload: string | null = '';

    @Output("copied")
    public copied: EventEmitter<string> = new EventEmitter<string>();

    @HostListener("click", ["$event"])
    public onClick(event: MouseEvent): void {
        if (this.payload && event.currentTarget instanceof HTMLButtonElement) {
            this.payload = this.payload.toString()
            navigator.clipboard.writeText(this.payload)
                .then(() => {
                    this._notificationService.showInfo(
                        this._translate.instant('COMMON.COPIED', {content: this.payload})
                    )
                    this.copied.emit(this.payload ? this.payload : '')
                }
                )
        }
    }
}
