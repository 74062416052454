import { Injectable } from "@angular/core";
import { fileSave } from "browser-fs-access";
import { AngularCsv } from "angular-csv-ext/dist/Angular-csv";
import { NotificationService } from "./notification.service";
import { ExportOption, ExportRequestEvent } from "src/app/shared/export/export.component";
import { BehaviorSubject } from "rxjs";
import { formatDate } from "@angular/common";
import JSZip from "jszip";
import { environment } from "src/environments/environment";
import { TranslateService } from "@ngx-translate/core";

export interface ExportVars {
    exportCondition: boolean,
    exportOptions: ExportOption[],
    showDatePicker: boolean
}

export interface ExportDataSet {
    [title: string]: any
}

type ExportRejectedError = {error: string, message: string};

const MESSAGE_TYPES = {
    noData: 'APP_ERRORS.NO_DATA',
    noDataFound: 'APP_ERRORS.NO_DATA_TO_EXPORT',
    exportFailed: 'APP_ERRORS.EXPORT_FAILED',
    exportSuccess: 'APP_ERRORS.EXPORT_SUCCESS'
}

@Injectable({
    providedIn: "root",
})
export class ExportService {
    public exportVars$ = new BehaviorSubject<ExportVars | null>(null);
    public pollingInfo$ = new BehaviorSubject<boolean | null>(null);

    public exportRequest$ = new BehaviorSubject<ExportRequestEvent | null>(null);

    constructor(
        private _notificationService: NotificationService,
        private _translate: TranslateService
    ) {}

    // See: https://www.npmjs.com/package/angular-csv-ext
    private readonly _csvOptions: {[key: string]: any} = {
        fieldSeparator: ",",
        quoteStrings: '"',
        decimalseparator: ".",
        showLabels: true,
        showTitle: false,
        title: "",
        useBom: true,
        noDownload: true,
        headers: [],
        useHeader: true,
        nullToEmptyString: false // disabled, as value of 0 will result in empty string as well, thus we handle this ourselves
    };

    private get _timestamp(): string {
        return formatDate(new Date(), 'yyyy-MM-dd_HH-mm-ss', 'en')
    }

    private _sanitizeTitle(title: string): string {
        const reservedChars = /[\/\?<>\\.:\*\|":]/g;
        return title.replace(reservedChars, "_");
    }

    /**
     * It takes a hash array and a column filter array and returns a hash array with only the columns
     * specified in the column filter array
     * @param hashArray - This is the array of hashes that you want to filter.
     * @param columnFilter - This is an array of keys in hashArray.
     * @returns An array of objects.
     */
    public filterHashArray(hashArray: Array<any>, _columnFilter: Array<string>): Array<any> {
        let outArray: Array<any> = [];

        hashArray.forEach((row: any) => {
            let outObj: {[key: string]: any} = new Object();
            _columnFilter.forEach((col) => outObj[col] = row[col] ?? '');

            outArray.push(outObj)
        })

        return outArray;
    }


    /**
     * It takes an array of files and returns a promise that resolves to a zip file containing those
     * files
     * @param {{
     *             file: Blob;
     *             name: string;
     *         }[]} files - {
     * @returns A promise that resolves to a blob.
     */
    private _zipFiles(
        files: {
            file: Blob;
            name: string;
        }[]
    ): Promise<Blob> {
        const zip = new JSZip();
        // tslint:disable-next-line:prefer-for-of
        for (let counter = 0; counter < files.length; counter++) {
            const fileData = files[counter]["file"];
            const fileName = files[counter]["name"];
            zip.file(fileName.substring(fileName.lastIndexOf("/") + 1), fileData);
        }

        return zip.generateAsync({ type: "blob" });
    }

    /**
     * It takes a promise of an array of objects, and returns a promise of a zip file containing
     * CSV files
     * @param data - The data to be exported.
     * @param [title=evercharge] - The title of the export.
     * @returns A promise that resolves to a zip file containing the data.
     */
    private async _resolveExportData(data: Promise<any[]>, title = environment.brandName): Promise<Blob> {
        return new Promise(async (resolve, reject) => {
            await data.then((data) => {
                if (data.length == 0) return reject(new Error(
                    this._translate.instant(MESSAGE_TYPES.noDataFound)
                ))

                let stationHeaders = Object.keys(data[0]);
                
                data.map((entry) => {
                    stationHeaders.forEach((key) => {
                        // replace nulls with empty string
                        entry[key] = entry[key] === null ? '' : entry[key];
                        // convert nested objects
                        entry[key] = typeof(entry[key]) == 'object' ? JSON.stringify(entry[key]) : entry[key];
                    })
                })

                let files = [];
                const csvOptions = this._csvOptions; 
                csvOptions['headers'] = stationHeaders;

                // Create a CSV for all station Stations
                let stationsExportName = (this._sanitizeTitle(title) + "-stations_" + this._timestamp).replace(/\:/g, "_") + ".csv";

                const stationsCsvData = new AngularCsv(data, "", csvOptions).getCsvData();
                const stationsCsvBlob = new Blob([stationsCsvData], { type: "text/csv" });
                files.push({ file: stationsCsvBlob, name: stationsExportName });

                resolve(this._zipFiles(files))
            })
        })
    }

    /**
     * It takes a promise of an array of objects, each object containing a key (the name of the data
     * set) and a value (the data set itself). The data set is an array of objects
     * @param dataSet - Promise<ExportDataSet[]>
     * @param {string} [stationId] - The stationId of the station you want to export data from.
     * @returns A promise that resolves to a blob.
     */
    private async _resolveDataSet(dataSet: Promise<ExportDataSet[]>, stationId?: string): Promise<Blob> {
        return new Promise(async (resolve, reject) => {
            await dataSet.then((dataSet) => {

                let files: {
                    file: Blob;
                    name: string;
                }[] = [];

                const csvOptions = this._csvOptions;

                dataSet.forEach((set) => {
                    let title   = this._sanitizeTitle(Object.keys(set)[0]) + '_' + this._timestamp,
                        data    = Object.values(set)[0];

                    if (data && data.length > 0) {
                        // remove arrays and objects from export from now
                        // if feature is desired, we should create a new file for each array/object right here
                        //    -> take timestamp (e.g. of measuredValue) as reference?
                        let keys = Object.keys(data[0]);
                        keys.forEach((key) => {
                            let values = data?.map((val: any) => val[key]);
                            
                            if (!values || values[0] == undefined || typeof(values[0]) == 'object') {
                                let realIndex = keys.indexOf(key)
                                keys.splice(realIndex, 1)
                            }
                        })
                        
                        if (stationId) {
                            keys.unshift("stationId");
                            data.forEach((value: any) => {
                                value["stationId"] = stationId
                            })
                        }

                        csvOptions["headers"]   = keys
                        csvOptions["title"]     = title
                        
                        const csvData = new AngularCsv(data, "", csvOptions).getCsvData();
                        const csvBlob = new Blob([csvData], {
                            type: "text/csv",
                        });
                        files.push({ file: csvBlob, name: title + ".csv" });
                    }
                })
                if (files.length > 0) {
                    resolve(this._zipFiles(files))
                } else {
                    // reject if no data is available
                    // !! only to be used as FALLBACK !! As of now, this would still save an empty 
                    // zip to the users hardware, thus check data before passing it on to this service
                    reject({
                        error: this._translate.instant(MESSAGE_TYPES.noData), 
                        message: this._translate.instant(MESSAGE_TYPES.noDataFound)
                    })
                }
            })
        })
    }

   /**
    * It exports data to a CSV file.
    * @param {'csv' | 'dataSet'} type - 'csv' | 'dataSet'
    * @param data - Promise<any[]>
    * @param [title=evercharge] - The title of the export.
    * @param {string} [stationId] - The stationId of the station you want to export data for.
    */
    private async _startExport(
        type: 'csv' | 'dataSet', 
        data: Promise<any[]>,
        title = environment.brandName,
        stationId?: string
    ): Promise<void> {
        let zipExportName = (this._sanitizeTitle(title) + "_" + this._timestamp).replace(/\:/g, "_");
        
        return fileSave(
            (
                type == 'csv'
                    ? this._resolveExportData(data, title)
                    : this._resolveDataSet(data, stationId)
            )
                .catch((error: ExportRejectedError) => {
                    this._notificationService.showError(error.message, error.error)
                    return Promise.reject(error)
                }), {
            // more options: https://github.com/GoogleChromeLabs/browser-fs-access?tab=readme-ov-file#saving-files
            fileName: zipExportName,
            extensions: ['.zip'],
            id: environment.brandName // lets the user agent remember the user's choice
        })
        .catch((error) => {
            // only catch error if user aborted request and is not any of our errorTypes
            if (!error.message.includes('abort') && error.message !== this._translate.instant(MESSAGE_TYPES.noDataFound)) {
                this._notificationService.showError(this._translate.instant(MESSAGE_TYPES.exportFailed), error.message)
            }
            return new Blob()
        })
        .then((res) => {
            if (res instanceof FileSystemFileHandle) {
                this._notificationService.showSuccess(this._translate.instant(MESSAGE_TYPES.exportSuccess));
            }
        })
    }

    /**
    * It exports the data to a CSV file.
    * @param data - The data to export. This can be an array of objects or a promise that resolves to
    * an array of objects.
    * @param {string} [title=evercharge] - The title of the file.
    */
    public async exportCSVPromise(
        data: Promise<any[]>,
        title: string = environment.brandName
    ): Promise<void> {
        return this._startExport('csv', data, title)
    }

    /**
     * It exports a data set to a CSV file.
     * @param dataSet - The data set to export.
     * @param [title=evercharge] - The title of the file.
     * @param {string} [stationId] - The stationId of the station you want to export data from.
     */
    public async exportDataSetPromise(
        dataSet: Promise<ExportDataSet[]>,
        title = environment.brandName,
        stationId?: string
    ) {
        this._startExport('dataSet', dataSet, title, stationId)
    }
}
